import React from 'react'
import styles from './legal.module.scss'

const Template = (props) => {
  const [propsVal, setPropsVal] = React.useState(true);
  // console.log(props)
  return (

    <div className={styles.temp}>
        <h4 style={{marginBottom: '20px'}}>{props.data.legal_name}</h4>
      
        <div className={styles.pageContent} dangerouslySetInnerHTML={ {__html: props.data.page_content} } />
      
    </div>
  )
}

export default Template