import React, { useEffect, useState } from 'react';
import styles from './processor.module.scss';
import Marquee from 'react-fast-marquee';
import { APIConstant } from '../../../services/APIConstant';

const Processor = ({templateId}) => {
    const [inputDataList, setInputDataList] = useState([]);
    const [outputDataList, setOutputDataList] = useState([]);
    useEffect(() => {
        fetch(APIConstant.getProcessorInput, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            setInputDataList(data)
        })
        .catch(err => console.log(err))
    }, [])
 
    useEffect(() => {
        fetch(APIConstant.getProcessorOutput, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            setOutputDataList(data)
        })
        .catch(err => console.log(err))
    }, [])
  return (
    <div className={styles.processorContainer}>
        <div className={styles.proWrapper}>
            <div className={styles.containerLeft}>
                <Marquee>
                    <ul className={styles.scrollerL}>
                        {outputDataList.map((item,index) => {
                            return(
                                <li>
                                    <img src={item.img_path} alt={item.title} />
                                </li>
                            )}
                        )}
                    </ul>
                </Marquee>
            </div>
            <div className={styles.processor}>
                <div className={styles.logoCont}>

                </div>
            </div>
            <div className={styles.containerRight}>
                <Marquee>
                    <ul className={styles.scrollerR}>
                        {inputDataList.map((item,index) => {
                            return(
                                <li>
                                    <img src={item.img_path} alt={item.title} />
                                </li>
                            )}
                        )}
                    </ul>
                </Marquee>
            </div>
        </div>
    </div>
  )
}

export default Processor