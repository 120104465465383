import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './journey.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdateJourneyTitle = ({ templateId }) => {
  const [initialValue, setInitialValue] = useState();
  const [titleVal, setTitleVal] = useState();
  useEffect(() => {
    fetch(APIConstant.getJourneyTitle, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId: templateId })
    })
      .then(res => res.json())
      .then((data) => {
        setTitleVal(data)
        if (data.length > 0) {
          setInitialValue({
            title: data[0].title,
            description: data[0].description,
            status: data[0].status,
            visibility: data[0].visibility
          })

        } else {
          setInitialValue({
            title: '',
            description: '',
            status: '',
            visibility: ''
          })
        }
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.updatePayStack}>
      <Formik
        initialValues={initialValue}
        onSubmit={async (values) => {
          values.templateId = templateId
          if (titleVal.length > 0) {
            axios.put(APIConstant.updateJourneyTitle, values)
              .then(res => {
                console.log(res)
              })
              .catch(err => console.log(err))
          } else {
            console.log('add')
            axios.post(APIConstant.addJourneyTitle, values)
              .then(res => {
                console.log(res)
              })
              .catch(err => console.log(err))
          }
        }}
        enableReinitialize
      >
        <Form>

          <header className={styles.formHeader}>
            <h5>Edit Heading Text</h5>
            <div className={styles.btnContainer}>
              
              {titleVal?.length > 0
                ? <button className={styles.primary} variant="contained" type='submit'>Update</button>
                : <button className={styles.primary} variant="contained" type='submit'>Add</button>
              }
            </div>
          </header>

          <div className={styles.formContainer}>
            <div className="fieldContainer">
              <label htmlFor="title"> Title</label>
              <Field id="title" name="title" />
            </div>
            <div className="fieldContainer">
              <label htmlFor="description"> Description</label>
              <Field id="description" name="description" />
            </div>

          </div>
          {/* <div className={styles.btnContainer}>
            {titleVal?.length > 0
              ? <button variant="contained" type='submit'>Update</button>
              : <button variant="contained" type='submit'>Add</button>
            }
          </div> */}
        </Form>
      </Formik>


    </div>
  )
}

export default UpdateJourneyTitle