import { configureStore } from "@reduxjs/toolkit";
// import pageReducer from './slices/activePageSlice'
import activePageReducer from './slices/activePageSlice'
import pagesReducer from './slices/pagesSlice'

export const store = configureStore({
    devTools: true,
    reducer: {
        activePageId: activePageReducer,
        allPagesList: pagesReducer
    }
})