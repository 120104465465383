import React, { useEffect } from 'react'
import styles from './integration_made_easy.module.scss'
import images from '../../../assets/images/images'
import axios from 'axios'
import { APIConstant } from '../../../services/APIConstant'

const IntegrationMadeEasy = ({template}) => {
  const [pageTitleData, setPageTitleData] = React.useState();
  const [commonSectionList, setCommonSectionList] = React.useState();
  const [shoppingCartSectionList, setShoppingCartSectionList] = React.useState();
  useEffect(() => {
    
    axios.post(APIConstant.getIntegrationMadeEasyTitle,{templateId : template})
    .then(res => {
      console.log('titles :', res.data[0])
        setPageTitleData(res.data[0]);
    })
    .catch(err => console.log(err))
}, [])

useEffect(() => {
  axios.post(APIConstant.getCommonSubSectionIntegrationEasy,{templateId : template, category: 'common'})
  .then(res => {
    setCommonSectionList(res.data);
  })
  .catch(err => console.log(err))
}, [])

useEffect(() => {
  axios.post(APIConstant.getCommonSubSectionIntegrationEasy,{templateId : template, category: 'ShoppingCart'})
  .then(res => {
    setShoppingCartSectionList(res.data);
  })
  .catch(err => console.log(err))
}, [])
  return (
    <div className={styles.integrationMadeEasy}>
      {/* {console.log('title', pageTitleData)} */}
      <div className='container'>
        <h2 className={styles.title} data-aos="fade-up">{pageTitleData?.title}</h2>
        <p className={styles.subtitle} data-aos="fade-up">{pageTitleData?.description} </p>
        <div className={styles.grid_cont}>

          {commonSectionList?.map((item, index) => {
            return(
              <div className={`${styles.gridBox} ${styles.g_small}`} data-aos="fade-up" data-aos-duration="500" data-aos-delay="50">
                <span><img src={item.img_path} alt="API" width="80" /></span>
                <h4>{item.title}</h4>
              </div>
            )
          })

          }
         

          <div className={`${styles.gridBox} ${styles.g_large}`}  data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            {/* <h4>Shopping Cart</h4> */}
            <ul>
            {shoppingCartSectionList?.map((item, index) => {
              return(
                <li>
                  <span><img src={item.img_path} alt="API" width="80" /></span>
                  <p>{item.title}</p>
                </li>
                )
                })

                }
            </ul>
          </div>
        </div>   
      </div>
    </div>
  )
}

export default IntegrationMadeEasy