import React, { useEffect, useState } from 'react'
import styles from './company.module.scss'
import { APIConstant } from '../../services/APIConstant';
import { useSearchParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/home/navbar/navbar';
import Footer from '../../components/home/footer/footer';
import { useSelector } from 'react-redux'

const CompanyHome = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [pageId, setPageId] = useState()
    const [pages, setPages] = useState([]);
    const [policyPages, setPolicyPages] = useState([]);
    const [pageData, setPageData] = useState({});

    const activePageId = useSelector((state) => state.activePageId)
    // console.log('activePageId', activePageId.id)

    useEffect(() => {
        axios.post(APIConstant.getSelectedTemplate, { page_id: searchParams.get("id")  })
        .then(res => {
            setPageId(res.data[0])
        })
        .catch(err => console.log(err))
    }, [])
    useEffect(() => {
        axios.post(APIConstant.getSelectedPage, {page_id: searchParams.get("id") })
        .then(res => {
            setPageData(res.data[0])
        })
        .catch(err => console.log(err))
    }, [searchParams.get("id")])

  return (
    <div className=" policyPage">
        <div className={styles.mobileHeader} style={{backgroundColor: '#398378', height: '70px'}}>
            <Navbar />
        </div>
        <div className={styles.policyPage}>
            {/* <h4>{pageData?.page_name}</h4> */}
            <div className={styles.heading}>
                <h4>{pageData?.page_name}</h4>
            </div>
            <div className={styles.contentWrapper}>
                <div dangerouslySetInnerHTML={ {__html: pageId?.text_content} } />
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default CompanyHome