import React, { useEffect, useState } from 'react'
import styles from './addPlan.module.scss'
import { Field, Formik, Form } from 'formik';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const AddPricingPlan = (props) => {
  
 
  const [planBg, setPlanBg] = useState()
  const [features, setFeatures] = React.useState([]);
  const [initialValue, setInitialValue] = useState(
    {
      plan_name:'',
      price: '',
      time: 'month',
      color: '',
      feature : []
    }

  )

  useEffect(() => {
    const elColorPicker = document.getElementById("color");
    const changeColor = (evt) => {
        setPlanBg(evt.currentTarget.value)
    }
    elColorPicker.addEventListener("change", changeColor);
  })

  useEffect(() => {
    fetch(APIConstant.getpricingfeaturesList,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId : props.templateId})
      }
    )
    .then(res => res.json())
    .then((data) => {
      setFeatures(data)
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <div className={styles.addCatgoryItemContainer}>
        <h4>Add Plan</h4>

              <Formik
              initialValues={ initialValue }
              onSubmit={async (values) => {
                values.templateId = props.templateId
                for (let i = 0; i < features.length; i++) {
                  values.feature[i].featureId = features[i].feature_id
                  values.feature[i].featureName = features[i].title
                }
                
                axios.post(APIConstant.addPricingPlan, values)
                .then(res => {
                  values.planId = res.data.insertId
                  // axios.post(APIConstant.addPricingPlanValues, values)
                  // .then(res => {

                  // })
                  // .catch(err => console.log(err))

                })
                .catch(err => console.log(err))
              }}
              enableReinitialize
              >
                <Form>
                  <div className={styles.formContainer}>
                    <div className={styles.inlineForm}>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="plan_name"> Plan Title</label>
                        <Field id="plan_name" name="plan_name" style={{ width: '250px' }}/>
                      </div>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="price"> Plan Price</label>
                        <Field id="price" name="price" />
                      </div>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="time"> Plan Time Period</label>
                        <Field  as="select" name="time">
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </Field >
                      </div>
                      <div className={styles.fieldContainer}>
                        <label htmlFor="color"> Color Code</label>
                        <div className={styles.colorPicker}>
                          <Field id="color" name="color" type="color" />
                          {planBg}
                        </div>
                      </div>
                    </div>  

                    {features.length > 0 &&
                      <>
                        <h5>Enter Plan Feature Value</h5>
                        <div className={styles.featureValContainer}>
                          {features?.map((item, index) => {
                            return (
                              <div className={styles.fieldContainer}>
                                <label htmlFor={`feature[${index}].feature_val`}>{item.title}</label>
                                
                                <Field id={`feature[${index}].feature_val`} name={`feature[${index}].feature_val`} />
                              </div>
                            )}
                          )}
                        </div>
                      </>
                    }
                   
                    

                  </div>
                    
                  <div className={styles.btnContainer}>
                      <button variant="contained" type='submit'>Submit</button>
                  </div>
                </Form>
            </Formik>
                    
    </div>
  )
}

export default AddPricingPlan