import React, { useEffect, useState } from 'react'
import styles from './organization.module.scss'
import { Formik, Field, Form } from 'formik';
import FileUpload from '../../FileUpload/FileUpload';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const Organization = () => {
    const [initialValue, setInitialValue] = useState()
    const [darkBgLogo, setImagePath1] = useState()
    const [lightBgLogo, setImagePath2] = useState()
    const [logoIcon, setImagePath3] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
		if(data.length === 0){
			fetch(APIConstant.getOrganization)
			.then(res => res.json())
			.then((data) => {
                console.log('Data : ', data[0].youtube)
                setInitialValue({
                    orgname: data[0].org_name,
                    countrycode: data[0].country_code,
                    contact: data[0].contact,
                    email: data[0].email,
                    website: data[0].website,
                    address: data[0].address,
                    country: data[0].country,
                    state: data[0].state,
                    city: data[0].city,
                    pincode: data[0].pincode,
                    facebook: data[0].facebook,
                    twitter: data[0].twitter,
                    linkedin: data[0].linkedin,
                    youtube: data[0].youtube 
                })
                console.log('Data : ', initialValue)
            })
			.catch(err => console.log(err))
		}
	}, [data])

  return (
    <div className={styles.pageContainer}>
        <div className={styles.wrapper}>
          <h3>Organization</h3>
            <Formik
                initialValues={initialValue}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));

                    axios.put(APIConstant.updateOrganization, values)
                    .then(res => { 
                      console.log(res)
                    //   handleClick()
                    })
                    .catch(err => console.log(err))
                }}
                enableReinitialize
                >
                <Form>
                    <div className={styles.formContainer}>
                        <div className={styles.fieldContainer}>
                            <label>Organization Name</label>
                            <Field id="orgname" name="orgname" placeholder=""/>
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="countrycode">Country Code</label>
                            <Field id="countrycode" name="countrycode"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="contact">Contact Number</label>
                            <Field id="contact" name="contact" />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="email">Email</label>
                            <Field id="email" name="email" />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="website">Website URL</label>
                            <Field id="website" name="website"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="address">Address</label>
                            <Field id="address" name="address"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="country">Country</label>
                            <Field id="country" name="country"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="state">State</label>
                            <Field id="state" name="state"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="city">City</label>
                            <Field id="city" name="city"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="pincode">Pincode</label>
                            <Field id="pincode" name="pincode"  />
                        </div>
                    </div>
                    <div className={styles.formContainer} style={{marginTop: '20px'}}>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="facebook">Facebook</label>
                            <Field id="facebook" name="facebook" />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="twitter">Twitter</label>
                            <Field id="twitter" name="twitter"  />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="linkedin">Linkedin</label>
                            <Field id="linkedin" name="linkedin" />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="youtube">Youtube</label>
                            <Field id="youtube" name="youtube" />
                        </div>
                        
                    </div>
                    <div className={styles.uploadImageWrapper}>
                      <h4>Logo</h4>
                      <div className={styles.bannerImages}>
                          <div className={styles.bannerUploadSec}>
                            <h5>Dark Background Logo</h5>
                            <FileUpload setImagePath={setImagePath1} />
                          </div>
                          <div className={styles.bannerUploadSec}>
                            <h5>Light Background Logo</h5>
                            <FileUpload setImagePath={setImagePath2}/>
                          </div>
                          <div className={styles.bannerUploadSec}>
                            <h5>Logo Icon</h5>
                            <FileUpload setImagePath={setImagePath3} />
                          </div>
                      </div>
                    </div>
                <div className={styles.btnContainer}>
                        <button variant="contained" type='submit'>Submit</button>
                    </div>
                </Form>
            </Formik>
          
         
            
        </div>
    </div>
  )
}

export default Organization