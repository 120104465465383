import React, { useEffect } from 'react'
import styles from './pricingupdate.module.scss'
import images from '../../../../../assets/images/images'
import { Field, Form, Formik } from 'formik'
import axios from 'axios'
import { APIConstant } from '../../../../../services/APIConstant'

const AddFeature = ({getplans, templateId}) => {
    const [initialValue, setInitialValue] = React.useState({});
    useEffect(() => {
        
        let init = {
            featuretitle: '',
        }
        for (let i = 0; i < getplans.length; i++) {
            init[`feature${i+1}`] = ''
        }
        
        setInitialValue(init)
    }, [getplans])
  return (
    <div className={styles.pricingWrapper}>
        <div className={styles.pricingGrid}>
        <Formik
                    initialValues={initialValue}
                    onSubmit={async (values, { resetForm }) => {
                        let planData = []
                        for (let i = 0; i < getplans.length; i++) {
                            let planFieldVal = {planid: getplans[i].plan_id}
                            planFieldVal[`featureVal`] = values[`feature${i+1}`]
                            
                            planData.push(planFieldVal)
                        }
                        values.templateId = templateId
                        axios.post(APIConstant.addPricingFeature, values)
                        .then(res => {
                        //   console.log(res.data.insertId)
                            
                            for (let i = 0; i < planData.length; i++) {
                                planData[i].featureid = res.data.insertId;
                                console.log(planData)
                                planData[i].templateId = templateId
                                axios.post(APIConstant.addPricingPlanData, planData[i])
                                .then(res => {})
                                .catch(err => console.log(err))
                            }
                        })
                        .catch(err => console.log(err))

                        resetForm();
                    }}
                    enableReinitialize
                    >
                    <Form>
            <table className={styles.addRecord}>
                <tbody>
                
                        <tr>
                            <td>
                                <Field type="text" name="featuretitle" placeholder='Enter Feature' />
                            </td>
                            {getplans?.map((item, index) => {
                                return (
                                <td>
                                    <Field type="text" name={`feature${index+1}`}  />
                                </td>
                                )}
                            )}
                            <td>
                                <ul>
                                    <li><button type="submit" className={styles.btnAdd}><img src={images.ADD}alt="add" /></button></li>
                                </ul>
                            </td>
                        </tr>
                  
                </tbody>
            </table>
            </Form>
            </Formik>
        </div>
    </div>
  )
}

export default AddFeature