import React, { useEffect, useState } from 'react'
import styles from './portfolio.module.scss'
import { APIConstant } from '../../../services/APIConstant'
import axios from 'axios'
import images from '../../../assets/images/images'

const Portfolio = ({template}) => {
    const [headerValue, setHeaderValue] = useState({})
    const [sectionList, setSectionList] = useState([]);
    // const [selectedSecId, setSelectedSecId] = useState();
    // const [portfolioList, setPortfolioList] = useState([]);
    useEffect(() => {
        fetch(APIConstant.getPortfolioTitle, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : template})
          })
			.then(res => res.json())
			.then((data) => {
            setHeaderValue({
                title: data[0].title,
                description: data[0].description,
            })
      })
			.catch(err => console.log(err))
    }, [])

    // const getPortfolioSecionDetailList = (secid) => {
    //     console.log('sectionIdVal: ', secid)
    //     axios.post(APIConstant.getPortfolioSecionDetail, { section_id: secid, template_id: template })
    //     .then(res => {
    //         setPortfolioList(res.data)
    //     })
    //     .catch(err => console.log(err))
    // }
    useEffect(() => {
        fetch(APIConstant.getPortfolioSectionsAndData, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({templateID: template})
          })
            .then(res => res.json())
            .then((data) => {
                setSectionList(data)
            })
            .catch(err => console.log(err))

            
    //  }, [sectionList])

    }, [])


    // useEffect(() => {
    //     console.log('selectedSecId', selectedSecId)
    //     axios.post(APIConstant.getPortfolioSecionDetail, { section_id: selectedSecId })
    //         .then(res => {
    //             setPortfolioList(res.data)
    //         })
    //         .catch(err => console.log(err))
    // }, [selectedSecId])
  return (
    <div className={styles.portfolio}>
        <div className='container'>
            
            <h2 className={styles.title} data-aos="fade-up">{headerValue.title}</h2>
            <p className={styles.subtitle} data-aos="fade-up">{headerValue.description}</p>
            <div className={styles.port_btn_cont}>
                <ul className={`${styles.stickyHeader} ${styles.hideMobile}`}>
                    {sectionList?.map((item,index) => {
                        return(
                            <li key={index} data-aos="fade-up" data-aos-duration="500" data-aos-delay={index * 50}>
                                <h5>{item.title}</h5>
                                <span><img src={images.ARROWRIGHT} alt="arrow" width="30"/></span>
                            </li>
                        )
                    })}
                </ul>
                <ul>
                    {sectionList?.map((item,index) => {
                        return(
                            <li key={index} data-aos="fade-up" data-aos-duration="500" data-aos-delay={index * 50}>
                                <div className={styles.showOnlyMobile}>
                                    <h5>{item.title}</h5>
                                </div>

                               

                                <div className={styles.grid_cont} data-aos="fade-up" data-aos-duration="500" data-aos-delay="50">
                                    {item.listitem?.map((item,index) => {
                                        return(
                                            <div className={styles.grid} key={index}>
                                                <div className={styles.gbox}>
                                                    <div className={styles.img_cont}>
                                                        <img src={item.img_path} alt="Banner Image"/>
                                                    </div>
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </li>
                        )   
                    })}
                </ul>
            </div>

            
        </div>
    </div>
  )
}

export default Portfolio