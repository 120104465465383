import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';

const RichTextEditor = ({ getChildData, parentData }) => {
    const editor = useRef(null);
	// const [content, setContent] = useState('');

    const sendEditorDataToParent = (newContent) => {
        // console.log('newContent', newContent)
        getChildData(newContent)
    }
    return <JoditEditor
                ref={editor}
                value={parentData}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => sendEditorDataToParent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            />
}

export default RichTextEditor