import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { APIConstant } from "../../../../../services/APIConstant";
import styles from './bannerupdate.module.scss';
import images from "../../../../../assets/images/images";





// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  
  
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "#FFFFFF",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#FFFFFF",
  padding: grid,
  width: '100%',
});

const BannerDND = ({ template, editBannerWithId, deleteBannerWithId }) => {
  const [items, setItems] = useState([]);
//   const [banners, setBanners] = useState(false);

  const pullBanners = (template) => {
    // axios.get(APIConstant.getLandingBanners)
    axios.post(APIConstant.getHomeBannersWithId, {template: template})
    .then(res => {  
        setItems(res.data)
    })
    .catch(err => console.log(err))
  }
    useEffect(() => {
        pullBanners(template)

    }, [])


//   useEffect(() => {
//     setItems(data);
//   }, []);
    const sequenceBanner = async (updatedlists) => {
        axios.post(APIConstant.sequenceHomeBanner, {templateId: template, bannerlist: updatedlists})
        .then(res => {  
            // setItems(res.data)
        })
        .catch(err => console.log(err))

    }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    // console.log({ reorderedItems });
    setItems(reorderedItems);

    sequenceBanner(reorderedItems).then((data) => {
        // pullBanners(template)
    })
  };

  const editBanner = (id) => {
    editBannerWithId('Update', id)
  }
  const deleteBanner = (id) => {
    deleteBannerWithId(id)
  }

  return (
    <div className={styles.bannerContainer}>
        {/* {console.log('Banners', banners)} */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Alignment</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    
                
                {items.map((item, index) => (
                    <Draggable key={`${item.banner_id}`} draggableId={`${item.banner_id}`} index={index}>
                    {(provided, snapshot) => (
                        <tr
                        className="card"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        >
                                <td>
                                    <img src={item.img_path} alt="Banner" className={styles.thumbnailBannerImage}/>
                                </td>
                                <td>{item.title}</td>
                                <td>{item.description}</td>
                                <td>{item.align_type}</td>
                                <td>
                                    <div className={styles.actionBtns}>
                                        <ul>
                                            <li><a className={styles.edit} onClick={() => editBanner(item.banner_id)}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                                            <li><a className={styles.delete} onClick={() => deleteBanner(item.banner_id)}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                                        </ul>
                                    </div>
                                </td>
                        </tr>
                    )}
                    </Draggable>
                ))}
                </tbody>
            </table>


              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BannerDND;
