// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete_deleteRecordConfirm__RlFBk {
  padding: 50px;
}
.delete_deleteRecordConfirm__RlFBk h4 {
  font-size: 20px;
}
.delete_deleteRecordConfirm__RlFBk .delete_btnCont__2fj9F {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.delete_deleteRecordConfirm__RlFBk .delete_btnCont__2fj9F button {
  height: 34px;
  padding: 0px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
.delete_deleteRecordConfirm__RlFBk .delete_btnCont__2fj9F button.delete_btnBordered__ruwjc {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.delete_deleteRecordConfirm__RlFBk .delete_btnCont__2fj9F button.delete_btnPrimary__NasIo {
  background-color: #398378;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dialog/delete/delete.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,eAAA;AAER;AAAI;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAER;AADQ;EACI,YAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAGZ;AAFY;EACI,oCAAA;AAIhB;AAFY;EACI,yBAAA;EACA,cAAA;AAIhB","sourcesContent":[".deleteRecordConfirm{\n    padding: 50px;\n    h4{\n        font-size: 20px;\n    }\n    .btnCont{\n        display: flex;\n        justify-content: flex-end;\n        margin-top: 20px;\n        button{\n            height: 34px;\n            padding: 0px 15px;\n            font-size: 14px;\n            border: none;\n            border-radius: 5px;\n            margin-left: 10px;\n            &.btnBordered{\n                border: 1px solid rgba(0,0,0,0.1)\n            }\n            &.btnPrimary{\n                background-color: #398378;\n                color:#FFFFFF;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteRecordConfirm": `delete_deleteRecordConfirm__RlFBk`,
	"btnCont": `delete_btnCont__2fj9F`,
	"btnBordered": `delete_btnBordered__ruwjc`,
	"btnPrimary": `delete_btnPrimary__NasIo`
};
export default ___CSS_LOADER_EXPORT___;
