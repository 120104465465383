import React from 'react'
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import styles from './signup.module.scss';
import images from '../../../assets/images/images';

const SignUp = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.signupCont}>
      <div className={styles.banner}><img src={images.LOGO} width="200" alt="Logo"/></div>
      <div className={styles.signupWrapper}>
        <div className={styles.signupBox}>
          <h3>
            Create your PayOrc account
          </h3>
          <p>Make your payments easy and fun!</p>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              if(values.username === 'admin' && values.password === 'admin'){
                navigate('/admin/organization')
              }
            }}
          >
            <Form>
              <div className={styles.fieldCont}>
                <label htmlFor="businessname">Business name</label>
                <Field id="businessname" name="businessname" />
              </div>
              <div className={styles.fieldCont}>
                <label htmlFor="country">Business registered country</label>
                <Field id="country" name="country" />
              </div>
              <div className={styles.fieldCont}>
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" />
              </div>
              <div className={styles.fieldCont}>
                <label htmlFor="mobile">Mobile</label>
                <Field id="mobile" name="mobile" />
              </div>
              <div className={styles.fieldCont}>
                <button type="submit">Sign Up</button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default SignUp