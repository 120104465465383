import React, { useState } from 'react'
import styles from './previewNoIntegration.module.scss';
import { Dialog, Switch } from '@mui/material';
import OmniChannel from '../../home/omnichannel/omni_channel';
import UpdateNoCode from '../../admin/home/sections/nocode/UpdateNoCode';
import images from '../../../assets/images/images';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import DeleteConfirmationDialog from '../../dialog/deleteConfirmationDialog/deleteConfirmationDialog';
import { TextConstant } from '../../../constant/common';
import DeleteToaster from '../../toaster/delete/deleteToaster';

const PreviewNoIntegration = ({templateId}) => {
  const [checked, setChecked] = useState();
  const [viewMode, setViewMode] = useState('preview');
  const [openDeleteToaster, setOpenDeleteToaster] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);  

  const handleOpenDeleteConform = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseDeleteConform = () => {
    setOpenConfirmDeleteDialog(false);
  };


  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if (checked) {
      visbility = 'hide'
    }
    else {
      visbility = 'active'
    }
  };
  const deleteTemplate = () => {
    axios.post(APIConstant.deleteTemplate, {templateId : templateId})
    .then(res => {
      setOpenDeleteToaster(true)
    })
    .catch(err => console.log(err))
  }
  const confirmValue = (res) => {
    if(res){
      deleteTemplate()
      handleCloseDeleteConform()
    }else{
      handleCloseDeleteConform()
    }
  }

  return (
    <div className={`${styles.platformPreviewCont} ${styles.previewContainer}`}>
      <header>
        <h5>No-Integration</h5>
        <ul className={styles.sectionBtns}>
            {/* <li>
              <div className="customeSwitch">
                  <Switch
                  checked={checked}
                  onChange={handleVisibilityChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <span className={checked ? 'active switchlabel' : 'hide switchlabel'}>{checked ? 'Active' : 'Hide'}</span>
              </div>
            </li> */}
            {viewMode === 'preview' &&
              <>
                <li style={{marginTop: '10px'}}>
                  <button className={styles.delete} onClick={handleOpenDeleteConform }>
                    <img src={images.DELETE} width="20" title="Delete" alt="delete" />
                  </button>
                </li>
                <li style={{marginTop: '10px'}}>
                    <button className={styles.edit} onClick={() => setViewMode('edit')}>Edit</button>
                </li>
              </>
            }
            {viewMode === 'edit' &&
              <>
                <li className={styles.discard}>
                  <button className={styles.discard} onClick={() => setViewMode('preview')}>Discard</button>
                </li>
                <li className={styles.preview}>
                  <button className={styles.discard} onClick={() => setViewMode('preview')}>Preview</button>
                </li>
              </>
            }
        </ul>
      </header>
      {viewMode === 'preview' &&
          <OmniChannel  template={templateId} />
      }
      {viewMode === 'edit' &&
          <UpdateNoCode template={templateId}/>
      }

      {/* Dialog */}
      <Dialog open={openConfirmDeleteDialog} onClose={handleCloseDeleteConform}>
          <DeleteConfirmationDialog message={{title:TextConstant.AreYouSure, description: TextConstant.DeleteConfirmMessage}} getConfirmation={confirmValue}/>
        </Dialog>

        {/* Toaster */}
        {openDeleteToaster &&
          <DeleteToaster/>
        }

    </div>
  )
}

export default PreviewNoIntegration