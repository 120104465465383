import React from 'react'
import styles from './platforms.module.scss'
import PlatformSubSectionList from './SubSectionList'

const PlatformSubSections = ({templateId}) => {
  return (
    <div className={styles.paymentSections}>
        <div className={styles.secGrid}>
            
            <div className={styles.sectionList}>
                <PlatformSubSectionList templateId={templateId}/>
            </div>
        </div>
    </div>
  )
}

export default PlatformSubSections