import React from 'react'
import styles from './footerupdate.module.scss'

const FooterForm = () => {
  return (
    <div className={styles.footerFormCont}>
        <h4>Logo</h4>
        <div className={styles.logoCont}>
            <div className={styles.fcol}>
                <div className="fieldContainer">
                    <label>Width</label>
                    <input type='text' />
                </div>
            </div>
            <div className={styles.fcol}>
                <div className="fieldContainer">
                    <label>Height</label>
                    <input type='text' />
                </div>
            </div>
        </div>
        <div className={styles.sectionContainer}>
            <header className={styles.header}>
                <h4>Sections</h4>
                <button>Add Section</button>
            </header>
            <section>
                <input type="text" placeholder='Section Name' value="Company"/>
                <table>
                    <thead>
                        <tr>
                            <th>Link Name</th>
                            <th>Permalink</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input type="text" /></td>
                            <td><input type="text" /></td>
                            <td>
                                <ul>
                                    <li><button>Add</button></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>About Us</td>
                            <td><a className={styles.permalink}>http://localhost:3000/admin/aboutus</a></td>
                            <td>
                                <ul>
                                    <li><button>edit</button></li>
                                    <li><button>delete</button></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <div className={styles.btnContainer}>
                <button>Save Changes</button>
            </div>
        </div>
    </div>
  )
}

export default FooterForm