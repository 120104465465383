import React, { useEffect, useState } from 'react'
import styles from './roundrobinribbon.module.scss';
import Marquee from "react-fast-marquee";
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const RoundRobinRibbon = ({templateId}) => {
  const [imageRRR, setImageRRR] = useState();
  const [contentRRR, setContentRRR] = useState();
  const [activeRRR, setActiveRRR] = useState();

  useEffect(() => {
    axios.post(APIConstant.getImageRRR,{templateId : templateId})
    .then(res => {
      setImageRRR(res.data)
      if(res.data.length > 0){
        setActiveRRR('imagerrr')
      }
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    axios.post(APIConstant.getContentRRR,{templateId : templateId})
    .then(res => {
      setContentRRR(res.data)
      if(res.data.length > 0){
        setActiveRRR('contentrrr')
      }
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <div className={styles.rrrContainer}>
      <Marquee>


        <div className={styles.marqueeGridCont}>

          {activeRRR === 'contentrrr' &&
          <>
            {contentRRR?.map((item, index) => {
              return(
                <div className={styles.gridCellCont}>
                  <div className={styles.boxWrapper}>
                    <header>
                      <div className={styles.profileImg}></div>
                      <div className={styles.proData}>
                        <h5>{item.username}</h5>
                        <p>{item.country}</p>
                      </div>
                    </header>
                    <div className={styles.ratingCont}>
                      <ul>
                        <li>*</li>
                        <li>*</li>
                        <li>*</li>
                        <li>*</li>
                        <li>*</li>
                      </ul>
                      <span>{item.date}</span>
                    </div>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                    <a>Continue reading</a>
                  </div>
                </div>
              )}
            )}
            </>
          
          }

          {activeRRR === 'imagerrr' &&
            <>
              {imageRRR?.map((item, index) => {
                return(
                  <div className={styles.gridCellCont}>
                    <div className={`${styles.boxWrapper} ${styles.imgBanner}`}>
                      <div className={styles.imgRRRCont}>
                        <img src={item.img_path} alt={item.title} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          }
                    
          

        </div>
      </Marquee>
    </div>
  )
}

export default RoundRobinRibbon