// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal_legalHome__hUvJx > header {
  background-color: #0a352f;
  padding-top: 100px;
  padding-bottom: 50px;
}
.legal_legalHome__hUvJx > header h3 {
  text-align: center;
  color: #FFFFFF;
  font-size: 40px;
}
.legal_legalHome__hUvJx .legal_mainContainer__yGR9m {
  padding: 50px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/legal/legal.module.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,kBAAA;EACA,oBAAA;AAAR;AACQ;EACI,kBAAA;EACA,cAAA;EACA,eAAA;AACZ;AAEI;EACI,iBAAA;AAAR","sourcesContent":[".legalHome{\n    & > header{\n        background-color: #0a352f;\n        padding-top: 100px;\n        padding-bottom: 50px;\n        h3{\n            text-align: center;\n            color: #FFFFFF;\n            font-size: 40px;\n        }\n    }\n    .mainContainer{\n        padding: 50px 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalHome": `legal_legalHome__hUvJx`,
	"mainContainer": `legal_mainContainer__yGR9m`
};
export default ___CSS_LOADER_EXPORT___;
