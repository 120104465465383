import React, { useEffect, useState } from 'react'
import styles from './editLcri.module.scss'
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import FileUpload from '../../../../FileUpload/FileUpload';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';
import FullPageLoader from '../../../../loader/loader';

const EditLCRI = ({templateId, pageViewMode}) => {
    const [initialValues, setInitialValues] = useState();
    const [showLoader, setShowLoader] = useState(false)
    const [imgPath1, setImagePath1] = useState()
    const [mode, setMode] = useState();
    const [id, setId] = useState();
    const [descriptionData, setDescriptionData] = useState('');
      useEffect(() => {
        fetch(APIConstant.getLcri, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            console.log('Data :', data)
            if(data.length > 0){
                setMode('update')
                setId(data[0].id)
                setInitialValues({
                    title: data[0].title,
                    heading: data[0].heading,
                    description: data[0].description,
                    text: data[0].link_text,
                    url: data[0].link_url,
                    imgPath: data[0].img_path
                })
                setDescriptionData(data[0].description)
            }else{
                setMode('add')
                setInitialValues({
                    title: '',
                    heading: '',
                    description: '',
                    text: '',
                    url: '',
                    imgPath: ''
                })
            }
        })
        .catch(err => console.log(err))
    }, [])

    const textEditorData = (descriptionData) => {
      setDescriptionData(descriptionData)
      // console.log('descriptionData', descriptionData)
    }

  return (
    <div className={styles.lcriCont}>
      {showLoader &&
        <FullPageLoader />
      }

         <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        values.templateId = templateId
        values.description = descriptionData;
        if(mode==='add'){
            values.imgPath = imgPath1
            axios.post(APIConstant.addLcri, values)
            .then(res => {
              if(res.data.message === 'success'){
                setShowLoader(false)
                pageViewMode('preview')
              }
            })
            .catch(err => console.log(err))
        }
        if(mode==='update'){
            values.id=id
            if(imgPath1){
                values.imgPath = imgPath1
            }else{
                values.imgPath = initialValues.imgPath
            }
            axios.put(APIConstant.updateLcri, values)
            .then(res => {
              if(res.data.message === 'success'){
                setShowLoader(false)
                pageViewMode('preview')
              }
            })
            .catch(err => console.log(err))
        }
        
      }}
      enableReinitialize
    >
      <Form>
        <header className={styles.formHeader}>
          <h5>{mode==='update'
                ? 'Update '
                : 'Add '
            } LCRI Text</h5>
          <div style={{marginTop: '0px'}} className={styles.btnContainer}>
            {mode==='update'
                ? <button className={styles.primary} type="submit">Update</button>
                : <button className={styles.primary} type="submit">Add</button>
            }
          </div>
        </header>
        <div className={styles.formCont}>
            {/* <h4>Edit LCRI</h4> */}
            <label htmlFor="title">Title</label>
            <Field id="title" name="title" />

            <label htmlFor="heading">Heading</label>
            <Field id="heading" name="heading"  />

            <label htmlFor="description">Description</label>
            {/* <Field id="description" name="description"  /> */}
            <RichTextEditor getChildData={textEditorData} parentData={descriptionData} />
            

            <label htmlFor="text">Button Link Text</label>
            <Field id="text" name="text"  />
            <label htmlFor="url">Link Url</label>
            <Field id="url" name="url"  />

            <FileUpload setImagePath={setImagePath1} />
            {/* <div style={{marginTop: '10px'}}>
              {mode==='update'
                  ? <button type="submit">Update</button>
                  : <button type="submit">Add</button>
              }
            </div> */}
        </div>
      </Form>
    </Formik>
    </div>
  )
}

export default EditLCRI