import React from 'react'
import styles from './delete.module.scss'

const DeleteConfirmation = ({ selected, closeDialog, confirmDeleteDialog }) => {
  return (
    <div className={styles.deleteRecordConfirm}>
        <h4>Are you sure you want to delete?</h4>
        <div className={styles.btnCont}>
            <button className={styles.btnBordered} onClick={closeDialog}>Cancel</button>
            <button className={styles.btnPrimary} onClick={() => confirmDeleteDialog(selected)}>Yes, Delete</button>
        </div>
    </div>
  )
}

export default DeleteConfirmation