import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pages: []
}

const pagesSlice = createSlice({
    name: 'pages',
    initialState: initialState,
    reducers: {
        allPagesList(state, action){
            state.pages = action.payload
        }
    }
})

export const { allPagesList } = pagesSlice.actions;
export default pagesSlice.reducer