// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roundrobinribbon_formContainer__c0ZFt {
  width: 500px;
}

.roundrobinribbon_actionBtns__emwht {
  display: flex;
  align-items: center;
  height: 50px;
}
.roundrobinribbon_actionBtns__emwht ul {
  display: flex;
}
.roundrobinribbon_actionBtns__emwht ul li {
  margin: 0px 5px;
}
.roundrobinribbon_actionBtns__emwht ul li a {
  width: 30px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roundrobinribbon_actionBtns__emwht ul li a.roundrobinribbon_edit__avnJX {
  background-color: rgba(25, 118, 210, 0.1);
}
.roundrobinribbon_actionBtns__emwht ul li a.roundrobinribbon_edit__avnJX img {
  stroke: #1976d2;
}
.roundrobinribbon_actionBtns__emwht ul li a.roundrobinribbon_delete__8xgPA {
  background-color: rgba(200, 31, 40, 0.1);
}
.roundrobinribbon_actionBtns__emwht ul li a.roundrobinribbon_delete__8xgPA img {
  stroke: #C81F28;
}

.roundrobinribbon_imageCont__bcYiI {
  padding: 20px;
}
.roundrobinribbon_imageCont__bcYiI h5 {
  margin-bottom: 10px;
}

.roundrobinribbon_marqueeCont__Evq03 h5 {
  margin-bottom: 15px;
}
.roundrobinribbon_marqueeCont__Evq03 .roundrobinribbon_radioGroup__IKjn- {
  margin: 10px 0px;
}
.roundrobinribbon_marqueeCont__Evq03 .roundrobinribbon_radioGroup__IKjn- label {
  display: inline-block;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/home/sections/roundrobinribbon/roundrobinribbon.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AACI;EACI,aAAA;AACR;AAAQ;EACI,eAAA;AAEZ;AADY;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGhB;AAFgB;EACI,yCAAA;AAIpB;AAHoB;EACI,eAAA;AAKxB;AAFgB;EACI,wCAAA;AAIpB;AAHoB;EACI,eAAA;AAKxB;;AAGA;EACI,aAAA;AAAJ;AACI;EACI,mBAAA;AACR;;AAII;EACI,mBAAA;AADR;AAGI;EACI,gBAAA;AADR;AAEQ;EACI,qBAAA;EACA,kBAAA;AAAZ","sourcesContent":[".formContainer{\n    width: 500px;\n}\n\n\n.actionBtns{\n    display: flex;\n    align-items: center;\n    height: 50px;\n    ul{\n        display: flex;\n        li{\n            margin: 0px 5px;\n            a{\n                width: 30px;\n                border-radius: 5px;\n                height: 30px;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                &.edit{\n                    background-color: rgba(#1976d2, 0.1);\n                    img{\n                        stroke:#1976d2;\n                    }\n                }\n                &.delete{\n                    background-color: rgba(#C81F28, 0.1);\n                    img{\n                        stroke:#C81F28;\n                    }\n                }\n            }\n        }\n    }\n}\n\n.imageCont{\n    padding: 20px;\n    h5{\n        margin-bottom: 10px;\n    }\n}\n\n.marqueeCont{\n    h5{\n        margin-bottom: 15px;\n    }\n    .radioGroup{\n        margin: 10px 0px;\n        label{\n            display: inline-block;\n            margin-right: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `roundrobinribbon_formContainer__c0ZFt`,
	"actionBtns": `roundrobinribbon_actionBtns__emwht`,
	"edit": `roundrobinribbon_edit__avnJX`,
	"delete": `roundrobinribbon_delete__8xgPA`,
	"imageCont": `roundrobinribbon_imageCont__bcYiI`,
	"marqueeCont": `roundrobinribbon_marqueeCont__Evq03`,
	"radioGroup": `roundrobinribbon_radioGroup__IKjn-`
};
export default ___CSS_LOADER_EXPORT___;
