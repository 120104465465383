import React from 'react'
import styles from './cookie_policy.module.scss'

const CookiePolicy = () => {
    const [showCookie, setShowCookie] = React.useState(true);
  return (
    <>
    {showCookie === true &&
    <div className={styles.cookie_wrapper}>
        
        <div className="container">
            <div className={styles.cookie_policy}>
                <div className={styles.cookie_content}>
                    <h4>Cookie Consent</h4>
                    <p>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies. <a>Read More</a></p>
                </div>
                <div className={styles.cookie_btn}>
                    <ul>
                        <li><button onClick={() => setShowCookie(false)} className={styles.btn_bordered}>Decline</button></li>
                        <li><button onClick={() => setShowCookie(false)} className={styles.btn_solid}>Accept All</button></li>
                    </ul>
                </div>
            </div>
        </div>
      
    </div>
}
</>
  )
}

export default CookiePolicy