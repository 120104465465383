import React from 'react';
import styles from './loader.module.scss';
import images from '../../assets/images/images';

const FullPageLoader = () => {
  return (
    <div className={styles.loaderContainer}>
        <img src={images.LOADER} alt="Loader" width="100" />
    </div>
  )
}

export default FullPageLoader