import React, { useEffect, useState } from 'react'
import styles from './paymentstack.module.scss'
import SectionList from './SectionList'
import { APIConstant } from '../../../../../services/APIConstant';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { Dialog } from '@mui/material';
import AddSectionList from './addSectionList';

const PaymentSections = ({templateId}) => {
    const [initialValue, setInitialValue] = useState();
    const [sectionList, setSectionList] = useState([]);
    const [sectionId, setSectionId] = useState();
    const [selectedSection, setSelectedSection] = useState()
    const [openSectionDialog, setOpenSectionDialog] = React.useState(false);

    const [modeType, setModeType] = useState();

    const handleOpenSectionDialog = (mode) => {
        setOpenSectionDialog(true);
        setModeType(mode)
    };

    const handleCloseSectionDialog = () => {
        setOpenSectionDialog(false);
    };

    useEffect(() => {
        
        fetch(APIConstant.getPortfolioSections, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateID : templateId})
          })
            .then(res => res.json())
            .then((data) => {
                setSectionList(data)
            })
            .catch(err => console.log(err))
     }, [sectionList])

     useEffect(() => {
        if(sectionId === -1){
            setInitialValue({title: ''})
        }else{
            let x = sectionList.find(obj => obj.section_id === sectionId);
            // console.log('ddsdsd', x)
            setInitialValue({title: x?.title})
        }
     }, [sectionId])

     const viewDetailSection = (id) => {
       
        setSectionId(id)
        axios.post(APIConstant.getSelectedPortfolioTitle, { section_id : id })
            .then(res => {
                setSelectedSection(res.title)
            })
            .catch(err => console.log(err))
     }
  return (
    <div className={styles.paymentSections}>
        
        <div className={styles.secList}>
           
            <header>
                <h5>Group</h5>
                <a onClick={() => setSectionId(-1)}>Add</a>
            </header>
            <ul>
                {sectionList?.map((item, index) => {
                    return (
                        <li className={sectionId === item.section_id ? `${styles.active}` : ''}><a onClick={() => viewDetailSection(item.section_id)}>{item.title}</a></li>
                    )}
                )}
            </ul>
        </div>
        <div className={styles.secGrid}>
            {(sectionId > 0 || sectionId === -1) &&
                
                    <Formik
                initialValues={initialValue}
                
                onSubmit={async (values) => {
                    values.templateId = templateId
                    if(sectionId === -1){
                        axios.post(APIConstant.createPortfolioSections, values)
                        .then(res => {
                            setSectionId(res.data.insertId)
                        })
                        .catch(err => console.log(err))
                    }
                    else{
                        values.sectionId = sectionId
                        axios.put(APIConstant.updatePortfolioSections, values)
                        .then(res => {
                            setSectionId(res.data.insertId)
                        })
                        .catch(err => console.log(err))
                    }
                }}
                enableReinitialize
              > 
                <Form>
                    <div className={styles.inlineForm}>
                        <div className={styles.formGroup}>
                            <label>Group Name</label>
                            <Field id="title" name="title" placeholder=""  />
                        </div>
                        <div className={styles.btnCont}>
                            {sectionId > 0 &&
                                <button type='submit' variant="contained">Update</button>
                            }
                            {sectionId === -1 &&
                                <button type='submit' variant="contained">Add</button>
                            }
                        </div>
                    </div>
                    </Form>
                    </Formik>
               
            }
            <div className={styles.sectionList}>
                <SectionList setSectionId={sectionId} template={templateId}/>
                <button onClick={() => handleOpenSectionDialog('Add')}>Add Item</button>

                <Dialog open={openSectionDialog} onClose={handleCloseSectionDialog}>
                    <AddSectionList mode={modeType} selected={sectionId} template={templateId}/>
                </Dialog>
            </div>
        </div>
    </div>
  )
}

export default PaymentSections