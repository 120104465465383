import axios from "axios";
import { useRef, useState } from "react"
import { APIConstant } from "../../services/APIConstant";
import styles from "./FileUpload.module.scss";
import ErrorToaster from "../toaster/error/errorToaster";
import images from "../../assets/images/images";
// import CHECK from '../../images/icons/check.svg';

export default function FileUpload({setImagePath, mode}){
    const [status, setStatus] = useState(false);
    const [filePath, setFilePath] = useState();
    const [showErrorToaster, setShowErrorToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [file, setFile] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [fileName, setFileName] = useState("")
    const [fileSize, setFileSize] = useState()
    const fileInput = useRef();

    const saveFile = () => {
        setUploadedImage(URL.createObjectURL(fileInput.current.files[0]))
        setFile(fileInput.current.files[0])
        setFileName(fileInput.current.files[0].name)
        setFileSize(fileInput.current.files[0].size)
        setStatus(false)
    }
    const uploadFile = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        try {
            axios.post(APIConstant.imageUpload, formData)
            .then((res)=>{
                if(res.data.status === 'success'){
                    console.log(res.data.imgpath)
                    setImagePath(res.data.imgpath)
                    setStatus(true)
                }
                if(res.data.status === 'err'){
                    setToasterMessage(res.data.message)
                    setShowErrorToaster(true)
                }
            })
        }catch(ex){
 
        }
    }
    const getFileSize = () => {
        const sizeInKb = fileSize / 1024;

        if (sizeInKb > 1024) {
        return `${(sizeInKb / 1024).toFixed(2)} mb`;
        } else {
        return `${sizeInKb.toFixed(2)} kb`;
        }
    }
    return (
        <div className={styles.fileUploadContainer}>    
        
            <div className={styles.uploadedFile}>

                
                
                {fileName === ''
                    ? <div className={styles.browseCont}>
                            <input type="file" ref={fileInput} onChange={saveFile}/>
                            {mode === 'Add'
                                ? <span className={styles.choose}>Add Image</span>
                                : <span className={styles.choose}>Change Image</span>
                            }
                        </div>
                    :   <div className={styles.upploadedImageCont}>
                        <h4>Added Image File</h4>
                        <ul>
                            <li>
                                <img src={uploadedImage} alt="Banner" width="300" style={{border: '1px solid rgba(0,0,0,0.3)'}}/>
                                <div className={styles.browseCont} >
                                            <input type="file" ref={fileInput} onChange={saveFile}/>
                                            
                                                <span className={styles.choose}>Change Image</span>
                                            
                                        </div>
                            </li>
                            <li>
                                <h6>{fileName}</h6>
                                <p>File Size: <span>{getFileSize(fileSize)}</span></p>
                                {!status &&
                                    <a onClick={uploadFile}>Upload Image File</a>
                                }
                                        
                              
                            </li>
                        </ul>
                        </div>
                }
                

            </div>
            
            
           {status && (
                <span className={styles.status}>
                    {/* <img src={CHECK} alt="Check" width="24" /> */}
                </span>
           )}
            {showErrorToaster &&
                <ErrorToaster message={toasterMessage}/>
            }
            
        </div>
    )
}