import React, { useEffect, useState } from 'react'
import styles from './omni_channel.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const OmniChannel = ({template}) => {
  const [pageTitleData, setPageTitleData] = useState('payments');
  const [pageListData, setPageListData] = useState([]);
    useEffect(() => {
        axios.post(APIConstant.getNoCodeIntegrationTitle, {templateId : template})
        .then(res => {
            if(res.data.length > 0){
              setPageTitleData(res.data[0]);
            }
        })
        .catch(err => console.log(err))

        axios.post(APIConstant.getNoCodeIntegrationList, {templateId : template})
        .then(res => {
          console.log(res.data)
            // if(res.data.length > 0){
              setPageListData(res.data);
            // }
        })
        .catch(err => console.log(err))
    }, [])

   
  return (
    <div className={styles.omni_channel}>
        <div className='container'>
            <h2 className={styles.title} data-aos="fade-up">{pageTitleData?.title}</h2>
            <p className={styles.subtitle} data-aos="fade-up">{pageTitleData?.description}</p>
            <div className={styles.grid_cont}>
              {/* {console.log('hsd', pageListData)} */}
              <ul>
                {pageListData?.map((item, index) => {
                  return(
                    <li data-aos="fade-up">
                      <div className={styles.imgCont}>
                        <span>
                          <img src={item.image_path} alt="Icon" width="40" />
                        </span>
                      </div>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </li>
                  )}
                )}
                
                
              </ul>
            </div>
        </div>
    </div>
  )
}

export default OmniChannel