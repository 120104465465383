// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookie_policy_cookie_wrapper__aGboe {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  position: sticky;
  bottom: 0px;
  z-index: 100;
}
.cookie_policy_cookie_policy__pfJ9m {
  display: flex;
}
.cookie_policy_cookie_content__QcGcN {
  width: calc(100% - 250px);
}
.cookie_policy_cookie_content__QcGcN h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.cookie_policy_cookie_content__QcGcN p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}
.cookie_policy_cookie_btn__nldiR {
  width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cookie_policy_cookie_btn__nldiR button {
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
}
.cookie_policy_cookie_btn__nldiR .cookie_policy_btn_bordered__i9LVG {
  background-color: #FFFFFF;
  color: #666666;
  border: 1px solid #666666;
}
.cookie_policy_cookie_btn__nldiR .cookie_policy_btn_solid__kODQm {
  background-color: #398378;
  color: #FFFFFF;
  border: 1px solid #398378;
}
.cookie_policy_cookie_btn__nldiR ul {
  display: flex;
}

button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/home/cookiepolicy/cookie_policy.module.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,2CAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEI;EACI,aAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AACQ;EACI,eAAA;EACA,mBAAA;AACZ;AACQ;EACI,eAAA;EACA,yBAAA;EACA,iBAAA;AACZ;AAEI;EACI,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AAAR;AACQ;EACI,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACZ;AACQ;EACI,yBAAA;EACA,cAAA;EACA,yBAAA;AACZ;AACQ;EACI,yBAAA;EACA,cAAA;EACA,yBAAA;AACZ;AACQ;EACI,aAAA;AACZ;;AAIA;EACI,eAAA;AADJ","sourcesContent":[".cookie{\n    &_wrapper{\n        background-color: #FFFFFF;\n        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);\n        padding: 20px 0px;\n        position: sticky;\n        bottom: 0px;\n        z-index: 100;\n    }\n    &_policy{\n        display: flex;\n    }\n    &_content{\n        width: calc(100% - 250px);\n        h4{\n            font-size: 20px;\n            margin-bottom: 10px;\n        }\n        p{\n            font-size: 16px;\n            color: rgba(0,0,0,0.6);\n            line-height: 20px;\n        }\n    }\n    &_btn{\n        width: 250px;\n        display: flex;\n        justify-content: flex-end;\n        align-items: center;\n        button{\n            border-radius: 5px;\n            padding: 10px 20px;\n            margin-left: 10px;\n            font-size: 16px;\n        }\n        .btn_bordered{\n            background-color: #FFFFFF;\n            color: #666666;\n            border: 1px solid #666666;\n        }\n        .btn_solid{\n            background-color: #398378;\n            color: #FFFFFF;\n            border: 1px solid #398378;\n        }\n        ul{\n            display: flex;\n        }\n    }\n}\n\nbutton{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookie_wrapper": `cookie_policy_cookie_wrapper__aGboe`,
	"cookie_policy": `cookie_policy_cookie_policy__pfJ9m`,
	"cookie_content": `cookie_policy_cookie_content__QcGcN`,
	"cookie_btn": `cookie_policy_cookie_btn__nldiR`,
	"btn_bordered": `cookie_policy_btn_bordered__i9LVG`,
	"btn_solid": `cookie_policy_btn_solid__kODQm`
};
export default ___CSS_LOADER_EXPORT___;
