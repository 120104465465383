import React, { useState } from 'react'
import styles from './roundrobinribbon.module.scss'
import { Formik, Field, Form } from 'formik';
import FileUpload from '../../../../FileUpload/FileUpload';
import { APIConstant } from '../../../../../services/APIConstant';

const AddContentRRR = ({templateId, showMode, activeItem}) => {
    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
        name: '',
        country : '',
    });
    const [imgPath1, setImagePath1] = useState()
    console.log(templateId)

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;

    // console.log(showMode, activeItem)
        if(showMode === 'update'){
            fetch(APIConstant.getContentRRRWithId, {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id : activeItem})
            })
            .then(res => res.json())
            .then((data) => {
                console.log('Data :', data)
                setInitialValues({
                    title: data[0].title,
                    description: data[0].description,
                    img_path: data[0].img_path,
                    name: data[0].username,
                    country : data[0].country,
                    id : data[0].id
                })
            })
            .catch(err => console.log(err))
        }

  return (
    <div className={styles.imageCont}>
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                
                values.templateId = templateId
                values.type = 'content'
                // console.log('ASD', values)

                
                values.date = formattedToday
                if(showMode === 'add'){
                    values.imgPath = imgPath1
                    fetch(APIConstant.addContentRRR, {
                        method: 'POST',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    })
                    .then(res => res.json())
                    .catch(err => console.log(err))
                }else{
                    if(imgPath1){
                        values.imgPath = imgPath1
                    }else{
                        values.imgPath = initialValues.img_path
                    }
                    fetch(APIConstant.updateContentRRR, {
                        method: 'PUT',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    })
                    .then(res => res.json())
                    .catch(err => console.log(err))
                }

            }}
            enableReinitialize
        >
            <Form>
                <div className={styles.formContainer}>
                    {showMode === 'add'
                        ? <h5>Add</h5>
                        : <h5>Update</h5>
                    }
                    {/* <h5>Add Text RRR</h5> */}
                    <div className="fieldContainer">
                        <label htmlFor="title">Content Title</label>
                        <Field id="title" name="title"/>
                    </div>
                    <div className="fieldContainer">
                        <label htmlFor="description">Description</label>
                        <Field id="description" name="description"/>
                    </div>
                    <div className="fieldContainer">
                        <label htmlFor="name">Customer Name</label>
                        <Field id="name" name="name"/>
                    </div>
                    <div className="fieldContainer">
                        <label htmlFor="country">Country</label>
                        <Field id="country" name="country"/>
                    </div>
                    <div className="fieldContainer" style={{width: '500px'}}>
                        <FileUpload setImagePath={setImagePath1} />
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    {showMode === 'add'
                        ? <button type="submit">Submit</button>
                        : <button type="submit">Update</button>
                    }
                </div>
            </Form>
        </Formik>
    </div>
  )
}

export default AddContentRRR