// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy_policyPage__VGhZK {
  width: 100%;
  margin: 0px auto;
}
.policy_policyPage__VGhZK .policy_heading__NDvZn {
  background-color: #F8F8F8;
  text-align: center;
  padding: 50px 0px;
}
.policy_policyPage__VGhZK .policy_heading__NDvZn h4 {
  font-size: 34px;
  color: rgb(57, 131, 120);
}

.policy_contentWrapper__lex-m {
  padding: 30px 0px;
  width: 1400px;
  max-width: 96%;
  margin: 0px auto;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .policy_mobileHeader__ROWwq {
    height: 130px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/policy/policy.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;AACJ;AACI;EACI,yBAAA;EACA,kBAAA;EACA,iBAAA;AACR;AAAQ;EACI,eAAA;EACA,wBAAA;AAEZ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;AACJ;;AAEA;EACI;IACI,wBAAA;EACN;AACF","sourcesContent":[".policyPage{\n    width: 100%;\n    margin: 0px auto;\n\n    .heading{\n        background-color: #F8F8F8;\n        text-align: center;\n        padding: 50px 0px;\n        h4{\n            font-size: 34px;\n            color: rgb(57, 131, 120);\n        }\n    }\n}\n.contentWrapper{\n    padding: 30px 0px;\n    width: 1400px;\n    max-width: 96%;\n    margin: 0px auto;\n    display: flex;\n}\n\n@media only screen and (max-width: 600px) {\n    .mobileHeader{\n        height: 130px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyPage": `policy_policyPage__VGhZK`,
	"heading": `policy_heading__NDvZn`,
	"contentWrapper": `policy_contentWrapper__lex-m`,
	"mobileHeader": `policy_mobileHeader__ROWwq`
};
export default ___CSS_LOADER_EXPORT___;
