import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: 0
}

const activePagesSlice = createSlice({
    name: 'activepage',
    initialState: initialState,
    reducers: {
        getActivePageId(state, action){
            state.id = action.payload
        }
    }
})

export const { getActivePageId } = activePagesSlice.actions;
export default activePagesSlice.reducer