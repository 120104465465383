import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './platforms.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdatePlatformsTitle = ({templateId}) => {
  const [initialValue, setInitialValue] = useState({});
  const [mode, setMode] = useState('');
  useEffect(() => {
    fetch(APIConstant.getPlatformsTitle, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateID : templateId})
    })
        .then(res => res.json())
        .then((data) => {
          if(data.length > 0){
            setInitialValue({
              title: data[0].title,
              subtitle: data[0].subtitle,
              description: data[0].description,
              status: data[0].status
            })
            setMode('update')
          }else{
            setInitialValue({
              title: '',
              subtitle: '',
              description: '',
              status: ''
            })
            setMode('add')
          }
    })
	.catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.updatePayStack}>
      
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.templateId = templateId
                    if(mode === 'add'){
                      console.log('Add Value :', values)
                      axios.post(APIConstant.addPlatformsTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }
                    if(mode === 'update'){
                      console.log('Update Value :', values)
                      axios.put(APIConstant.updatePlatformsTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }
                    
                  }}
                enableReinitialize
                >
                <Form>
                  <header className={styles.formHeader}>
                    <h5>Edit Heading Text</h5>
                    <div className={styles.btnContainer}>
                      {/* <button className={styles.discard} variant="contained" type='submit'>Discard</button> */}
                      {initialValue.title
                        ? <button className={styles.primary} variant="contained" type='submit'>Update</button>
                        : <button className={styles.primary} variant="contained" type='submit'>Add</button>
                      }
                        
                    </div>
                  </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="subtitle"> Sub Title</label>
                            <Field id="subtitle" name="subtitle" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            <Field id="description" name="description"  />
                        </div>
                        
                    </div>
                
                </Form>
            </Formik>


    </div>
  )
}

export default UpdatePlatformsTitle