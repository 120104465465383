

import React, { useEffect, useState } from 'react'
import styles from './platforms.module.scss'
import { Formik, Field, Form } from 'formik';
import { APIConstant } from '../../../../../services/APIConstant';

const AddSubSectionList = ({templateId, showMode, activeItem, closeDialogAndRefresh}) => {
    const [baseImage, setBaseImage] = useState("");
    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
      });

    const uploadImage = async (e) => {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      setBaseImage(base64);
    };
  
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    useEffect(() => {
        fetch(APIConstant.getPlatformsListWithId, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id : activeItem})
        })
        .then(res => res.json())
        .then((data) => {
            console.log('Data :', data)
            setInitialValues({
                title: data[0].title,
                description: data[0].description,
                image_path: data[0].image_path
            })
        })
        .catch(err => console.log(err))
    }, [])
  return (
    <div className={styles.addSectionCont}>
        <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        
        values.templateId = templateId
        
        if(showMode === 'add'){
            values.imgCode = baseImage
            fetch(APIConstant.addPlatformsList, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(res => res.json())
            .catch(err => console.log(err))
        }else{
            values.id = activeItem
            if(baseImage){
                values.imgCode = baseImage
            }else{
                values.imgCode = initialValues.image_path
            }
            
            fetch(APIConstant.updatePlatformsListWithId, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(res => res.json())
            .catch(err => console.log(err))
        }
        resetForm();
        closeDialogAndRefresh()
      }}
      enableReinitialize
    >
      <Form>
        {showMode === 'add'
            ? <h4>Add</h4>
            : <h4>Update</h4>
        }
        
        <label htmlFor="title">Title</label>
        <Field id="title" name="title"  />

        <label htmlFor="description">Description</label>
        <Field id="description" name="description" />

        <label htmlFor="icon">Upload Icon</label>
        <div className={styles.imgUpload}>
            <Field id="icon" name="icon" type="file" onChange={(e) => { uploadImage(e);}}/>

            <img src={baseImage} height="30" alt="Icon" />
        </div>

        <button type="submit">Submit</button>
      </Form>
    </Formik>
    </div>
  )
}

export default AddSubSectionList