import React from 'react'
import styles from './nocode.module.scss'
import PaymentList from './PaymentList'

const PaymentSections = ({templateId}) => {
  return (
    <div className={styles.paymentSections}>
        <div className={styles.secGrid}>
            
            <div className={styles.sectionList}>
                <PaymentList templateId={templateId}/>
            </div>
        </div>
    </div>
  )
}

export default PaymentSections