import React, { useEffect, useState } from 'react'
import styles from './manageand_optimize.module.scss'
import Images from '../../../assets/images/images'
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const ManageandOptimize = ({template}) => {
    const [showSection, setShowSection] = useState(0);
    const [pageTitleData, setPageTitleData] = useState([]);
    const [subSectionData, setSubSectionData] = useState([]);
    useEffect(() => {
        axios.post(APIConstant.getPlatformsTitle, {templateID: template})
        .then(res => {
            if(res.data.length > 0){
                setPageTitleData(res.data[0]);
            }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.post(APIConstant.getPlatformsList, {templateId: template})
        .then(res => {
            setSubSectionData(res.data);
        })
        .catch(err => console.log(err))
    }, [])
  return (
    <div className={styles.manage_optimize}>
        <div className='container'>
            <h2 data-aos="fade-up">{pageTitleData.title}</h2>
            <div className={styles.content_box}>
                <div className={styles.img_box}>

                </div>
                <div className={styles.txt_cont}>
                    <div className={styles.dark_bg_col}>
                        <h3 data-aos="fade-up">{pageTitleData.subtitle}</h3>
                        <p data-aos="fade-up">{pageTitleData.description}</p>
                        {/* <ul>
                            {subSectionData.map((item, index) => {
                                return (
                                    <li>
                                        <a onClick={() => setShowSection(index)}>
                                            <span></span>
                                            <strong>{item.title}</strong>
                                            <i><img src={Images.RIGHT_ARROW} alt="arrow" width="24" /></i>
                                        </a>
                                    </li>
                                )}
                            )}
                        </ul> */}
                    </div>
                    {/* {console.log('L',subSectionData, showSection)} */}
                    <div className={styles.light_bg_col}>
                        {subSectionData.map((item, index) => {
                            return (
                                <div className={styles.txtCont}>
                                    <header>
                                        <div className={styles.iconCont}>
                                            <img src={item.image_path} width="50" />
                                        </div>
                                        <div className={styles.headerTxt}>
                                            <h4>{item?.title}</h4>
                                            <p>{item?.description}</p>
                                        </div>
                                    </header>
                                </div>
                            )}
                        )}
                        {/* {showSection === 'payments' && (
                            <div className={styles.txtCont}>
                                <header>
                                    <div className={styles.iconCont}></div>
                                    <div className={styles.headerTxt}>
                                        <h4>Payments</h4>
                                        <p>Accept online payments through customizable tools.</p>
                                    </div>
                                </header>
                                <ul>
                                    <li>
                                        <span>
                                        </span>
                                        <p>Token Voult</p>
                                    </li>
                                    <li>
                                        <span>
                                        </span>
                                        <p>Dynamic Checkout Pages</p>
                                    </li>
                                    <li>
                                        <span>
                                        </span>
                                        <p>Routing</p>
                                    </li>
                                    <li>
                                        <span>
                                        </span>
                                        <p>Retry</p>
                                    </li>
                                </ul>
                            </div>
                        )} */}
                        {/* {showSection === 'automations' && (
                        <div className={styles.txtCont}>
                            <header>
                                <div className={styles.iconCont}></div>
                                <div className={styles.headerTxt}>
                                    <h4>Automations</h4>
                                    <p>Automate tedious manual processes like balance tracing and reconciliation.</p>
                                </div>
                            </header>
                            <ul>
                                <li>
                                    <span>
                                    </span>
                                    <p>Reconciliation</p>
                                </li>
                                <li>
                                    <span>
                                    </span>
                                    <p>Ledgers</p>
                                </li>
                                <li>
                                    <span>
                                    </span>
                                    <p>Analytics</p>
                                </li>
                            </ul>
                        </div>
                        )} */}
                        {/* {showSection === 'payouts' && (
                        <div className={styles.txtCont}>
                            <header>
                                <div className={styles.iconCont}></div>
                                <div className={styles.headerTxt}>
                                    <h4>Payouts</h4>
                                    <p>Unify your payout infrastructure with scalability and flexibility at its core.</p>
                                </div>
                            </header>
                            <ul>
                                <li>
                                    <span>
                                    </span>
                                    <p>Multi-currency accounts</p>
                                </li>
                                <li>
                                    <span>
                                    </span>
                                    <p>Real-Time and Batch Payouts</p>
                                </li>
                                <li>
                                    <span>
                                    </span>
                                    <p>Routing and FX optimization</p>
                                </li>
                                <li>
                                    <span>
                                    </span>
                                    <p>Global Network</p>
                                </li>
                            </ul>
                        </div>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default ManageandOptimize