import React, { useEffect, useState } from 'react'
import { APIConstant } from '../../services/APIConstant';
import { NavLink } from 'react-router-dom';

const PolicyList = () => {
    const [pages, setPages] = useState([]);
    const [policyPages, setPolicyPages] = useState([]);

    useEffect(() => {
    fetch(APIConstant.getPageList)
        .then(res => res.json())
        .then((data) => {
            setPages(data)
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setPolicyPages(pages?.filter(item => item.page_category === 'policy' && item.status === 'published'))
    }, [pages])

  return (
    <div>
        {console.log('Policy',policyPages)}
        {policyPages.map((item,i) => {
            return(
                // <li key={i}><NavLink target='_blank' to={`/policy?id=${item.page_id}`}>{item.page_name}</NavLink></li>
                <li key={i}><NavLink target='_blank' to={`/${item.page_url}`}>{item.page_name}</NavLink></li>
            )}
        )}
    </div>
  )
}

export default PolicyList