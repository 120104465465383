import React, { useState } from 'react'
import styles from './processor.module.scss';
import { Formik, Field, Form } from 'formik';
import { APIConstant } from '../../../../../services/APIConstant';
import FileUpload from '../../../../FileUpload/FileUpload';

const AddOutputProcessor = ({templateId}) => {
    const [imgPath1, setImagePath1] = useState()
  return (
    <div className={styles.imageCont}>
        <Formik
            initialValues={{
                title: '',
            }}
            onSubmit={async (values) => {
                values.imgPath = imgPath1
                values.templateId = templateId
                fetch(APIConstant.addProcessorOutputData, {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                .then(res => res.json())
                .catch(err => console.log(err))

            }}
        >
            <Form>
                <div className={styles.formContainer}>
                    <h5>Add Output Data</h5>
                    <div className="fieldContainer">
                        <label htmlFor="title">Image Title</label>
                        <Field id="title" name="title"/>
                    </div>
                    <div className="fieldContainer" style={{width: '500px'}}>
                        <FileUpload setImagePath={setImagePath1} />
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <button type="submit">Submit</button>
                </div>
            </Form>
        </Formik>
    </div>
  )
}

export default AddOutputProcessor