import React, { useState } from 'react'
import styles from './previewPlatform.module.scss';
import { Dialog, Switch } from '@mui/material';
import ManageandOptimize from '../../home/manageandoptimize/manageand_optimize';
import UpdatePlatforms from '../../admin/home/sections/platforms/UpdatePlatforms';
import Images from '../../../assets/images/images';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import DeleteConfirmationDialog from '../../dialog/deleteConfirmationDialog/deleteConfirmationDialog';
import { TextConstant } from '../../../constant/common';
import DeleteToaster from '../../toaster/delete/deleteToaster';

const PreviewPlatform = ({templateId}) => {
  const [checked, setChecked] = useState();
  const [viewMode, setViewMode] = useState('preview');
  const [openDeleteToaster, setOpenDeleteToaster] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);  
  const [getPreviewBtnText, setGetPreviewBtnText] = useState('Discard');  

  const handleOpenDeleteConform = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseDeleteConform = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if (checked) {
      visbility = 'hide'
    }
    else {
      visbility = 'active'
    }
  };
  const deleteTemplate = () => {
    axios.post(APIConstant.deleteTemplate, {templateId : templateId})
    .then(res => {
      setOpenDeleteToaster(true)
    })
    .catch(err => console.log(err))
  }
  const confirmValue = (res) => {
    if(res){
      deleteTemplate()
      handleCloseDeleteConform()
    }else{
      handleCloseDeleteConform()
    }
  }

  const getPreviewText = (btnLabel) => {
    console.log(btnLabel)
   if(btnLabel === `1`){
    setGetPreviewBtnText('Discard')
    }else{
      setGetPreviewBtnText('Preview')
    }
    
  }
  return (
    <div className={`${styles.platformPreviewCont} ${styles.previewContainer}`}>
      <header>
      <h5>Platform</h5>
        <ul className={styles.sectionBtns}>
            {/* <li>
            <div className="customeSwitch">
                <Switch
                checked={checked}
                onChange={handleVisibilityChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />
                <span className={checked ? 'active switchlabel' : 'hide switchlabel'}>{checked ? 'Active' : 'Hide'}</span>
            </div>
            </li> */}
            {viewMode === 'preview' &&
            <>
              <li>
                <button className={styles.delete} onClick={handleOpenDeleteConform}>
                  <img src={Images.DELETE} width="20" title="Delete" alt="delete" />
                </button>
              </li>
              <li>
                    <button className={styles.edit} onClick={() => setViewMode('edit')}>Edit</button>
                </li>
            </>
                
            }
            {viewMode === 'edit' &&
                <>
                    <li>
                    <button className={styles.discard} onClick={() => setViewMode('preview')}>{getPreviewBtnText}</button>
                    </li>
                    {/* <li>
                    <button className={styles.primary} onClick={() => setViewMode('preview')}>Save</button>
                    </li> */}
                    
                </>
            }
        </ul>
        </header>
        {viewMode === 'preview' &&
            <ManageandOptimize  template={templateId} />
        }
        {viewMode === 'edit' &&
            <UpdatePlatforms  template={templateId} getChildTabSelected={getPreviewText}/>
        }

        {/* Dialog */}
        <Dialog open={openConfirmDeleteDialog} onClose={handleCloseDeleteConform}>
          <DeleteConfirmationDialog message={{title:TextConstant.AreYouSure, description: TextConstant.DeleteConfirmMessage}} getConfirmation={confirmValue}/>
        </Dialog>

        {/* Toaster */}
        {openDeleteToaster &&
          <DeleteToaster/>
        }
    </div>
  )
}

export default PreviewPlatform