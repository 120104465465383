 const images = {
    LOGO: require('./PayOrc.svg').default,
    LOGO_LIGHT_BG: require('./PayOrc-lightbg.svg').default,
    RISKRULEENGINE: require('./risk_rule_engine.svg').default,
    RIGHT_ARROW: require('./icons/right-arrow.svg').default,
    CHECK: require('./icons/check.svg').default,
    APIS: require('./icons/apis.svg').default,
    MOBILE_SDK: require('./icons/mobilesdks.svg').default,

    WOO: require('./icons/woo.svg').default,
    WORDPRESS: require('./icons/wordpress.svg').default,
    OPENCART: require('./icons/opencart.svg').default,
    PRESTS: require('./icons/presta.svg').default,
    CSCART: require('./icons/cscart.svg').default,
    MAGENTO: require('./icons/magento.svg').default,
    ECWID: require('./icons/ecwid.svg').default,
    DRUPAL: require('./icons/drupal.svg').default,
    CODE: require('./icons/code.svg').default,
    PAGE: require('./icons/page.svg').default,
    CLOSE: require('./icons/close.svg').default,
    EDIT: require('./icons/edit.svg').default,
    DELETE: require('./icons/delete.svg').default,
    ADD: require('./icons/add.svg').default,
    UPDATE: require('./icons/update.svg').default,
    LIRT: require('./icons/banner-alignment-01.svg').default,
    LTRI: require('./icons/banner-alignment-02.svg').default,
    ARROWRIGHT: require('./icons/arrowRight.svg').default,
    DEVELOPER: require('./icons/developer.svg').default,
    
    CLOSEWHITE: require('./icons/close_white.svg').default,
    HOME: require('./icons/home.svg').default,
    HOMEACTIVE: require('./icons/home_active.svg').default,
    USER: require('./icons/user.svg').default,
    IMAGE_FILE: require('./icons/image.svg').default,
    COPYRIGHT: require('./icons/copyright.svg').default,
    LOGIN: require('./icons/login.svg').default,

    LINKEDIN: require('./icons/linkedin.svg').default,

    BANNER001: require('./banner001.svg').default,
    BANNER002: require('./banner002.webp'),
    BANNER003: require('./banner003.webp'),

    BANNER: require('./components/banner.png'),
    PLATFORM: require('./components/integration.png'),
    JOURNEY: require('./components/journey.png'),
    PORTFOLIO: require('./components/portfolio.png'),
    OPTIMIZE: require('./components/optimize.png'),
    INTEGRATION: require('./components/integration.png'),
    PRICING: require('./components/pricing.png'),
    FOOTER: require('./components/footer.png'),
    LOADER: require('./loader.gif'),

    LAPTOP: require('./laptop-dashboard.png'),

    UAE: require('./flag/1UAE.png'),
    KSA: require('./flag/2KSA.png'),
    BAHRAIN: require('./flag/3Bahrain.png'),
    INDIA: require('./flag/4India.png'),
    JORDAN: require('./flag/5Jordan.png')
}

export default images;