import React, { useEffect, useState } from 'react'
import styles from './paymentstack.module.scss'
import FileUpload from '../../../../FileUpload/FileUpload';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const AddSectionList = ({ mode, selected, list, template }) => {
  const [initialValue, setInitialValue] = useState({})
  const [imgPath1, setImagePath1] = useState()

  useEffect(() => {
    if(mode === 'Update'){
      axios.post(APIConstant.getPortfolioSelectedItem, { listId : list })
      .then(res => {
          setInitialValue({
            title: res.data[0].title,
            description: res.data[0].description,
            id: res.data[0].id
          })
          setImagePath1(res.data[0].img_path)
      })
      .catch(err => console.log(err))
    }
    if(mode === 'Add'){
      setInitialValue({
        title: '',
        description: '',
      })
    }
    
  }, [mode])

  return (
    
<div className={styles.addIntToolContainer}>
<h4>{mode} Item</h4>
    <Formik
          
    initialValues={ initialValue }
    onSubmit={async (values) => {
        // values.imgPath = imgPath1
          if(imgPath1 !== undefined){
            values.imgpath = imgPath1
          }
          if(mode === 'Add'){
            values.sectionId = selected
            values.templateId = template
            console.log(values)
            axios.post(APIConstant.createPortfolioList, values)
            .then(res => {
            //   handleClick()
              
            })
            .catch(err => console.log(err))
          }
          if(mode === 'Update'){
            
            axios.put(APIConstant.updatePortfolioList, values)
            .then(res => {
              console.log('done')
            })
            .catch(err => console.log(err))
          }
    }}
    enableReinitialize
    >
      {({ values }) => (
        <Form>
            <div className={styles.formContainer}>
                <div className={styles.fieldContainer}>
                    <label htmlFor="title">Title</label>
                    <Field id="title" name="title" />
                </div>
                <div className={styles.fieldContainer}>
                    <label htmlFor="description">Description</label>
                    <Field id="description" name="description" />
                </div>
                
            </div>
            <div className={styles.uploadImageWrapper}>
            <h4>Banner Image</h4>
            <div className={styles.bannerImages}>
                <div className={styles.bannerUploadSec}>
                    <FileUpload setImagePath={setImagePath1} />
                    
                </div>
            </div>
            </div>
            
            <div className={styles.btnContainer}>
                <button variant="contained" type='submit'>Submit</button>
            </div>
        </Form>
    )}
</Formik>
</div>

  )
}

export default AddSectionList