import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import styles from './texteditor.module.scss'
import { Field, Form, Formik } from 'formik';
import { APIConstant } from '../../services/APIConstant';
import axios from 'axios';

const CreateNewTextEditor = (props) => {
  const editor = useRef(null);
const [content, setContent] = useState('');
 
  useEffect(() => {

  }, [props])
  
  return (
    <div>

<Formik
      initialValues={{
        legal_name: '',
        status: 'active',
        visibility: 'active'
      }}
      onSubmit={async (values) => {
        values['page_content'] = content
        let removedSpacesText = values.legal_name.split(" ").join("").toLowerCase();
        values['permalink'] = '/legal/'+removedSpacesText
        
        
        axios.post(APIConstant.createLegalData, values)
        .then(res => {
          
        })
        .catch(err => console.log(err))
      }}
    >
      <Form>
        <div className={styles.createNewPage}>
            <h3>Create New Page</h3>
            <div className={styles.formCont}>
              <div className={styles.pageTitle}>
                <label>Page Title</label>
                <Field type="text" id="legal_name" name="legal_name" />
              </div>
            </div>
            <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            />
            <div className={styles.btnCont}>
                <button  className={styles.btnBordered}>Cancel</button>
                <button type='submit' className={styles.btnPrimary}>Submit</button>
            </div>
            </div>
      </Form>
    </Formik>
    </div>
  )
}

export default CreateNewTextEditor