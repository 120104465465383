import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './integrationmadeeasy.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdateIntegrationEasyTitle = ({templateId}) => {
  
  const [initialValue, setInitialValue] = useState();
  const [mode, setMode] = useState();
  useEffect(() => {
    fetch(APIConstant.getIntegrationMadeEasyTitle, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ templateId : templateId})
        }
      )
      .then(res => res.json())
      .then((data) => {
        if(data.length > 0){
          setMode('update')
          setInitialValue({
            title: data[0].title,
            description: data[0].description,
            status: data[0].status,
            visibility: data[0].visibility
          })
        }else{
          setMode('add')
          setInitialValue({
            title: '',
            description: '',
            status: '',
            visibility: ''
          })
        }
      
    })
	.catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.updatePayStack}>
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.templateId = templateId
                    if(mode === 'update'){
                      axios.put(APIConstant.updateIntegrationMadeEasyTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }else{
                      axios.post(APIConstant.addIntegrationMadeEasyTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    } 
                  }}
                enableReinitialize
                >
                <Form>
                    <header className={styles.formHeader}>
                      <h5>Edit Heading Text</h5>
                      <div className={styles.btnContainer}>
                        {mode === 'add'
                            ? <button className={styles.primary} variant="contained" type='submit'>Add</button>
                            : <button className={styles.primary} variant="contained" type='submit'>Update</button>
                        }
                      </div>
                    </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            <Field id="description" name="description"  />
                        </div>
                    </div>
                {/* <div className={styles.btnContainer}>
                  {mode === 'add'
                      ? <button variant="contained" type='submit'>Add</button>
                      : <button variant="contained" type='submit'>Update</button>
                  }
                </div> */}
                </Form>
            </Formik>


    </div>
  )
}

export default UpdateIntegrationEasyTitle