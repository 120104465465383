// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_homeContainer__tMTcL {
  display: flex;
  flex-direction: column;
}
.home_homeContainer__tMTcL .home_menu__yz-hg {
  width: 100%;
  margin-bottom: 10px;
}
.home_homeContainer__tMTcL .home_menu__yz-hg ul {
  display: flex;
  gap: 10px;
}
.home_homeContainer__tMTcL .home_menu__yz-hg ul li a {
  display: flex;
  width: 150px;
  padding: 15px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
  text-decoration: none;
  background-color: #FFFFFF;
}
.home_homeContainer__tMTcL .home_menu__yz-hg ul li a.home_active__480ll {
  color: #398378;
  font-weight: 500;
  background-color: rgba(57, 131, 120, 0.1);
}
.home_homeContainer__tMTcL .home_wrapper__HLpSz {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.home_homeWrapper__xbRFK h2 {
  margin-bottom: 20px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/home/home.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,WAAA;EACA,mBAAA;AAER;AADQ;EACI,aAAA;EACA,SAAA;AAGZ;AAAgB;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,qBAAA;EACA,yBAAA;AAEpB;AAAgB;EACI,cAAA;EACA,gBAAA;EACA,yCAAA;AAEpB;AAGI;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,4CAAA;AADR;;AAKI;EACI,mBAAA;EACA,eAAA;AAFR","sourcesContent":[".homeContainer{\n    display: flex;\n    flex-direction: column;\n    .menu{\n        width: 100%;\n        margin-bottom: 10px;\n        ul{\n            display: flex;\n            gap: 10px;\n            li{\n                \n                a{\n                    display: flex;\n                    width: 150px;\n                    padding: 15px 20px;\n                    color: rgba(0,0,0,0.6);\n                    font-weight: 300;\n                    text-decoration: none;\n                    background-color: #FFFFFF;\n                }\n                a.active{\n                    color: #398378;\n                    font-weight: 500;\n                    background-color: rgba(#398378, 0.1);\n                }\n            }\n        }\n    }\n    .wrapper{\n        background-color: #FFFFFF;\n        border-radius: 10px;\n        padding: 20px;\n        width: 100%;\n        box-shadow: 0px 0px 20px rgba(0,0,0,0.05);\n    }\n}\n.homeWrapper{\n    h2{\n        margin-bottom: 20px;\n        font-size: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeContainer": `home_homeContainer__tMTcL`,
	"menu": `home_menu__yz-hg`,
	"active": `home_active__480ll`,
	"wrapper": `home_wrapper__HLpSz`,
	"homeWrapper": `home_homeWrapper__xbRFK`
};
export default ___CSS_LOADER_EXPORT___;
