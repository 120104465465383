export const Endpoint = {
    getHomeBanners : '/homebanners',
    getSectionVisibility : '/getsectionvisibility',
    addHomeBanners : '/addbanner',
    updateVisibility : '/updatevisibility',
    updatePortfolioTitle : '/updateportfoliotitle',
    addPortfolioTitle : '/addPortfolioTitle',
    getPortfolioTitle : '/getportfoliotitle',
    getPortfolioSections : '/getportfoliosections',
    getPortfolioSecionDetail : '/getportfolioseciondetail',
    getSelectedPortfolioTitle : '/getselectedportfoliotitle',
    getLegalList : '/getlegallist',
    getSelectedLegalData : '/getselectedlegaldata',
    updateLegalData : '/updatelegaldata',
    createLegalData : '/createlegaldata',
    getLandingBanners : '/gethomebanners',
    updateHomeBanner : '/updatehomebanner',
    deleteHomeBanner : '/deleteHomeBanner',
    getSelectedHomeBanner : '/getselectedhomebanner',
    updateOrganization : '/updateorganization',
    getOrganization : '/getorganization',
    createPortfolioSections : '/createportfoliosections',
    updatePortfolioSections : '/updateportfoliosections',
    createPortfolioList : '/createportfoliolist',
    updatePortfolioList : '/updateportfoliolist',
    getPortfolioSelectedItem : '/getportfolioselecteditem',
    deletePortfolioItem : '/deleteportfolioitem',
    getPricingTitle : '/getpricingtitle',
    updatePricingTitle : '/updatepricingtitle',
    addPricingPlan : '/addpricingplan',
    getPricingPlan : '/getpricingplan',
    deletePricingPlan : '/deletepricingplan',
    getPricingFeatures : '/getpricingfeatures',
    addPricingFeature : '/addpricingfeature',
    addPricingPlanData : '/addpricingplandata',
    getPricingPlanData : '/getpricingplandata',
    updateFeatureListData : '/updatefeaturelistdata',
    updatePricingPlanData : '/updatepricingplandata',
    updateNoCodeTitle : '/updatenocodetitle',
    getNoCodeTitle : '/getnocodetitle',
    getIntegrationMadeEasyTitle : '/getintegrationmadeeasytitle',
    updateIntegrationMadeEasyTitle : '/updateintegrationmadeeasytitle',
    deleteFeature : '/deletefeature',
    addPricingPlanValues : '/addpricingplanvalues',
    getPlatformsTitle : '/getplatformstitle',
    updatePlatformsTitle : '/updateplatformstitle',
    updateJourneyTitle : '/updatejourneytitle',
    getJourneyTitle : '/getjourneytitle',
    getPricingPlanDetails : '/getpricingplandetails',
    imageUpload : '/imageupload',
    createPage : '/createpage',
    getPageList : '/getpagelist',
    getSelectedPage : '/getselectedpage',
    publishPage : '/publishpage',
    getTemplates : '/getTemplates',
    createPageTemplate : '/createPageTemplate',
    reorderPageTemplates : '/reorderPageTemplates',
    getPageTemplatesWithID : '/getPageTemplatesWithID',
    getLandingPage : '/getLandingPage',
    draftPage : '/draftPage',
    getDraftPageWithGroupId : '/getDraftPageWithGroupId',
    deletePage : '/deletePage',
    getHomeBannersWithId : '/getHomeBannersWithId',
    getLandingPageContent : '/getLandingPageContent',
    getNoCodeIntegrationTitle : '/getNoCodeIntegrationTitle',
    addNoCodeIntegrationTitle : '/addNoCodeIntegrationTitle',
    getNoCodeIntegrationList : '/getNoCodeIntegrationList',
    addNoCodeIntegrationList : '/addNoCodeIntegrationList',
    getNoCodeIntegrationListWithId : '/getNoCodeIntegrationListWithId',
    updateNoCodeIntegrationListWithId : '/updateNoCodeIntegrationListWithId',
    deleteNoCodeIntegrationWithId : '/deleteNoCodeIntegrationWithId',
    addPlatformsTitle : '/addPlatformsTitle',
    addPlatformsList : '/addPlatformsList',
    updatePlatformsListWithId : '/updatePlatformsListWithId',
    getPlatformsListWithId : '/getPlatformsListWithId',
    getPlatformsList : '/getPlatformsList',
    deletePlatformsWithId : '/deletePlatformsWithId',
    addJourneyTitle : '/addJourneyTitle',
    addJourneySection : '/addJourneySection',
    updateJourneySection : '/updateJourneySection',
    getJourneySection : '/getJourneySection',
    deleteTemplate : '/deleteTemplate',
    addLcri : '/addLcri',
    getLcri : '/getLcri',
    updateLcri : '/updateLcri',
    addLirc : '/addLirc',
    getLirc : '/getLirc',
    updateLirc : '/updateLirc',
    getLircTwoColTitle : '/getLircTwoColTitle',
    addLircTwoColTitle : '/addLircTwoColTitle',
    updateLircTwoColtitle : '/updateLircTwoColtitle',
    addLircTwoColList : '/addLircTwoColList',
    updateLircTwoColListWithId : '/updateLircTwoColListWithId',
    getLircTwoColListWithId : '/getLircTwoColListWithId',
    getLircTwoColList : '/getLircTwoColList',
    addImageRRR : '/addImageRRR',
    getImageRRR : '/getImageRRR',
    deleteImageRRRWithId : '/deleteImageRRRWithId',
    addContentRRR : '/addContentRRR',
    getContentRRR : '/getContentRRR',
    updateContentRRR : '/updateContentRRR',
    getContentRRRWithId : '/getContentRRRWithId',
    deleteContentRRRWithId : '/deleteContentRRRWithId',
    addProcessorInputData : '/addProcessorInputData',
    addProcessorOutputData : '/addProcessorOutputData',
    getProcessorInput : '/getProcessorInput',
    getProcessorOutput : '/getProcessorOutput',
    deleteProcessorInputWithId : '/deleteProcessorInputWithId',
    deleteProcessorOutputWithId : '/deleteProcessorOutputWithId',
    addPricingTitle : '/addPricingTitle',
    getpricingfeaturesList : '/getpricingfeaturesList',
    getPricingPlanDetailsWithId : '/getPricingPlanDetailsWithId',
    getCommonSubSectionIntegrationEasy : '/getCommonSubSectionIntegrationEasy',
    addCommonSubSectionIntegrationEasy : '/addCommonSubSectionIntegrationEasy',
    updateCommonSubSectionIntegrationEasy : '/updateCommonSubSectionIntegrationEasy',
    addIntegrationMadeEasyTitle : '/addIntegrationMadeEasyTitle',
    deleteIntegrationEasySectionWithId : '/deleteIntegrationEasySectionWithId',
    createTextEditorData : '/createTextEditorData',
    getTexteditorData : '/getTexteditorData',
    getSelectedTemplate : '/getSelectedTemplate',
    updateTextEditorData : '/updateTextEditorData',
    getPortfolioSectionsAndData : '/getPortfolioSectionsAndData',
    sequenceHomeBanner : '/sequenceHomeBanner',
    loginCms : '/loginCms',
    getPublishedPageList : '/getPublishedPageList',
    getSelectedSEO : '/getSelectedSEO',
    sendContactusFormData : '/sendContactusFormData'
}