import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import images from '../../../../../assets/images/images';
import styles from './paymentstack.module.scss'

import BannerImg from '../../../../../assets/images/homeBanner001.webp'
import { APIConstant } from '../../../../../services/APIConstant';
import axios from 'axios';
import { Dialog } from '@mui/material';
import AddSectionList from './addSectionList';

const SectionList = (props) => {
    const [sectionDataList, setSectionDataList] = useState([]);
    const [openSectionDialog, setOpenSectionDialog] = useState(false);
    const [modeType, setModeType] = useState();
    const [list, setList] = useState()

    const handleOpenSectionDialog = (mode, id) => {
        setOpenSectionDialog(true);
        setModeType(mode)
        setList(id)
    };

    const handleCloseSectionDialog = () => {
        setOpenSectionDialog(false);
    };

    const handleDeletePortfolioItem = (id) => {
        axios.post(APIConstant.deletePortfolioItem, { listId : id })
        .then(res => {    
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
            axios.post(APIConstant.getPortfolioSecionDetail, { section_id: props.setSectionId, template_id: props.template })
            .then(res => {
                setSectionDataList(res.data)
            })
            .catch(err => console.log(err))
            

     }, [props.setSectionId])

     useEffect(() => {
       
        // axios.get(APIConstant.getPortfolioSecionDetail)
        // .then(res => {
        //     setSectionDataList(res.data)
        // })
        // .catch(err => console.log(err))
        

    }, [])
    const columns = [
        {
            headerName: 'Image',
            field: 'image',
            width: 120,
            renderCell: (params) => (
                <div className={styles.imgCont}>
                    <img src={params.row.img_path}  alt="Banner" />
                </div>
            )
        },
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'description', headerName: 'Description', flex: 1 },
        
        {
            field: 'actions',
            headerName: '',
            renderCell: (params) => (
                <div className={styles.actionBtns}>
                    <ul>
                        <li><a className={styles.edit} onClick={() => handleOpenSectionDialog('Update', params.row.id)}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                        <li><a className={styles.delete}  onClick={() => handleDeletePortfolioItem( params.row.id)}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                    </ul>
                </div>
            )
        },
    ];

    // const rows = [
    //     { id: 1, title: 'One Integration', description: 'Single Application andLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore', imgurl: 35 },
    //     { id: 2, title: 'Payments Made Powerful', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore', imgurl: 35 }
    // ];

    return (
        <div className={styles.bannerList}>
            <div style={{ height: 'auto', width: '100%' }} className="customDataGrid">
                {/* {props.template}{props.setSectionId} */}
                <DataGrid
                    rows={sectionDataList}
                    columns={columns}
                />

                <Dialog open={openSectionDialog} onClose={handleCloseSectionDialog}>
                    <AddSectionList mode={modeType} selected={props.setSectionId} list={list} templateId={props.template} />
                </Dialog>
            </div>
        </div>
    )
}

export default SectionList