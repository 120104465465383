import React, { useEffect, useState } from 'react'
import styles from './CustomDragAndDrop.module.scss'
import Images from '../../assets/images/images'
import Dnd from './dnd';
import { APIConstant } from '../../services/APIConstant';
import axios from 'axios';
const CustomDnd = ({sendDataToParent, pageID, callBackParent}) => {
  const [widgets, setWidgets] = useState([]);
  const [destination, setDestination] = useState([])
  const [sourceData, setSourceData] = useState([])
  const handleOnDrag = (e, widgetId, widgetName, widgetIcon) => {
    e.dataTransfer.setData('widgetId', widgetId);
    e.dataTransfer.setData('widgetName', widgetName);
    e.dataTransfer.setData('widgetIcon', widgetIcon);
  }
  const handleOnDrop = (e) => {
   
    const widgetData = {'id':`s${widgets.length+1}`,'templateId':e.dataTransfer.getData('widgetId'), 'icon':e.dataTransfer.getData('widgetIcon'), 'name':e.dataTransfer.getData('widgetName')};
    setWidgets([...widgets, widgetData]);
    widgetData.pageId = pageID;
    widgetData.sequence_number = widgets.length + 1;
    axios.post(APIConstant.createPageTemplate, widgetData)
    .then(res => {
      widgetData.page_templateId = res.data.insertId;
    })
    .catch(err => console.log(err))
  } 
  const handleDragOver = (e) => {
    e.preventDefault();
  }
  const handleCallback = (childData) => {
    callBackParent()
    setWidgets(childData)
  }
  useEffect(() => {
    axios.post(APIConstant.getPageTemplatesWithID, { pageID: pageID})
    .then(res => {
      setWidgets(res.data);
    })
    .catch(err => console.log(err))
  }, [])
  useEffect(() => {
    sendDataToParent(widgets)
  }, [widgets])
  useEffect(() => {
    fetch(APIConstant.getTemplates)
        .then(res => res.json())
        .then((data) => {
          console.log('sourceData', data)
          setSourceData(data)
        })
        .catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.dndContainer}>
        <div className={styles.dndSource}>
          <h6>All Templates</h6>
            <ul className={styles.sourceElementCont} id="sourceElementCont">
                {sourceData?.map((item, i) => (
                    <li  key={i} draggable="true" onDragStart={(e) => handleOnDrag(e, item.id, item.name, item.icon)}>
                      <img src={Images[item.icon]} alt="Icon" width="50" />
                      {item.name}
                    </li>
                ))}
            </ul>
        </div>
        <div id="div2" className={`${styles.dndDestination} customDnd`} onDrop={handleOnDrop} onDragOver={handleDragOver}>
          <h6>Selected</h6>
                {/* {widgets.map((Widget, index) => (
                  <div className='dropped-widget' key={index}>
                    {Widget.name}
                  </div>
                ))} */}
                {/* {console.log('widgetType', widgets)} */}
          <Dnd widget={widgets} parentCallback={handleCallback} pageId={pageID}  />
        </div>
    </div>
  )
}

export default CustomDnd