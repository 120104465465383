import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import styles from './texteditor.module.scss'
import { Field, Form, Formik } from 'formik';
import { APIConstant } from '../../services/APIConstant';
import axios from 'axios';

const TextEditor = (props) => {
  const editor = useRef(null);
  const [legalid, setlegalid] = useState('');
	const [content, setContent] = useState('');
  const [pageName, setPageName] = useState(props.data.legal_name);
  const [permalink, setPermalink] = useState('');

  // setContent(props.data.page_content)
	// const config = useMemo(() => ({
	// 		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
	// 		placeholder: placeholder || 'Start typings...'
	// 	}),
	// 	[placeholder]
	// );
  useEffect(() => {
    setContent(props.data.page_content)
    setPageName(props.data.legal_name)
    setPermalink(props.data.permalink)
    setlegalid(props.data.legal_id)
  }, [props])
  
  return (
    <div>

<Formik
      initialValues={{
        legal_name: pageName,
        status: 'active',
        visibility: 'active'
      }}
      onSubmit={async (values) => {
        values['page_content'] = content
        values['legal_id'] = legalid
        let removedSpacesText = values.legal_name.split(" ").join("").toLowerCase();
        values['permalink'] = '/legal/'+removedSpacesText
        
        console.log(values)
        axios.post(APIConstant.updateLegalData, values)
        .then(res => {
          
        })
        .catch(err => console.log(err))
      }}
    >
      <Form>
          
            <div className={styles.formCont}>
              <div className={styles.pageTitle}>
                <label>Page Title</label>
                <Field type="text" id="legal_name" name="legal_name" />
              </div>
              <div className={styles.permalink}>
                <label>Permalink : </label>
                <a>{APIConstant.getBaseUrl}{permalink}</a>
              </div>
            </div>
            <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            />
            <div className={styles.btnCont}>
              <button type='submit' className={styles.publishBtn}>Publish</button>
            </div>
      </Form>
    </Formik>
    </div>
  )
}

export default TextEditor