import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Switch from '@mui/material/Switch';
import styles from './preview.module.scss'
import OptimizeSteps from '../../components/home/optimizesteps/optimizesteps';
import OmniChannel from '../../components/home/omnichannel/omni_channel';
import IntegrationMadeEasy from '../../components/home/integrationmadeeasy/integration_made_easy';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PreviewBanner from '../../components/preview/banner/banner';
import PreviewPlatform from '../../components/preview/platform/previewPlatform';
import PreviewPricing from '../../components/preview/pricing/previewPricing';
import PreviewPortfolio from '../../components/preview/portfolio/previewPortfolio';
import CustomDnd from '../dnd/CustomDragAndDrop';
import PreviewLCRI from '../../components/preview/lcri/previewLCRI';
import PreviewLIRC from '../../components/preview/lirc/previewLIRC';
import PreviewLIRCTwoCol from '../../components/preview/lircTwoColPreview/lircTwoColPreview';
import PreviewRRR from '../../components/preview/RRR/previewRRR';
import axios from 'axios';
import { APIConstant } from '../../services/APIConstant';
import { Dialog, Snackbar } from '@mui/material';
import Images from '../../assets/images/images';
import PreviewOptimizeSteps from '../../components/preview/optimize/previewOptimize';
import PreviewNoIntegration from '../../components/preview/nointegration/previewNoIntegration';
import PreviewIntegrationMadeEasy from '../../components/preview/integration/perviewIntegration';
import Navbar from '../../components/home/navbar/navbar';
import Footer from '../../components/home/footer/footer';
import PreviewProcessor from '../../components/preview/processor/previewProcessor';
import PreviewTextEditor from '../../components/preview/textEditor/textEditor';
import DeleteConfirmationDialog from '../../components/dialog/deleteConfirmationDialog/deleteConfirmationDialog';
import { TextConstant } from '../../constant/common';
import DeleteToaster from '../../components/toaster/delete/deleteToaster';

import { useDispatch } from "react-redux"
import images from '../../assets/images/images';
import { getActivePageId } from '../../store/slices/activePageSlice';
import { allPagesList } from '../../store/slices/pagesSlice';
import { useSelector } from 'react-redux';
import DraftCreateConfirmation from '../../components/dialog/draftCreation/draftCreateConfirmation';

function PagePreview() {
  const [checked, setChecked] = useState();
  const [draftPageData, setDraftPageData] = useState({});
  const [pageId, setPageId] = useState('');
  const [pageData, setPageData] = useState({});
  const [templates, setTemplates] = useState({});
  const [viewMode, setViewMode] = useState('preview');
  const [dataFromChild, setDataFromChild] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openConfirmDraftDialog, setOpenConfirmDraftDialog] = useState(false);
  const [openDeleteToaster, setOpenDeleteToaster] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [globalPageId, setGlobalPageId] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValue] = useState({
    pageName: '',
    pageUrl: '',
    categories: ''
  });

  const globalPage = useSelector((state) => state.allPagesList)

  useEffect(() => {
    setGlobalPageId(globalPage.pages)
  }, [globalPage])
    
  const handleOpenDeleteConform = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseDeleteConform = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleOpenDraftConform = (draftData) => {
    setOpenConfirmDraftDialog(true);
    setDraftPageData(draftData)
  };

  const handleCloseDraftConform = () => {
    setOpenConfirmDraftDialog(false);
  };
  
  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if (checked) {
      visbility = 'hide'
    }
    else {
      visbility = 'active'
    }
  };

  const getPageTemplates = () => {
    axios.post(APIConstant.getPageTemplatesWithID, { pageID: pageId})
    .then(res => {
      setTemplates(res.data);
      
    })
    .catch(err => console.log(err))
  } 

  const handleDataFromChild = (data) => {
    // console.log('data from child',data)
    getPageTemplates()
    setDataFromChild(data);
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSuccess = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event) => {
    setOpenSnackbar(false);
  };
 
  useEffect(() => {
    
    if(!pageId){
      setInitialValue({
        pageName: '',
        pageUrl: '',
        categories: ''
      })
    }else{
    }
    const pid = searchParams.get("id")
    setPageId(pid)

    fetch(APIConstant.getSelectedPage, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ page_id : pid})
    })
    .then(res => res.json())
    .then((data) => {
        setPageData(data[0])
        setInitialValue({
          pageName: data[0].page_name,
          pageUrl: data[0].page_url,
          categories: data[0].page_category
        })
     
       
    })
    .catch(err => console.log(err))

  
    getPageTemplates(pid)

  }, [searchParams, pageId])

  useEffect(() => {
    
    dispatch(getActivePageId(searchParams.get("id")))
  }, [searchParams])

  const changeMode = (mode) => {
    if(mode === 'preview'){
      console.log(dataFromChild)
    }
    setViewMode(mode)
    setLoader(true)

    const closeLoader = () => {
      setLoader(false)
    }
    setTimeout(closeLoader, 1000);

    

  }


  
  // const draftPage = () => {
  //   fetch(APIConstant.getSelectedPage, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ page_id : pageId})
  //   })
  //   .then(res => res.json())
  //   .then((data) => {
  //       axios.post(APIConstant.publishPage, data[0])
  //       .then(res => {
  //         console.log('done')
  //       })
  //       .catch(err => console.log(err))
  //   })
  //   .catch(err => console.log(err))
  // }
  const createNewDraftPage = (pageData, insertId) => {
      axios.post(APIConstant.draftPage, pageData)
      .then(res => {
        // console.log('asasas',res)
        navigate(`/admin/preview?id=${res.data}`)
      })
      .catch(err => console.log(err))
  }
  const createDraft = () => {
        fetch(APIConstant.getSelectedPage, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ page_id : pageId})
        })
        .then(res => res.json())
        .then((data) => {
          if(data[0].status === 'published'){
            axios.post(APIConstant.getDraftPageWithGroupId, {'groupID': data[0].group_id})
            .then(res => {
              if(res.data.length > 0){
                // navigate(`/admin/preview?id=${res.data[0].page_id}`)
                // console.log('length is one')
                handleOpenDraftConform(data[0])
              }else{
                createNewDraftPage(data[0], res.data.insertId)
                /*  CREATE DRAFT PAGE */
                // axios.post(APIConstant.draftPage, data[0])
                // .then(res => {
                //   navigate(`/admin/preview?id=${res.data.insertId}`)
                // })
                // .catch(err => console.log(err))
              }
            })
            .catch(err => console.log(err))
          }
        })
        .catch(err => console.log(err))
        changeMode('edit')
  }

  const confirmDraftValue = (res) => {
    // if(res){
    //   console.log(res)
    // }else{
    //   console.log(res)
    // }
    handleCloseDraftConform()
  }
 
  const publishPage = () => {
    axios.put(APIConstant.publishPage, {'pageID': pageId})
    .then(res => {
      changeMode('preview')
      if(res.data.message === 'success'){
        // navigate(`/admin/preview?id=${res.data.data}`)
        pageData.status = 'published'
      }
    })
    .catch(err => console.log(err))
  }
  
  const deletePage = () => {
    let globalId = globalPageId?.filter(item => item.is_landing === 'yes' && item.status === 'published')
  
    axios.post(APIConstant.deletePage, {'pageID' : pageId})
    .then(res => {
      navigate(`/admin/preview?id=${globalId[0].page_id}`)
      setOpenDeleteToaster(true)
    })
    .catch(err => console.log(err))
  }
  const confirmValue = (res) => {
    if(res){
      deletePage()
      handleCloseDeleteConform()
    }else{
      handleCloseDeleteConform()
    }
  }
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div className={styles.previewCont}>

      {loader &&
        <div className={styles.loaderCont}>
          <img src={images.LOADER} alt="Loader" width="150" />
        </div>
      }
      

      {pageId && viewMode === 'edit' && 
      <div className={styles.previewSidebar}>
        <div className={styles.sectionOrder}>
          <CustomDnd sendDataToParent = {handleDataFromChild} pageID = {pageId} callBackParent={getPageTemplates}/>
        </div>
      </div>
      }
      <div className={styles.mainWrapper}>
        <header className={styles.appHeader}>
          <div className={styles.title}>
            {pageId &&
              <h3 style={{ width: '100%' }}>
                {pageData?.page_name} <br/>
                {pageData?.status === 'draft'
                  ?<span>Draft</span>
                  :<span>Published</span>
                }
               
              </h3>
            }
            {!pageId &&
              <h3 style={{ width: '100%' }}>Create New Page</h3>
            }
            {pageId &&
              <ul>
                {viewMode === 'edit' && 
                  <li style={{marginRight: '10px'}}>
                    <a style={{cursor: 'pointer'}} onClick={() => changeMode('preview')}><img src={Images.HOME} alt="Home" width="24" /></a>
                  </li>
                }
                {/* <li>
                  <div className="customeSwitch">
                    <Switch
                      checked={checked}
                      onChange={handleVisibilityChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span className={checked ? 'active switchlabel' : 'hide switchlabel'}>{checked ? 'Active' : 'Hide'}</span>
                  </div>
                </li> */}
                {(pageData?.is_landing === 'no' || pageData?.status === 'draft') && (viewMode === 'preview') &&
                  <li>
                    {/* onClick={deletePage} */}
                   <button className={styles.delete} onClick={handleOpenDeleteConform}>
                     <img src={Images.DELETE} width="20" title="Delete" alt="delete" />
                   </button>
                  </li>
                }
                {pageData?.status === 'draft' &&
                  <li>
                    <button className={styles.preview} onClick={publishPage}>Publish</button>
                  </li>
                }
                {pageData?.status === 'published' &&
                  <li><button className={styles.edit} onClick={createDraft}>Draft Page</button></li>
                }
                <li>
                  {viewMode === 'preview' && pageData?.status === 'draft'
                    ? <button className={styles.edit} onClick={() => changeMode('edit')}>Edit</button>
                    : ''
                  }
                  {/* <button className={styles.edit} onClick={() => changeMode('preview')}>Save as Draft</button> */}
                </li>
                
                {/* {console.log('pageData', pageData)} */}
                
                 
              </ul>
            }
          </div>
          <div className={styles.headerCont}>
            
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {

               

                values.group_id = Math.floor(100000000 + Math.random() * 900000000);

                axios.post(APIConstant.createPage, values)
                .then(res => {
                  setPageId(res.data.insertId)
                  
                  handleSuccess()
                  navigate(`/admin/preview?id=${res.data.insertId}`)
                })
                .catch(err => console.log(err))

                fetch(APIConstant.getPageList)
                .then(res => res.json())
                .then((data) => {
                    dispatch(allPagesList(data))
                })
                .catch(err => console.log(err))
              }}

              enableReinitialize
            >
              <Form>
                <div className={styles.formCont}>
                  <div className={styles.fieldCont}>
                    
                    <label>Page Name</label>
                    <Field type="text" name="pageName"/>
                  </div>
                  <div className={styles.fieldCont} >
                    <label>Page Url</label>
                    <span className={styles.placeholderTxt}>https://www.payorc.com/</span>
                    <Field type="text" name="pageUrl" className={styles.pageUrl} />
                  </div>
                </div>
                <div className={styles.formCont} style={{ display: 'flex' }}>
                  <div className={`${styles.visibility} pageVisibility custonSelectBox`}>
                    <h5>Page Categories</h5>
                    <div className={styles.visibleWrapper}>
                      <div className={styles.fieldCont}>
                        <Field as="select" name="categories">
                          <option value="home">Home</option>
                          <option value="marketing">Marketing</option>
                          <option value="policy">Policy</option>
                          <option value="others">Others</option>
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.visibility} pageVisibility`}>
                    <h5>Visibility</h5>
                    <div className={styles.visibleWrapper}>

                      <div className={styles.fieldCont}>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={countries}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            );
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.visibility} pageVisibility`}>
                    <h5>Restricted</h5>
                    <div className={styles.visibleWrapper}>

                      <div className={styles.fieldCont}>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={countries}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            );
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  {!pageId 
                    ? <ul>
                      <li><button>Cancel</button></li>
                      <li><button className={styles.primary} type="submit">Create Page</button></li>
                    </ul>
                    : <ul>
                    {/* <li> <button className={styles.primary} type="submit">Update</button></li> */}
                  </ul>
                  }
                  
                </div>
              </Form>
            </Formik>
          </div>

        </header>

        {templates?.length > 0 &&

          <section className={styles.previewSection}>
            <header>
              <h4>Page Preview</h4>
            </header>
            <div className={`${styles.previewWrapper} previewWrapper`}>
              <div style={{backgroundColor: '#398378', height: '70px'}}>
              <Navbar />
              </div>
              {/* {console.log('templates', templates)} */}
              {templates?.map((item, index) => {
                return (
                  <div key={index}>

                    {item.templateId === 1 &&
                      <PreviewBanner templateId={item.id} />
                    }
                    {item.templateId === 2 &&
                      <PreviewPlatform templateId={item.id}/>
                    }
                    {item.templateId === 3 &&
                      <PreviewOptimizeSteps templateId={item.id} />
                    }
                    {item.templateId === 4 &&
                      <PreviewPortfolio templateId={item.id}/>
                    }
                    {item.templateId === 5 &&
                      <PreviewNoIntegration templateId={item.id}/>
                    }
                    {item.templateId === 6 &&
                      <PreviewIntegrationMadeEasy templateId={item.id}/>
                    }
                    {item.templateId === 7 &&
                      <PreviewPricing templateId={item.id}/>
                    }
                    {item.templateId === 8 &&
                      <PreviewLIRC templateId={item.id}/>
                    }
                    {item.templateId === 9 &&
                      <PreviewLCRI templateId={item.id}/>
                    }
                    {item.templateId === 10 &&
                      <PreviewLIRCTwoCol templateId={item.id}/>
                    }
                    {item.templateId === 11 &&
                      <PreviewRRR templateId={item.id}/>
                    }
                    {item.templateId === 12 &&
                      <PreviewProcessor templateId={item.id}/>
                    }
                    {item.templateId === 13 &&
                      <PreviewTextEditor templateId={item.id}/>
                    }
                  </div>
                )
              })
              }
              <Footer />
              {/* <CookiePolicy /> */}
            </div>
          </section>
        }

       
        {/* Dialog */}
        <Dialog open={openConfirmDeleteDialog} onClose={handleCloseDeleteConform}>
          <DeleteConfirmationDialog message={{title:TextConstant.AreYouSure, description: TextConstant.DeleteConfirmMessage}} getConfirmation={confirmValue}/>
        </Dialog>

        <Dialog open={openConfirmDraftDialog} onClose={handleCloseDraftConform} >
          <DraftCreateConfirmation getConfirmation={confirmDraftValue} parentDraftData={draftPageData}/>
        </Dialog>

        

        {/* Toaster */}
        {openDeleteToaster &&
          <DeleteToaster/>
        }
        
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Page Created Successfully"
      />

      </div>
    </div>
  );
}

export default PagePreview;




const regionMain = [
  { name: 'APAC' },
  { name: 'EU' },
  { name: 'MENA' },
  { name: 'MENA_EGYPT' },
  { name: 'MENA_IRAQ' },
  { name: 'MENA_JORDAN' },
  { name: 'MENA_KUWAIT' },
  { name: 'MENA_LEBANON' },
  { name: 'MENA_SAUDI ARABIA' },
  { name: 'MENA_UNITED ARAB EMIRATES' },
];

const regionSub = [
  { name: 'SAARC' },
  { name: 'OCEANIA' },
  { name: 'SEA' },
  { name: 'EAPAC' },
  { name: 'OCEANIA' },
  { name: 'CAPAC' }
];
const countries = [
  { name: 'AFGHANISTAN' },
  { name: 'BANGLADESH' },
  { name: 'BHUTAN' },
  { name: 'INDIA' },
  { name: 'MALDIVES' },
  { name: 'NEPAL' },
  { name: 'PAKISTAN' },
  { name: 'SRI LANKA' }
];