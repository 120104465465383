// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textEditor_sectionBtns__u\\+8bb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 100px;
  top: 20px;
}
.textEditor_sectionBtns__u\\+8bb li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 34px;
}
.textEditor_sectionBtns__u\\+8bb li button.textEditor_discard__6kzGV, .textEditor_sectionBtns__u\\+8bb li button.textEditor_edit__hec\\+5 {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.textEditor_sectionBtns__u\\+8bb li button.textEditor_primary__0pvl5 {
  background-color: #398378;
  color: #FFFFFF;
}

.textEditor_previewContainer__DkUxl {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/textEditor/textEditor.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AAAY;EACI,yBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,cAAA;AAEhB;;AAGA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AAAJ","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    position: absolute;\n    right: 100px;\n    top: 20px;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 34px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `textEditor_sectionBtns__u+8bb`,
	"discard": `textEditor_discard__6kzGV`,
	"edit": `textEditor_edit__hec+5`,
	"primary": `textEditor_primary__0pvl5`,
	"previewContainer": `textEditor_previewContainer__DkUxl`
};
export default ___CSS_LOADER_EXPORT___;
