import React from 'react'
import styles from './organization.module.scss';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Organization from './organization';
import Navbar from '../../home/navbar/navbar';
import Footer from '../../home/footer/footer';

const UpdateOrganization = ({templateId}) => {
    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };    
  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className='orgNav'>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Org Info" value="1" />
            <Tab label="Page Header" value="2" />
            <Tab label="Page Footer" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <Organization templateId={templateId}/>
        </TabPanel>
        <TabPanel value="2">
           <div className='navWrapper'><Navbar /></div>
        </TabPanel>
        <TabPanel value="3">
            <Footer />
        </TabPanel>
      </TabContext>
    </Box>
    </div>
  )
}

export default UpdateOrganization