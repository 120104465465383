// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_sectionBtns__fHp\\+w {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
}
.banner_sectionBtns__fHp\\+w li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  height: 30px;
}
.banner_sectionBtns__fHp\\+w li button.banner_discard__4sgF2, .banner_sectionBtns__fHp\\+w li button.banner_edit__UDrrk {
  background-color: #FFFFFF;
  border: 1px solid #398378;
  padding: 0px 15px;
}
.banner_sectionBtns__fHp\\+w li button.banner_primary__fqJwc {
  background-color: #398378;
  color: #FFFFFF;
  padding: 0px 15px;
}

.banner_previewContainer__gdSuT {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
}
.banner_previewContainer__gdSuT > header {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/banner/banner.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EAEA,YAAA;AAAZ;AACY;EACI,yBAAA;EACA,yBAAA;EACA,iBAAA;AAChB;AACY;EACI,yBAAA;EACA,cAAA;EACA,iBAAA;AAChB;;AAKA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;AAFJ;AAGI;EACI,aAAA;EACA,mBAAA;AADR","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 5px;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            // padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n                padding:0px 15px\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n                padding:0px 15px\n            }\n        }\n    }\n}\n\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n    & > header{\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `banner_sectionBtns__fHp+w`,
	"discard": `banner_discard__4sgF2`,
	"edit": `banner_edit__UDrrk`,
	"primary": `banner_primary__fqJwc`,
	"previewContainer": `banner_previewContainer__gdSuT`
};
export default ___CSS_LOADER_EXPORT___;
