import React from 'react'


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import PaymentSections from './Payments';
import UpdateNocodeTitle from './UpdateTitle';

const UpdateNoCode = ({template}) => {
    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };    
  return (
    <div style={{ padding: '10px'}}>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Header Title" value="1" />
            <Tab label="Sub-Section" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <UpdateNocodeTitle templateId={template}/>
        </TabPanel>
        <TabPanel value="2">
            <PaymentSections templateId={template}/>
        </TabPanel>
      </TabContext>
    </Box>
    </div>
  )
}

export default UpdateNoCode