// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.processor_imageCont__JMFO0 {
  padding: 20px;
}
.processor_imageCont__JMFO0 h5 {
  margin-bottom: 10px;
}

.processor_actionBtns__8T7eU {
  display: flex;
  align-items: center;
  height: 50px;
}
.processor_actionBtns__8T7eU ul {
  display: flex;
}
.processor_actionBtns__8T7eU ul li {
  margin: 0px 5px;
}
.processor_actionBtns__8T7eU ul li a {
  width: 30px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processor_actionBtns__8T7eU ul li a.processor_edit__vs\\+8m {
  background-color: rgba(25, 118, 210, 0.1);
}
.processor_actionBtns__8T7eU ul li a.processor_edit__vs\\+8m img {
  stroke: #1976d2;
}
.processor_actionBtns__8T7eU ul li a.processor_delete__syMnv {
  background-color: rgba(200, 31, 40, 0.1);
}
.processor_actionBtns__8T7eU ul li a.processor_delete__syMnv img {
  stroke: #C81F28;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/home/sections/processor/processor.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,mBAAA;AAER;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAEI;EACI,aAAA;AAAR;AACQ;EACI,eAAA;AACZ;AAAY;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEhB;AADgB;EACI,yCAAA;AAGpB;AAFoB;EACI,eAAA;AAIxB;AADgB;EACI,wCAAA;AAGpB;AAFoB;EACI,eAAA;AAIxB","sourcesContent":[".imageCont{\n    padding: 20px;\n    h5{\n        margin-bottom: 10px;\n    }\n}\n\n\n\n.actionBtns{\n    display: flex;\n    align-items: center;\n    height: 50px;\n    ul{\n        display: flex;\n        li{\n            margin: 0px 5px;\n            a{\n                width: 30px;\n                border-radius: 5px;\n                height: 30px;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                &.edit{\n                    background-color: rgba(#1976d2, 0.1);\n                    img{\n                        stroke:#1976d2;\n                    }\n                }\n                &.delete{\n                    background-color: rgba(#C81F28, 0.1);\n                    img{\n                        stroke:#C81F28;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageCont": `processor_imageCont__JMFO0`,
	"actionBtns": `processor_actionBtns__8T7eU`,
	"edit": `processor_edit__vs+8m`,
	"delete": `processor_delete__syMnv`
};
export default ___CSS_LOADER_EXPORT___;
