

import React, { useEffect, useState } from 'react'
import styles from './lircTwoCol.module.scss'
import { Formik, Field, Form } from 'formik';
import { APIConstant } from '../../../../../services/APIConstant';

const AddColumnList = ({templateId, showMode, activeItem}) => {
    
    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
      });
      console.log(templateId, showMode, activeItem)
    useEffect(() => {
        fetch(APIConstant.getLircTwoColListWithId, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id : activeItem})
        })
        .then(res => res.json())
        .then((data) => {
            console.log('Data :', data)
            setInitialValues({
                title: data[0].title,
                description: data[0].description,
                image_path: data[0].image_path
            })
        })
        .catch(err => console.log(err))
    }, [])
  return (
    <div className={styles.addSectionCont}>
        <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        
        values.templateId = templateId
        
        if(showMode === 'add'){
          console.log('add')
            fetch(APIConstant.addLircTwoColList, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(res => res.json())
            .catch(err => console.log(err))
        }else{
            values.id = activeItem
            fetch(APIConstant.updateLircTwoColListWithId, {
                method: 'PUT',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(res => res.json())
            .catch(err => console.log(err))
        }
        
      }}
      enableReinitialize
    >
      <Form>
        {showMode === 'add'
            ? <h4>Add</h4>
            : <h4>Update</h4>
        }
        
        <label htmlFor="title">Title</label>
        <Field id="title" name="title"  />

        <label htmlFor="description">Description</label>
        <Field id="description" name="description" />

        {showMode === 'add'
            ? <button type="submit">Add</button>
            : <button type="submit">Update</button>
        }
        
      </Form>
    </Formik>
    </div>
  )
}

export default AddColumnList