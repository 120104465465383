// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_menu__XXXLY a {
  display: flex;
  height: 40px;
  width: 40px;
  max-width: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.menu_menu__XXXLY a span {
  height: 40px;
  width: 40px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/header/menu/menu.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,qCAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AACQ;EACI,YAAA;EACA,WAAA;EACA,aAAA;AACZ","sourcesContent":[".menu{\n    a{\n        display: flex;\n        height: 40px;\n        width: 40px;\n        max-width: 40px;\n        border-radius: 50%;\n        background-color: rgba(0,0,0,0.06);\n        overflow: hidden;\n        align-items: center;\n        justify-content: center;\n        span{\n            height: 40px;\n            width: 40px;\n            display: flex;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `menu_menu__XXXLY`
};
export default ___CSS_LOADER_EXPORT___;
