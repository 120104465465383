import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import styles from './legal.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import Navbar from '../navbar/navbar';

const LegalHome = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [legalData, setLegalData] = useState({});

    useEffect(() => {
        axios.post(APIConstant.getSelectedLegalData, { legal_id: searchParams.get("id")  })
        .then(res => {
            setLegalData(res.data[0])
        })
        .catch(err => console.log(err))
      }, [searchParams.get("id")])

  return (
    <div className={styles.legalHome}>
        <Navbar />
        <header>
            <h3>{legalData.legal_name}</h3>
        </header>
        <div className="container">
            <div className={styles.mainContainer}>
            <div className={styles.pageContent} dangerouslySetInnerHTML={ {__html: legalData.page_content} } />
            </div>
        </div>
    </div>
  )
}

export default LegalHome