// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textEditor_textEditorContainer__93e0I {
  padding-top: 50px;
}
.textEditor_textEditorContainer__93e0I .textEditor_heading__5lotq {
  background-color: #F8F8F8;
  text-align: center;
  padding: 50px 0px;
}
.textEditor_textEditorContainer__93e0I .textEditor_heading__5lotq h4 {
  font-size: 34px;
  color: rgb(57, 131, 120);
}
.textEditor_textEditorContainer__93e0I .textEditor_editorData__Zu4DG {
  padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/textEditor/textEditor.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAAI;EACI,yBAAA;EACA,kBAAA;EACA,iBAAA;AAER;AADQ;EACI,eAAA;EACA,wBAAA;AAGZ;AAAI;EACI,aAAA;AAER","sourcesContent":[".textEditorContainer{\n    padding-top: 50px;\n    .heading{\n        background-color: #F8F8F8;\n        text-align: center;\n        padding: 50px 0px;\n        h4{\n            font-size: 34px;\n            color: rgb(57, 131, 120);\n        }\n    }\n    .editorData{\n        padding: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textEditorContainer": `textEditor_textEditorContainer__93e0I`,
	"heading": `textEditor_heading__5lotq`,
	"editorData": `textEditor_editorData__Zu4DG`
};
export default ___CSS_LOADER_EXPORT___;
