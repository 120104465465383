import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import styles from './updateTextEditor.module.scss'
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdateTextEditor = ({ templateId }) => {
  const editor = useRef(null);
const [content, setContent] = useState('');
 
const [mode, setMode] = useState('');

    useEffect(() => {
        axios.post(APIConstant.getTexteditorData, {templateId: templateId})
        .then(res => {
            setContent(res.data[0].text_content)
            if(res.data.length > 0){
                setMode('edit')
            }else{
                setMode('add')
            }
        })
        .catch(err => console.log(err))
    }, [templateId])
  return (
    <div>

<Formik
      initialValues={{
        // content: '',
        status: 'active',
        visibility: 'active'
      }}
      onSubmit={async (values) => {
        values['page_content'] = content

        values['templateId'] = templateId
        
        

        if(mode === 'edit'){
          axios.put(APIConstant.updateTextEditorData, values)
          .then(res => {
            
          })
          .catch(err => console.log(err))
        }else{
          axios.post(APIConstant.createTextEditorData, values)
          .then(res => {
            
          })
          .catch(err => console.log(err))
        }
        
      }}
    >
      <Form>
        <div className={styles.createNewPage}>
          <div className={styles.btnCont}>
                {/* <button  className={styles.btnBordered}>Cancel</button> */}
                {mode === 'edit'
                    ? <button type='submit' className={styles.btnPrimary}>Update</button>
                    : <button type='submit' className={styles.btnPrimary}>Submit</button>
                }
                
            </div>
            <h3>Create Text Page</h3>
            {/* <div className={styles.formCont}>
              <div className={styles.pageTitle}>
                <label>Title</label>
                <Field type="text" id="legal_name" name="legal_name" />
              </div>
            </div> */}
            <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            />
            
            </div>
      </Form>
    </Formik>
    </div>
  )
}

export default UpdateTextEditor