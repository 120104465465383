import axios from 'axios'
import styles from './textEditor.module.scss'
import React, { useEffect, useState } from 'react'
import { APIConstant } from '../../../services/APIConstant'
import { useSelector } from 'react-redux'

const TextEditorHome = ({ templateId }) => {
    const [pageContent, setPageContent] = useState();
    const [pageData, setPageData] = useState({});
    const activePageId = useSelector((state) => state.activePageId)

    console.log('activePageId', activePageId.id)
    
    useEffect(() => {
        axios.post(APIConstant.getTexteditorData, {templateId: templateId})
        .then(res => {
            setPageContent(res.data[0])
        })
        .catch(err => console.log(err))
    }, [templateId])

    useEffect(() => {
        axios.post(APIConstant.getSelectedPage, {page_id: activePageId.id})
        .then(res => {
            setPageData(res.data[0])
        })
        .catch(err => console.log(err))
    }, [activePageId.id])

    return (
        <div className={styles.textEditorContainer}>
            <div className={styles.heading}>
                <h4>{pageData?.page_name}</h4>
            </div>
            <div className={styles.editorData}>
                <div dangerouslySetInnerHTML={ {__html: pageContent?.text_content} } />
            </div>
        </div>
    )
}

export default TextEditorHome