// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loaderContainer__OJRqA {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.loader_loaderContainer__OJRqA img {
  border-radius: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,oCAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;AACJ;AAAI;EACI,mBAAA;AAER","sourcesContent":[".loaderContainer{\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    z-index: 1000;\n    background-color: rgba(0,0,0,0.5);\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n    img{\n        border-radius: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `loader_loaderContainer__OJRqA`
};
export default ___CSS_LOADER_EXPORT___;
