import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import styles from './pricingupdate.module.scss'
import images from '../../../../../assets/images/images'
import AddPricingPlan from './addPlan';
import { APIConstant } from '../../../../../services/APIConstant';
import AddFeature from './addFeature';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';


const PricingForm = ({templateId}) => {
  const [open, setOpen] = React.useState(false);
  const [plans, setPlans] = React.useState([]);
  const [planData, setPlanData] = React.useState([]);
  const [features, setFeatures] = React.useState([]);
  // console.log('hghj', templateId)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deletePlan = (id) => {
    axios.put(APIConstant.deletePricingPlan, { planid: id })
      .then(res => {

      })
      .catch(err => console.log(err))
  }
  useEffect(() => {

    fetch(APIConstant.getPricingPlan, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    }
	)
      .then(res => res.json())
      .then((data) => {
        setPlans(data)
      })
      .catch(err => console.log(err))
  }, [])
  useEffect(() => {

    fetch(APIConstant.getPricingPlanData,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    }
  )
      .then(res => res.json())
      .then((data) => {
        setPlanData(data)
      })
      .catch(err => console.log(err))
  }, [])


  const getFeatures = () => {
    fetch(APIConstant.getPricingFeatures, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId : templateId})
      }
    )
    .then(res => res.json())
    .then((data) => {
      // console.log(data)
      setFeatures(data)
    })
    .catch(err => console.log(err))
  }
  
  useEffect(() => {
    getFeatures()
    
  }, [])

  const getPlansData = (featureId) => {
    console.log(planData, featureId)
    return planData.filter(val => val.feature_id === featureId && val.template_id === templateId)
  }
  const getInitialValue = (featureId, featureName) => {

    let selectedPlanData = planData.filter(val => val.feature_id === featureId)
    let initialValue = {}
    let dataList = []
    for (let i = 0; i < selectedPlanData.length; i++) {
      initialValue[`featureId`] = featureId
      initialValue[`feature`] = featureName
      dataList.push({ fieldName: selectedPlanData[i].feature_value, fieldId: selectedPlanData[i].data_id, featureId: featureId, planId: selectedPlanData[i].plan_id })
      initialValue[`featField${i + 1}`] = { fieldName: selectedPlanData[i].feature_value, fieldId: selectedPlanData[i].data_id }
    }

    initialValue[`planDataList`] = dataList;

    return initialValue
  }

  const addFeature = (values) => {
    axios.put(APIConstant.updateFeatureListData, values)
      .then(res => {
      })
      .catch(err => console.log(err))

      for (let i = 0; i < values.planDataList.length; i++) {
        axios.put(APIConstant.updatePricingPlanData, values.planDataList[i])
          .then(res => {
            // console.log('done')
          })
          .catch(err => console.log(err))
      }
  }

  const deleteFeature = (featureId) => {
    // console.log('done')
    axios.put(APIConstant.deleteFeature, {feature_id:featureId})
    .then(res => {
      // console.log('done')
    })
    .catch(err => console.log(err))
  } 
  return (
    <div className={styles.pricingForm}>


      <div className={styles.pricingWrapper}>
        <div className={styles.pricingGrid}>
          <table className={styles.planDetails}>
            <thead>
              <tr>
                <th>FEATURES </th>
                {plans?.map((item, index) => {
                  return (
                    <th>PLAN 0{index + 1}</th>
                  )
                }
                )}
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}></td>
                {plans?.map((item, index) => {
                  return (
                    <td>
                      <input type='text' value={item.plan_name} placeholder='Enter Plan Name' name={`plan${index + 1}`} />
                    </td>
                  )
                }
                )}
                <td rowSpan={2}></td>
              </tr>
              <tr>
                {plans?.map((item, index) => {
                  return (
                    <td>
                      <input type='text' value={item.plan_price} placeholder='Enter Plan Price' name={`price${index + 1}`} />
                    </td>
                  )
                }
                )}
              </tr>
            </tbody>
          </table>

          <AddFeature getplans={plans} templateId={templateId}/>


          <table>
            <tbody>
              {features?.map((item, index) => {
                return (
                  <div className={styles.customTableRow}>
                    <Formik
                      initialValues={getInitialValue(item.feature_id, item.title)}
                      onSubmit={async (values) => {
                        // alert(JSON.stringify(values, null, 2));
                        console.log(values)
                        addFeature(values)
                        getFeatures()
                        getInitialValue(item.feature_id, item.title)
                      }

                      }
                      enableReinitialize
                    >
                      <Form>


                        <tr>
                          <td>
                            <Field type="text" name={`feature`} />
                          </td>
                          {getPlansData(item.feature_id)?.map((item, index) => {
                            return (
                              <td>
                                {/* {alert('hjghjfg')} */}
                                
                                <Field type="text" name={`planDataList[${index}].fieldName`} />
                              </td>
                            )
                          }
                          )}
                          <td>
                            <ul>
                              <li><button type="submit" className={styles.btnUpdate}><img src={images.UPDATE} alt="Update" width="20" /></button></li>
                              <li><a className={styles.btnDelete} onClick={() => deleteFeature(item.feature_id)}><img src={images.DELETE} alt="Delete" width="20" /></a></li>
                            </ul>
                          </td>
                        </tr>
                      </Form>
                    </Formik>
                  </div>
                )
              }
              )}

            </tbody>
          </table>



          <table>
            <tbody>
              <tr>
                <td></td>
                {plans?.map((item, index) => {
                  return (
                    <td>
                      <ul>
                        <li><a className={styles.btnDelete} onClick={() => deletePlan(item.plan_id)}><img src={images.DELETE} alt="Delete" width="20" /></a></li>
                      </ul>
                    </td>
                  )
                }
                )}
                <td></td>
              </tr>
            </tbody>
          </table>

          <div className={styles.secBtnContainer}>
            <button className={styles.btnAddPlan} onClick={handleClickOpen}>
              Add Plan
            </button>
          </div>

          <Dialog open={open} onClose={handleClose} >
            <AddPricingPlan templateId={templateId}/>
               
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default PricingForm