import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import images from '../../../../../assets/images/images';
import styles from './integrationmadeeasy.module.scss'
import Dialog from '@mui/material/Dialog';

import BannerImg from '../../../../../assets/images/homeBanner001.webp'
import AddSubSection from './AddSubSection';
import { APIConstant } from '../../../../../services/APIConstant';
import axios from 'axios';

const IntegrationEasySubSection = ({templateId}) => {
    const [dataList, setDataList] = useState([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [mode, setMode] = useState('');
    const [subCategoryItems, setSubCategoryItems] = useState('');

    const handleAddDialogOpen = (modeval, id) => {
       
        setOpenAddDialog(true);
     
    };
    const handleAddDialogClose = () => {
        setOpenAddDialog(false);
    };

    const deleteList = (id) => {
        axios.post(APIConstant.deleteIntegrationEasySectionWithId, {id})
        .catch(err => console.log(err))
    }

    // getCommonSubSectionIntegrationEasy
    useEffect(() => {
        fetch(APIConstant.getCommonSubSectionIntegrationEasy, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({templateId: templateId, category: 'common'})
          })
          .then(res => res.json())
          .then((data) => {
            setSubCategoryItems(data)
          })
          .catch(err => console.log(err))
      })
    const columns = [
        {
            headerName: 'Image',
            field: 'image',
            width: 120,
            renderCell: (item) => (
                <div className={styles.imgCont}>
                    <img src={item.row.img_path} alt="Banner" height='40'/>
                </div>
            )
        },
        { field: 'title', headerName: 'Title', width: 300 },
        // { field: 'description', headerName: 'Description', flex: 1 },
        
        {
            field: 'actions',
            headerName: '',
            renderCell: (item) => (
                <div className={styles.actionBtns}>
                    <ul>
                        {/* <li><a className={styles.edit}><img src={images.EDIT} width="20" alt="Edit" /></a></li> */}
                        <li><a className={styles.delete} onClick={() => deleteList(item.row.id)}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                    </ul>
                </div>
            )
        },
    ];

    // const rows = [
    //     { id: 1, title: 'One Integration', description: 'Single Application andLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore', imgurl: 35 },
    //     { id: 2, title: 'Payments Made Powerful', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore', imgurl: 35 }
    // ];

    return (
        <div className={styles.bannerList}>
            <div style={{ height: 'auto', width: '100%' }} className="customDataGrid">
                <DataGrid
                    rows={subCategoryItems}
                    columns={columns}
                />
                
            </div>
            <div className="gridBtnCont">
                <button onClick={() => handleAddDialogOpen('add')}>Add New</button>
            </div>

            <Dialog open={openAddDialog} onClose={handleAddDialogClose} >
                <AddSubSection templateId={templateId}/>
            </Dialog>
        </div>
    )
}

export default IntegrationEasySubSection