// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draftCreateConfirmation_draftCreateConfirmation__9cqOz {
  width: 500px;
  padding: 40px;
  line-height: 30px;
}
.draftCreateConfirmation_draftCreateConfirmation__9cqOz .draftCreateConfirmation_btnCont__uQW82 {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  justify-content: flex-end;
}
.draftCreateConfirmation_draftCreateConfirmation__9cqOz .draftCreateConfirmation_btnCont__uQW82 button {
  border: none;
  height: 36px;
  padding: 0px 20px;
  border-radius: 5px;
}
.draftCreateConfirmation_draftCreateConfirmation__9cqOz h4 {
  font-size: 20px;
}
.draftCreateConfirmation_draftCreateConfirmation__9cqOz select {
  width: 100%;
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/components/dialog/draftCreation/draftCreateConfirmation.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;AAAI;EACI,aAAA;EACA,gBAAA;EACA,SAAA;EACA,yBAAA;AAER;AADQ;EACI,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAGZ;AAAI;EACI,eAAA;AAER;AACI;EACI,WAAA;EACA,YAAA;AACR","sourcesContent":[".draftCreateConfirmation{\n    width: 500px;\n    padding: 40px;\n    line-height: 30px;\n    .btnCont{\n        display: flex;\n        margin-top: 20px;\n        gap: 10px;\n        justify-content: flex-end;\n        button{\n            border: none;\n            height: 36px;\n            padding: 0px 20px;\n            border-radius: 5px;\n        }\n    }\n    h4{\n        font-size: 20px;\n    }\n    \n    select{\n        width: 100%;\n        height: 36px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draftCreateConfirmation": `draftCreateConfirmation_draftCreateConfirmation__9cqOz`,
	"btnCont": `draftCreateConfirmation_btnCont__uQW82`
};
export default ___CSS_LOADER_EXPORT___;
