import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './pricingupdate.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdatePricingTitle = ({templateId, pageViewMode}) => {
  const [initialValue, setInitialValue] = useState();
  const [viewDiscard, setViewDiscard] = useState(false);
  const [pricingTitle, setPricingTitle] = useState([]);
  const [mode, setMode] = useState('');
  
  useEffect(() => {
    fetch(APIConstant.getPricingTitle, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ templateId : templateId})
        }
      )
        .then(res => res.json())
        .then((data) => {
          setPricingTitle(data)
          if(data.length > 0){
            setMode('update')
            setInitialValue({
              title: data[0].title,
              description: data[0].description,
              currency: data[0].currency,
              status: data[0].status,
              visibility: data[0].visibility
            })
          }else{
            setMode('add')
            setInitialValue({
              title: '',
              description: '',
              currency: '',
              status: '',
              visibility: ''
            })
          }
          
    })
	.catch(err => console.log(err))
  }, [])
  
  const handleOnFormChange = () => {
    setViewDiscard(true)
  };
  const changeMode = () => {
    pageViewMode('preview')
  }
  return (
    <div className={styles.updatePayStack}>
      {console.log('mode', mode, templateId)}
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.templateId = templateId
                    if(mode === 'add'){
                      axios.put(APIConstant.addPricingTitle, values)
                      .then(res => {
                        if(res.data.message === 'success'){
                          // setShowLoader(false)
                          pageViewMode('preview')
                        }
                      })
                      .catch(err => console.log(err))
                    }else{
                      axios.put(APIConstant.updatePricingTitle, values)
                      .then(res => {
                        if(res.data.message === 'success'){
                          // setShowLoader(false)
                          pageViewMode('preview')
                        }
                      })
                      .catch(err => console.log(err))
                    }
                    
                  }}
                  
                enableReinitialize
                >
                <Form onChange={handleOnFormChange}>
                    <header className={styles.formHeader}>
                      <h5>Edit Heading Text</h5>

                      <div className={styles.btnContainer}>
                        {viewDiscard &&
                          <button className={styles.discard} variant="contained" type="button" onClick={() => {changeMode('preview')}}>Discard</button>
                        }
                        
                        {mode === 'add'
                          ? <button className={styles.primary} variant="contained" type='submit'>Add</button>
                          : <button className={styles.primary} variant="contained" type='submit'>Update</button>
                        }
                      </div>
                    </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            <Field id="description" name="description"  />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="currency"> Currency</label>
                            <Field id="currency" name="currency"  />
                        </div>
                        
                    </div>
                
                </Form>
            </Formik>


    </div>
  )
}

export default UpdatePricingTitle