// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lircTwoColPreview_sectionBtns__UUOoc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lircTwoColPreview_sectionBtns__UUOoc li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
}
.lircTwoColPreview_sectionBtns__UUOoc li button.lircTwoColPreview_discard__ndORZ, .lircTwoColPreview_sectionBtns__UUOoc li button.lircTwoColPreview_edit__2NMqr {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.lircTwoColPreview_sectionBtns__UUOoc li button.lircTwoColPreview_primary__aDn9P {
  background-color: #398378;
  color: #FFFFFF;
}

.lircTwoColPreview_previewContainer__2EH5a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
}
.lircTwoColPreview_previewContainer__2EH5a > header {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/lircTwoColPreview/lircTwoColPreview.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AAAY;EACI,yBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,cAAA;AAEhB;;AAGA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;AAAJ;AACI;EACI,aAAA;EACA,mBAAA;AACR","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n    & > header{\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `lircTwoColPreview_sectionBtns__UUOoc`,
	"discard": `lircTwoColPreview_discard__ndORZ`,
	"edit": `lircTwoColPreview_edit__2NMqr`,
	"primary": `lircTwoColPreview_primary__aDn9P`,
	"previewContainer": `lircTwoColPreview_previewContainer__2EH5a`
};
export default ___CSS_LOADER_EXPORT___;
