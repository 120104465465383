import React from 'react'


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import UpdatePlatformsTitle from './UpdateTitle';
import PlatformSubSections from './SubSection';

const UpdatePlatforms = ({template, getChildTabSelected}) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);;
    getChildTabSelected(newValue);
   
  };    
  return (
    <Box sx={{ width: '100%', typography: 'body1', marginTop: '15px' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Heading" value="1" />
            <Tab label="Sub-Section" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <UpdatePlatformsTitle templateId={template}/>
        </TabPanel>
        <TabPanel value="2">
          
            <PlatformSubSections templateId={template}/>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default UpdatePlatforms