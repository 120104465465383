import logo from './logo.svg';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/router';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';

function App() {

  return (
  
    <div className="App">

      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
