import React from 'react'
import styles from './deleteConfirmationDialog.module.scss'
import images from '../../../assets/images/images'

const DeleteConfirmationDialog = ({ message, getConfirmation }) => {
    const confirmDelete = (conval) => {
        getConfirmation(conval)
    }
  return (
    <div className={styles.deleteDialog}>
        <div className={styles.icon}>
            <img src={images.CLOSE} alt="delete" width="50" />
        </div>
        <h2>{message.title}</h2>
        <p>{message.description}</p>
        <div className={styles.btnContainer}>
            <button className={styles.btnCancel} onClick={() => confirmDelete(false)}>Cancel</button>
            <button className={styles.btnDelete} onClick={() => confirmDelete(true)}>Delete</button>
        </div>
    </div>
  )
}

export default DeleteConfirmationDialog