// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewRRR_sectionBtns__xT1XH {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.previewRRR_sectionBtns__xT1XH li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
}
.previewRRR_sectionBtns__xT1XH li button.previewRRR_discard__QQ760, .previewRRR_sectionBtns__xT1XH li button.previewRRR_edit__S34ah {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.previewRRR_sectionBtns__xT1XH li button.previewRRR_primary__oK-x6 {
  background-color: #398378;
  color: #FFFFFF;
}

.previewRRR_previewContainer__tv-S0 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
}
.previewRRR_previewContainer__tv-S0 > header {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/RRR/previewRRR.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AAAY;EACI,yBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,cAAA;AAEhB;;AAGA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;AAAJ;AACI;EACI,aAAA;EACA,mBAAA;AACR","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n    & > header{\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `previewRRR_sectionBtns__xT1XH`,
	"discard": `previewRRR_discard__QQ760`,
	"edit": `previewRRR_edit__S34ah`,
	"primary": `previewRRR_primary__oK-x6`,
	"previewContainer": `previewRRR_previewContainer__tv-S0`
};
export default ___CSS_LOADER_EXPORT___;
