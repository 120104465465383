import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import styles from './login.module.scss';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import images from '../../../assets/images/images';

const Login = () => {
  const [loginError, setloginError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem("accessid")){
        navigate('/admin/organization')
    }
  }, [])

  return (
    <div className={styles.loginCont}>
      <div className={styles.banner}><img src={images.LOGO} width="200" alt="Logo"/></div>
      <div className={styles.loginWrapper}>
        <div className={styles.loginBox}>
          <h3>
            Login
          </h3>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async (values) => {
              // if(values.username === 'admin' && values.password === 'admin'){
              //   navigate('/admin/organization')
              // }

              axios.post(APIConstant.loginCms, values)
              .then(res => {
                if(res.data.message === 'success'){
                  sessionStorage.setItem("accessid", res.data.accessid);
                  navigate('/admin/organization')
                }else{
                  setloginError(res.data.data)
                }
              })
              .catch(err => console.log(err))

            }}
          >
            <Form>
              <div className={styles.fieldCont}>
                <label htmlFor="username">Username</label>
                <Field id="username" name="username" />
              </div>
              <div className={styles.fieldCont}>
                <label htmlFor="password">Password</label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                />
              </div>
              {loginError !== '' &&
                <p className={styles.err}>{loginError}</p>
              }
              
              <div className={styles.fieldCont}>
                <button type="submit">Submit</button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login