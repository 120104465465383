import React, { useEffect, useState } from 'react'
import styles from './addBanner.module.scss'
import { Field, Formik, Form } from 'formik';
import axios from 'axios';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../../../FileUpload/FileUpload';
import images from '../../../../../assets/images/images';

import {APIConstant} from '../../../../../services/APIConstant'
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';

const AddBanner = ({ mode, selected, templateId, closeDialog }) => {
  const [initialValue, setInitialValue] = useState({})
  const [imgPath1, setImagePath1] = useState()
  const [open, setOpen] = useState(false);
  const [banners, setBanners] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [descriptionData, setDescriptionData] = useState('');
  const [titleData, setTitleData] = useState('');
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(mode === 'Update'){
      // setInitialValue({
      //   title: 'hhjghjg',
      //   description: 'jhjkhjk',
      //   align_type: 'jkhkjhkjh',
      // })
      axios.post(APIConstant.getSelectedHomeBanner, { bannerId : selected })
      .then(res => {
        
          setInitialValue({
            title: res.data[0].title,
            description: res.data[0].description,
            align_type: res.data[0].align_type,
          })
          setImagePath1(res.data[0].img_path)
          setDescriptionData(res.data[0].description)
          setTitleData(res.data[0].title)
      })
      .catch(err => console.log(err))
      setEnableSubmit(false)
    }
    if(mode === 'Add'){
      setInitialValue({
        title: '',
        description: '',
        align_type: '',
        templateID : templateId
      })
      setEnableSubmit(true)
    }
    
  }, [mode])
  useEffect(() => {
    
    axios.post(APIConstant.getHomeBannersWithId, { template : templateId })
      .then(res => {
        setBanners(res.data)
      })
      .catch(err => console.log(err))
  }, [])
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const descriptionTextEditorData = (descriptionData) => {
    setDescriptionData(descriptionData)
  }
  const titleTextEditorData = (titleData) => {
    setTitleData(titleData)
  }
  
  const getImagePath = (data) => {
    setImagePath1(data)
    setEnableSubmit(false)
  }
 
  return (
    <div className={styles.addIntToolContainer}>
       
        
        <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      message="Submited Successfully"
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      action={action}
                    />
         <Formik
          
                initialValues={ initialValue }
                onSubmit={async (values, { resetForm }) => {
                    // await new Promise((r) => setTimeout(r, 500));
                    values.templateID = templateId
                    values.description = descriptionData;
                    values.title = titleData;
                    // values.imgPath = imgPath1
                    if(imgPath1 !== undefined){
                        values.imgpath = imgPath1
                      }
                      // console.log(APIConstant.addHomeBanners)
                      
                      if(mode === 'Add'){
                        console.log('Values', values)
                        values.sequenceId = banners.length + 1;
                        
                        axios.post(APIConstant.addHomeBanners, values)
                        .then(res => {
                          handleClick()
                          values = {}
                          
                        })
                        .catch(err => console.log(err))
                      }
                      if(mode === 'Update'){
                        setEnableSubmit(false)
                        values.selectedRecord = selected
                        axios.put(APIConstant.updateHomeBanner, values)
                        .then(res => {
                          console.log('done')
                        })
                        .catch(err => console.log(err))
                      }
                      resetForm();
                      closeDialog()

                }}
                enableReinitialize
                >
                  {({ values }) => (
                <Form>
                  <div className={styles.titleBar}>
                    <h4>{mode} Banners</h4>
                      <div className={styles.btnContainer}>
                        <button variant="contained"  className={styles.borderedBtn}>Discard</button>
                        <button variant="contained" type='submit' className={styles.primaryBtn} disabled={enableSubmit}>Save</button>
                    </div> 
                  </div>
                    <div className={styles.formContainer}>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="toolname">Title</label>
                            {/* <Field as='textarea' id="title" name="title" /> */}
                            <RichTextEditor getChildData={titleTextEditorData} parentData={titleData}/>
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="description">Description</label>
                            {/* <Field id="description" name="description" /> */}
                            <RichTextEditor getChildData={descriptionTextEditorData} parentData={descriptionData}/>
                        </div>
                        <div className={styles.fieldContainer}>
                            <label htmlFor="toolname">Alignment Type</label>
                            <div className={styles.alignmentType}>
                              <div role="group" aria-labelledby="my-radio-group" className={styles.radioGroup}>
                                <label className={values.align_type === 'LImg_RTxt' ? `${styles.active}`: ''}>
                                  <Field type="radio" name="align_type" value="LImg_RTxt" />
                                  <img src={images.LIRT} alt="alignment01" />
                                </label>
                                <label className={values.align_type === 'LTxt_RImg' ? `${styles.active}`: ''}>
                                  <Field type="radio" name="align_type" value="LTxt_RImg" />
                                  <img src={images.LTRI} alt="alignment02" />
                                </label>
                                {/* <div>Picked: {values.alignment}</div> */}
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.uploadImageWrapper}>
                      <h4>Banner Image</h4>
                      <div className={styles.bannerImages}>
                          <div className={styles.bannerUploadSec}>
                            {/* <FileUpload setImagePath={setImagePath1} mode={mode} /> */}
                            <FileUpload setImagePath={getImagePath} mode={mode} />
                          </div>
                      </div>
                    </div>
                    
                
                </Form>
                )}
            </Formik>
                    
    </div>
  )
}

export default AddBanner