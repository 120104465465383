import React, { useEffect, useLayoutEffect, useState } from 'react'
import styles from './navbar.module.scss'
import Images from '../../../assets/images/images'
import { NavLink } from 'react-router-dom'
import images from '../../../assets/images/images'

const Navbar = () => {
    const [positionY, getPositionY] = useState(0);
    const handleScroll = () => {
        getPositionY(window.scrollY)
    }
    useEffect(() => {
        if(positionY < 100){
            window.addEventListener('scroll', handleScroll)
        }
        else{
            window.removeEventListener('scroll', handleScroll)
        }
    }, [positionY])

  return (
    <div className={styles.Navbar}>
        {/* <span style={{color: '#FF0000', position: 'fixed'}}>{positionY}</span> */}
        <div className={`container ${styles.navContainer}`}>
            <div className={`flex flex-justify-spacebetween flex-align-center ${styles.light_bg}`}>
                <div className='flex-row'>
                    <img src={Images.LOGO} alt="Logo" height="48" style={{marginLeft: '10px', marginTop: '10px'}}/>
                </div>
                <div className={`flex-row flex ${styles.rMenu}`}>
                    <ul className={`${styles.nav_link} ${styles.staticNavLink}`}>
                        {/* <li className={styles.homelink}><NavLink to="/" className={`nav-link`}>Home</NavLink></li> */}
                        {/* <li className={styles.homeicon}><NavLink to="/" className={`nav-link`}><img src={Images.HOMEACTIVE} alt="Home" width="20"/></NavLink></li> */}
                        {/* <li><NavLink to="/" className="nav-link">Aboutus</NavLink></li> */}
                        <li className={styles.devLink}><a href="http://api.payorc.com/" target="_blank" >Developers</a></li>
                            <li className={styles.devIcon}><a href="http://api.payorc.com/" target="_blank" ><img src={Images.DEVELOPER} alt="Developer" width="24"/></a></li>
                        <li className={styles.loginLink}><a href="https://merchant.payorc.com/console/login" target="_blank">Login</a></li>
                        <li className={styles.loginIcon}><a href="https://merchant.payorc.com/console/login" target="_blank"><img src={Images.LOGIN} alt="Developer" width="24"/></a></li>
                        <li className={styles.signupButton}><a href="https://merchant.payorc.com/console/merchant-signup" target="_blank" className={`nav-link ${styles.btn}`}>Sign Up</a></li>
                    </ul>
                    <ul className={styles.mapIcons}>
                        <li><img src={images.UAE} alt="uae" width="26"/></li>
                        <li><img src={images.KSA} alt="uae" width="26"/></li>
                        <li><img src={images.BAHRAIN} alt="uae" width="26"/></li>
                        <li><img src={images.INDIA} alt="uae" width="26"/></li>
                        <li><img src={images.JORDAN} alt="uae" width="26"/></li>
                    </ul>
                </div>
            </div>

          
            <div className={`${styles.fixed_nav_container} fixedNav ${positionY > 100 ? 'animated_nav' : 'notanimated_nav'}`}>
                <div className={`flex flex-justify-spacebetween flex-align-center ${styles.fixed_navbar}`}>
                    <div className='flex-row'>
                        <img src={Images.LOGO_LIGHT_BG} className={styles.logo} alt="Logo" height="36"/>
                    </div>
                    <div className={`flex-row flex ${styles.rMenu}`}>
                        <ul className={styles.nav_link}>
                            {/* <li className={styles.homelink}><NavLink to="/" className={`nav-link`}>Home</NavLink></li>
                            <li className={styles.homeicon}><NavLink to="/" className={`nav-link`}><img src={Images.HOMEACTIVE} alt="Home" width="20"/></NavLink></li> */}
                            
                            {/* <li><NavLink to="/aboutus" className="nav-link">Aboutus</NavLink></li> */}
                            <li className={styles.devLink}><a href="http://api.payorc.com/" target="_blank" >Developers</a></li>
                            <li className={styles.devIcon}><a href="http://api.payorc.com/" target="_blank" ><img src={Images.DEVELOPER} alt="Developer" width="24"/></a></li>
                            <li className={styles.loginLink}><a href="https://merchant.payorc.com/console/login" target="_blank">Login</a></li>
                            <li className={styles.loginIcon}><a href="https://merchant.payorc.com/console/login" target="_blank"><img src={Images.LOGIN} alt="Developer" width="24"/></a></li>
                            <li className={styles.signUpBtn}><a href="https://merchant.payorc.com/console/merchant-signup" target="_blank" className={`nav-link ${styles.btn}`}>Sign Up</a></li>
                        </ul>
                        <ul className={styles.mapIcons}>
                            <li><img src={images.UAE} alt="uae" width="26"/></li>
                            <li><img src={images.KSA} alt="uae" width="26"/></li>
                            <li><img src={images.BAHRAIN} alt="uae" width="26"/></li>
                            <li><img src={images.INDIA} alt="uae" width="26"/></li>
                            <li><img src={images.JORDAN} alt="uae" width="26"/></li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Navbar