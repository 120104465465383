import React, { useEffect, useState } from 'react'
import styles from './pricing.module.scss';
import { APIConstant } from '../../../services/APIConstant';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Pricing = ({templateId}) => {

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [pricingValue, setPricingValue] = useState();
  const [featureList, setFeatureList] = useState();
  const [planList, setPlanList] = useState();
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [featureListDetail, setFeatureListDetail] = useState();
  
  useEffect(() => {
      fetch(APIConstant.getPricingTitle, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId : templateId})
      }
    )
        .then(res => res.json())
        .then((data) => {
          // console.log('DD', data)
          setPricingValue({
            title: data[0].title,
            description: data[0].description,
            currency: data[0].currency
          })
        })
	      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    // console.log('TempID : ', templateId)
    

      fetch(APIConstant.getPricingPlan, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId: templateId})
      }
    )
    .then(res => res.json())
    .then((data) => {
      // console.log(data)
      setPlanList(data)
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    fetch(APIConstant.getPricingFeatures, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ templateId: templateId})
        }
      )
      .then(res => res.json())
      .then((data) => {
        // console.log(data)
        setFeatureList(data)
      })
      .catch(err => console.log(err))
  }, [])

 

  // useEffect(() => {
  //   fetch(APIConstant.getPricingPlanDetails)
  //     .then(res => res.json())
  //     .then((data) => {
  //       console.log('Data : ',data)
  //       // setFeatureList(data)
  //     })
  //     .catch(err => console.log(err))
  // }, [])
  useEffect(() => {
    // alert('jkhgj')
    fetch(APIConstant.getPricingPlanDetailsWithId, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId: templateId})
      }
    )
    .then(res => res.json())
    .then((data) => {
      // console.log('feat Data : ',data)
      setFeatureListDetail(data)
    })
    .catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.pricing}>
      <div className='container'>
        <h2 className={styles.title} data-aos="fade-up">{pricingValue?.title}</h2>
        <p className={styles.subtitle} data-aos="fade-up">{pricingValue?.description}</p>
        
        {windowWidth < 600 &&
          <div className="pricingTabContainer">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} initialSelectedIndex={0}>
                    {planList?.map((item,index) => { 
                      return(
                        <Tab 
                          className={selectedTabIndex === index ? 'active' : ''} 
                          label={
                          <div className='pricingTabField'>
                            <h4>
                            {item.plan_name}
                            </h4>
                            <div className='priceVal'>
                              <h5>{pricingValue?.currency}</h5>
                              <h3>{item.plan_price}</h3>
                              <span>per {item.time_period}</span>
                            </div>
                          </div>} 
                          value={index} 
                          onClick={() => setSelectedTabIndex(index)}
                        />
                      )}
                    )}
                  </TabList>
                </Box>
              </TabContext>
            </Box>
          </div>
        }
        

        <div className={styles.grid_cont}>
          <div className={styles.gridHeading}  data-aos="fade-right" data-aos-duration="500" data-aos-delay="250">
            <div className={`${styles.pricingHead}`}></div>
            <ul className={styles.pList}>
                {featureList?.map((item,index)=>{
                  return(
                    <li><h5>{item.title}</h5></li>
                  )}
                )}
            </ul>
          </div>
                {/* {console.log('planList', planList)} */}
          {planList?.map((item,index) => { 
            if(windowWidth < 600){
              if(selectedTabIndex === index){
                return(
                  <div className={styles.plancont}  data-aos="fade-up" data-aos-duration="500" data-aos-delay="50">
                <div className={`${styles.pricingHead} ${styles.head01}`}>
                  <h3>{item.plan_name}</h3>
                  <ul>
                    <li>
                      <span>{pricingValue?.currency}</span>
                      <strong>{item.plan_price}</strong>
                      <p>/ {item.time_period}</p>
                    </li>
                  </ul>
                </div>
               {/* {console.log('jkj', featureListDetail, item)} */}
                <ul className={`${styles.pList} ${styles.showMobileOnly}`}>
                    {featureList?.map((item,index)=>{
                      return(
                        <li><h5>{item.title}</h5></li>
                      )}
                    )}
                </ul>
                <ul className={`${styles.pList} ${styles.mobileRight}`} >
                  {featureListDetail?.map((featuresitem, ind) => {
                    return(
                      <>
                      { item.plan_id === featuresitem.plan_id &&
                        <li><p>{featuresitem.feature_value}</p></li>
                      }
                      </>
                    )
                  })}
                    
                  
                </ul>
              </div>
                )
              }
              
            }
            else{
            return(
              // style={{ backgroundColor: item.color_code }} 
              <div className={styles.plancont}  data-aos="fade-up" data-aos-duration="500" data-aos-delay="50">
                <div className={`${styles.pricingHead} ${styles.head01}`}>
                  <h3>{item.plan_name}</h3>
                  <ul>
                    <li>
                      <span>{pricingValue?.currency}</span>
                      <strong>{item.plan_price}</strong>
                      <p>/ {item.time_period}</p>
                    </li>
                  </ul>
                </div>
               {/* {console.log('jkj', featureListDetail, item)} */}
                <ul className={`${styles.pList} ${styles.showMobileOnly}`}>
                    {featureList?.map((item,index)=>{
                      return(
                        <li><h5>{item.title}</h5></li>
                      )}
                    )}
                </ul>
                <ul className={`${styles.pList} ${styles.mobileRight}`} >
                  {featureListDetail?.map((featuresitem, ind) => {
                    return(
                      <>
                      { item.plan_id === featuresitem.plan_id &&
                        <li><p>{featuresitem.feature_value}</p></li>
                      }
                      </>
                    )
                  })}
                    
                  
                </ul>
              </div>
            )}
          }
          )}

          
        </div>
      </div>
    </div>
  )
}

export default Pricing