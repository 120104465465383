// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewNoIntegration_sectionBtns__txGBd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -6px !important;
}
.previewNoIntegration_sectionBtns__txGBd li button {
  border: none;
  border-radius: 5px;
  height: 30px;
}
.previewNoIntegration_sectionBtns__txGBd li button.previewNoIntegration_discard__O4-Cl, .previewNoIntegration_sectionBtns__txGBd li button.previewNoIntegration_edit__k\\+zOT {
  background-color: #FFFFFF;
  border: 1px solid #398378;
  padding: 5px 20px;
}
.previewNoIntegration_sectionBtns__txGBd li button.previewNoIntegration_primary__4a3Lb {
  background-color: #398378;
  color: #FFFFFF;
  padding: 5px 20px;
}
.previewNoIntegration_sectionBtns__txGBd li button.previewNoIntegration_delete__OzGED {
  background-color: rgba(242, 79, 79, 0.1);
  border: 1px solid #F24F4F;
}

.previewNoIntegration_previewContainer__A\\+quu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.02);
}
.previewNoIntegration_previewContainer__A\\+quu header {
  padding: 5px 10px;
}

.previewNoIntegration_discard__O4-Cl {
  position: absolute;
  top: 44px;
  right: 60px;
}

.previewNoIntegration_preview__cR5HQ {
  position: absolute;
  top: -48px;
  right: -43px;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/nointegration/previewNoIntegration.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;AACJ;AACQ;EAEI,YAAA;EACA,kBAAA;EAEA,YAAA;AADZ;AAEY;EACI,yBAAA;EACA,yBAAA;EACA,iBAAA;AAAhB;AAEY;EACI,yBAAA;EACA,cAAA;EACA,iBAAA;AAAhB;AAEY;EACI,wCAAA;EACA,yBAAA;AAAhB;;AAKA;EACI,oCAAA;EAEA,YAAA;EACA,kBAAA;EACA,qCAAA;AAHJ;AAII;EACI,iBAAA;AAFR;;AAMA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AAHJ;;AAKA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AAFJ","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    position: relative;\n    top: -6px !important;\n    li{\n        button{\n            \n            border: none;\n            border-radius: 5px;\n           \n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n                padding: 5px 20px;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n                padding: 5px 20px;\n            }\n            &.delete{\n                background-color: rgba(242, 79, 79, 0.1);\n                border: 1px solid #F24F4F;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    // padding: 10px;\n    margin: 10px;\n    position: relative;\n    background-color: rgba(0,0,0,0.02);\n    header{\n        padding: 5px 10px;\n    }\n}\n\n.discard{\n    position:absolute;\n    top: 44px;\n    right: 60px;\n}\n.preview{\n    position:absolute;\n    top: -48px;\n    right: -43px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `previewNoIntegration_sectionBtns__txGBd`,
	"discard": `previewNoIntegration_discard__O4-Cl`,
	"edit": `previewNoIntegration_edit__k+zOT`,
	"primary": `previewNoIntegration_primary__4a3Lb`,
	"delete": `previewNoIntegration_delete__OzGED`,
	"previewContainer": `previewNoIntegration_previewContainer__A+quu`,
	"preview": `previewNoIntegration_preview__cR5HQ`
};
export default ___CSS_LOADER_EXPORT___;
