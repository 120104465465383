// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamicPage_dynamicPageCont__09PcT .dynamicPage_pageContainer__r6CWL .dynamicPage_heading__B26L0 {
  background-color: #F8F8F8;
  text-align: center;
  padding: 50px 0px;
}
.dynamicPage_dynamicPageCont__09PcT .dynamicPage_pageContainer__r6CWL .dynamicPage_heading__B26L0 h4 {
  font-size: 34px;
  color: rgb(57, 131, 120);
}
.dynamicPage_dynamicPageCont__09PcT .dynamicPage_pageContainer__r6CWL .dynamicPage_contentWrapper__9Quwe {
  max-width: 90%;
  margin: 0px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/dynamicPage/dynamicPage.module.scss"],"names":[],"mappings":"AAGQ;EACI,yBAAA;EACA,kBAAA;EACA,iBAAA;AAFZ;AAGY;EACI,eAAA;EACA,wBAAA;AADhB;AAIQ;EACI,cAAA;EACA,gBAAA;AAFZ","sourcesContent":[".dynamicPageCont{\n    .pageContainer{\n       \n        .heading{\n            background-color: #F8F8F8;\n            text-align: center;\n            padding: 50px 0px;\n            h4{\n                font-size: 34px;\n                color: rgb(57, 131, 120);\n            }\n        }\n        .contentWrapper{\n            max-width: 90%;\n            margin: 0px auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamicPageCont": `dynamicPage_dynamicPageCont__09PcT`,
	"pageContainer": `dynamicPage_pageContainer__r6CWL`,
	"heading": `dynamicPage_heading__B26L0`,
	"contentWrapper": `dynamicPage_contentWrapper__9Quwe`
};
export default ___CSS_LOADER_EXPORT___;
