import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import { APIConstant } from '../../../../../services/APIConstant';
import images from '../../../../../assets/images/images';
import styles from './roundrobinribbon.module.scss'

import BannerImg from '../../../../../assets/images/homeBanner001.webp'
// import AddPaymentList from './AddPaymentList'
import axios from 'axios';
import AddImageRRR from './addImageRRR';

const ImageRRR = ({templateId}) => {
    const [dataList, setDataList] = useState([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
   
    const [activeItem, setActiveItem] = useState('');

    const handleAddDialogOpen = (modeval, id) => {
       
        setOpenAddDialog(true);
    };
    const handleAddDialogClose = () => {
        setOpenAddDialog(false);
    };

    const deleteList = (id) => {
        axios.post(APIConstant.deleteImageRRRWithId, {id})
        .catch(err => console.log(err))
    }
    
    useEffect(() => {
        fetch(APIConstant.getImageRRR, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            setDataList(data)
        })
        .catch(err => console.log(err))
    }, [])
    const columns = [
        {
            headerName: 'Image',
            field: 'img_path',
            width: 120,
            renderCell: (params) => (
                <div className={styles.imgCont}>
                    <img src={params.row.img_path} alt="Banner" height='40'/>
                </div>
            )
        },
        { field: 'title', headerName: 'Title', width: 300 },
        
        {
            field: 'actions',
            headerName: '',
            renderCell: (item) => (
                <div className={styles.actionBtns}>
                    <ul>
                        <li><a className={styles.delete} onClick={() => deleteList(item.row.id)}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                    </ul>
                </div>
            )
        },
    ];

    return (
        <div className={styles.bannerList}>
            <div style={{ height: 'auto', width: '100%' }} className="customDataGrid">
                <DataGrid
                    rows={dataList}
                    columns={columns}
                    getRowId={(row) => row.id}
                />
            </div>
            <div className="gridBtnCont">
                <button onClick={() => handleAddDialogOpen('add')}>Add New</button>
            </div>
            <Dialog open={openAddDialog} onClose={handleAddDialogClose} >
                <AddImageRRR templateId={templateId}/>
            </Dialog>
        </div>
    )
}

export default ImageRRR