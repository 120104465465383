import React, { useEffect, useState } from 'react'
import styles from './banner.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const Banner = ({template, sequenceNumber}) => {
  const [sliderImg, setSliderImg] = useState(1);
  const [sliderList, setSliderList] = useState([])
  const [playState, setPlayState] = useState(false)
  const [animateTime, setAnimateTime] = useState(7)
  const [intervalState, setIntervalState] = useState(null)

  useEffect(() => {
    // console.log('Banner')
    axios.post(APIConstant.getHomeBannersWithId, {template: template})
    .then(res => {  
      setSliderList(res.data)
    })
    .catch(err => console.log(err))
  }, [])

  const startAnimation = () => {
    const interval = setInterval(() => {
        if(sliderImg <= sliderList?.length){
            if(sliderImg === sliderList?.length){
                setSliderImg(1)
            }
            else{
                setSliderImg(sliderImg + 1)
            }
        }
    }, animateTime*1000);
    setIntervalState(interval)
  }
  useEffect(() => {
    startAnimation()
    return () => {
        clearInterval(intervalState);
    };
  }, [sliderImg, sliderList])
  const pauseAnimation = (status, ind) => {
    // ${playState ? styles.paused : ''}
    clearInterval(intervalState);
    if(status){
        setAnimateTime(7)
        
        setSliderImg(ind)
    }else{
        setAnimateTime(7)
        startAnimation()
    }
    setPlayState(status)
  }
  
//   const showActiveBanner = (activebanner) => {
//     setSliderImg(activebanner + 1)
//   }

  return (
    <div className={styles.banner_container}>
        {/* {console.log('Lists : ', sliderImg)} */}
        <div className='container' style={{position: 'relative', height: '100vh'}}>
            {sliderList?.map((item, index) => {
                return (
                    <div key={index} style={{position: 'absolute', width: '100%'}} className={styles.bannerCont}>
                        
                    {sliderImg === index+1 &&
                        <div className={styles.banner_wrapper} >
                           {(item.title !== '<p><br></p>' || item.description !== '<p><br></p>') &&
                              <div className={styles.banner_content} style={{order: item.align_type === 'LImg_RTxt' ? '1' : '2'}}  >
                                  <div className={`${styles.fadeDown} ${playState ? styles.paused : ''}`} style={{animationDuration: `${animateTime}s`}} dangerouslySetInnerHTML={ {__html: item.title} }/>
                                  <div className={`${styles.fadeUp} ${styles.bannerDescription} ${playState ? styles.paused : ''}`} style={{animationDuration: `${animateTime}s`}} dangerouslySetInnerHTML={ {__html: item.description} }/>
                              </div>
                           }
                            <div className={`${styles.banner_img} ${styles.fadeIn} ${playState ? styles.paused : ''}`} style={{order: item.align_type === 'LImg_RTxt' ? '2' : '1', animationDuration: `${animateTime}s`, width : (item.title !== '<p><br></p>' && item.description !== '<p><br></p>') ? '50%': '100%'}} >
                                <img src={item.img_path} alt="Banner" />
                            </div>
                        </div>
                    }
                    </div>
                )}
            )}
            <ul className={styles.bannerBullets}>
            {sliderList?.map((item, index) => {
                return (
                  
                            <li className={sliderImg === index+1 ? `${styles.active}`: ''}  onMouseEnter={() => pauseAnimation(true, index + 1)} onMouseLeave={() => pauseAnimation(false, index + 1)}></li>
                       
                )}
            )}
            </ul>
        </div>
    </div>
  )
}

export default Banner

