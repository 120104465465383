import React, { useState } from 'react'
import styles from './previewLCRI.module.scss';
import { Dialog, Switch } from '@mui/material';
import LCRI from '../../home/lcri/lcri';
import images from '../../../assets/images/images';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import EditLCRI from '../../admin/home/sections/lcri/editLcri';
import DeleteConfirmationDialog from '../../dialog/deleteConfirmationDialog/deleteConfirmationDialog';
import { TextConstant } from '../../../constant/common';
import DeleteToaster from '../../toaster/delete/deleteToaster';

const PreviewLCRI = ({templateId}) => {
  const [checked, setChecked] = useState();
  const [viewMode, setViewMode] = useState('preview');

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);  
  const [openDeleteToaster, setOpenDeleteToaster] = useState(false);

  const handleOpenDeleteConform = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseDeleteConform = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if (checked) {
      visbility = 'hide'
    }
    else {
      visbility = 'active'
    }
  };
  const deleteTemplate = () => {
    axios.post(APIConstant.deleteTemplate, {templateId : templateId})
    .then(res => {
      setOpenDeleteToaster(true)
    })
    .catch(err => console.log(err))
  }

  const confirmValue = (res) => {
    if(res){
      deleteTemplate()
      handleCloseDeleteConform()
    }else{
      handleCloseDeleteConform()
    }
  }
  const changeViewMode = (viewMode) => {
    setViewMode(viewMode)
  }
  return (
    <div className={`${styles.platformPreviewCont} ${styles.previewContainer}`}>
      <header>
        <h5>LCRI</h5>
        <ul className={styles.sectionBtns}>
            {/* <li>
            <div className="customeSwitch">
                <Switch
                checked={checked}
                onChange={handleVisibilityChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />
                <span className={checked ? 'active switchlabel' : 'hide switchlabel'}>{checked ? 'Active' : 'Hide'}</span>
            </div>
            </li> */}
            {viewMode === 'preview' &&
              <>
                <li>
                  <button className={styles.delete} onClick={handleOpenDeleteConform}>
                    <img src={images.DELETE} width="20" title="Delete" alt="delete" />
                  </button>
                </li>
                <li>
                    <button className={styles.edit} onClick={() => setViewMode('edit')}>Edit</button>
                </li>
              </>
            }
            {viewMode === 'edit' &&
                <>
                  <li style={{position:'absolute', top: '42px', right: '108px'}}>
                    <button className={styles.discard} onClick={() => setViewMode('preview')}>Discard</button>
                  </li>
                </>
            }
        </ul>
        </header>
        {viewMode === 'preview' &&
            <LCRI templateId={templateId}/>
        }
        {viewMode === 'edit' &&
           <EditLCRI templateId={templateId} pageViewMode={changeViewMode}/>
        }

        {/* Dialog */}
        <Dialog open={openConfirmDeleteDialog} onClose={handleCloseDeleteConform}>
          <DeleteConfirmationDialog message={{title:TextConstant.AreYouSure, description: TextConstant.DeleteConfirmMessage}} getConfirmation={confirmValue}/>
        </Dialog>

        {/* Toaster */}
        {openDeleteToaster &&
          <DeleteToaster/>
        }
    </div>
  )
}

export default PreviewLCRI