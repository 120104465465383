import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { APIConstant } from '../../services/APIConstant';
import images from '../../assets/images/images';
import styles from './CustomDragAndDrop.module.scss';

  

const Dnd = ({widget, parentCallback, pageId}) => {
    

    const [items, setItems] = useState([]);
    const [loader, setLoader] = useState(false);




const pageTemplates = async () => {
  await axios.post(APIConstant.getPageTemplatesWithID, { pageID: pageId})
  .then(res => {
    setItems(res.data);
    
    return res.data;
  })
  .catch(err => console.log(err))
  setLoader(false)
}

const reorderTemplates = (result) => {
  setLoader(true)
  axios.put(APIConstant.reorderPageTemplates, result)
  .then(res => {
    if(res){
      pageTemplates()
    }
  })
  .catch((err) => {
    setLoader(false)
    console.log(err)
  })
    
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  console.log('xyz',list, startIndex, endIndex)
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // console.log('res', result)

    reorderTemplates(result)
   
    // console.log(result)
    return result;
   
  };
  
  const grid = 8;
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "#FFFFFF",
    ...draggableStyle,
  });
  
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "blue" : "transparent",
    padding: 5,
    width: 220,
  });










  useEffect(() => {
    console.log('widget work', widget)
    // if(widget.length > 0){
      setItems(widget);
    //   console.log('WIDGET :', widget)
    // }
    // else{
    //   axios.post(APIConstant.getPageTemplatesWithID, { pageID: pageId})
    //   .then(res => {
    //     setItems(res.data);
    //   })
    //   .catch(err => console.log(err))
    // }
  }, [widget]);


  // useEffect(() => {
    
  //   setItems(widget);
    
  // }, [widget]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    
    // console.log({ reorderedItems });
    setItems(reorderedItems);
    parentCallback(items)
  };


  return (
<>

    <div className={styles.dndContainer}>
      {/* {console.log('items :', items)} */}
        {/* {widget.map((Widget, index) => (
                  <div className='dropped-widget' key={index}>
                    {Widget.name}
                  </div>
                ))} */}

{/* {console.log('Item', items)} */}
{loader &&
  <div className={styles.loaderCont}>
    <img src={images.LOADER} alt="Loader" width="100" />
  </div>
}


<DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="dragCont"
            >
              {items.map((item, index) => (
                <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="card"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {/* {console.log('item', item)} */}
                      {item.name === 'banner' && <img src={images.BANNER} alt="Icon" width="50" />}
                      {item.name === 'journey' && <img src={images.JOURNEY} alt="Icon" width="50" />}
                      {item.name === 'portfolio' && <img src={images.PORTFOLIO} alt="Icon" width="50" />}
                      {item.name === 'no-integration' && <img src={images.OPTIMIZE} alt="Icon" width="50" />}
                      {item.name === 'itegration' && <img src={images.INTEGRATION} alt="Icon" width="50" />}
                      {item.name === 'pricing' && <img src={images.PRICING} alt="Icon" width="50" />}
                      {item.name === 'LIRC' && <img src={images.BANNER} alt="Icon" width="50" />}
                      {item.name === 'LCRI' && <img src={images.BANNER} alt="Icon" width="50" />}
                      {item.name === 'LIRC2C' && <img src={images.PLATFORM} alt="Icon" width="50" />}
                      {item.name === 'RRR' && <img src={images.PORTFOLIO} alt="Icon" width="50" />}
                      {item.name === 'processor' && <img src={images.PORTFOLIO} alt="Icon" width="50" />}

                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

    </div>
    </>
  )
}

export default Dnd