import React, { useEffect, useState } from 'react';
import styles from './lirc-two-col.module.scss';
import Images from '../../../assets/images/images';
import { APIConstant } from '../../../services/APIConstant';

const LircTwoColumn = ({template}) => {
  const [dataTitle, setDataTitle] = useState([]);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    fetch(APIConstant.getLircTwoColTitle, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId : template})
    })
    .then(res => res.json())
    .then((data) => {
        console.log('Data',data)
        setDataTitle(data)
    })
    .catch(err => console.log(err))
}, [])

useEffect(() => {
  fetch(APIConstant.getLircTwoColList, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : template})
  })
  .then(res => res.json())
  .then((data) => {
      console.log('Data',data)
      setDataList(data)
  })
  .catch(err => console.log(err))
}, [])
  return (
    <div className={styles.lircTwoColumnCont}>
      <div className='container'>
        <div className={styles.secWrapper}>
          <section className={`${styles.secCont} ${styles.imgCont}`}>
            <img src={dataTitle[0]?.img_path} alt="Laptop Banner" />
          </section>
          <section className={`${styles.secCont} ${styles.txtCont}`}>
            
            <h3>{dataTitle[0]?.title}</h3>
            <p>{dataTitle[0]?.description}</p>
            <div className={styles.gridCont}>
              {dataList?.map((item, index) => {
                return(
                  <div className={styles.gridCol}>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                )}
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default LircTwoColumn