import React from 'react'
import styles from './home.module.scss'
import { NavLink, Outlet } from 'react-router-dom'

const HomeUpdate = () => {
  return (
    <div className={styles.homeWrapper}>
      <h2>Home Page</h2>
    <div className={styles.homeContainer}>
      <div className={styles.menu}>
        <ul>
          {/* <li className={styles.active}>
            <NavLink to="/admin/home/nav" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>NavBar</NavLink></li> */}
          <li>
          <NavLink to="/admin/home/banner" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Banner</NavLink>
          </li>
          <li>
            <NavLink to="/admin/home/platforms" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 01</NavLink>
          </li>
          <li>
            <NavLink to="/admin/home/journey" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 02</NavLink>
          </li>
          <li>
          <NavLink to="/admin/home/paystack" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 03</NavLink>
          </li>
          <li>
          <NavLink to="/admin/home/nocode" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 04</NavLink>
          </li>
          <li>
          <NavLink to="/admin/home/integration" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 05</NavLink>
          </li>
          <li>
          <NavLink to="/admin/home/pricing" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 06</NavLink>
          </li>
          <li>
          <NavLink to="/admin/home/footer" className={({ isActive, isPending }) =>
                isPending ? styles.pending : isActive ? styles.active : ""
            }>Section 07</NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    </div>
    </div>
  )
}

export default HomeUpdate