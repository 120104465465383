// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewOptimize_sectionBtns__uGZmw {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.previewOptimize_sectionBtns__uGZmw li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
}
.previewOptimize_sectionBtns__uGZmw li button.previewOptimize_discard__2Jnrz, .previewOptimize_sectionBtns__uGZmw li button.previewOptimize_edit__0kDci {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.previewOptimize_sectionBtns__uGZmw li button.previewOptimize_primary__0975V {
  background-color: #398378;
  color: #FFFFFF;
}

.previewOptimize_previewContainer__JLo4D {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
}
.previewOptimize_previewContainer__JLo4D > header {
  display: flex;
  align-items: center;
}

.previewOptimize_discardBtn__rjs59 {
  position: relative;
}
.previewOptimize_discardBtn__rjs59 button {
  position: absolute;
  top: 77px;
  right: 105px;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/optimize/previewOptimize.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AAAY;EACI,yBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,cAAA;AAEhB;;AAGA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;AAAJ;AACI;EACI,aAAA;EACA,mBAAA;AACR;;AAEA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,YAAA;AAER","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n    & > header{\n        display: flex;\n        align-items: center;\n    }\n}\n.discardBtn{\n    position: relative;\n    button{\n        position: absolute;\n        top: 77px;\n        right: 105px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `previewOptimize_sectionBtns__uGZmw`,
	"discard": `previewOptimize_discard__2Jnrz`,
	"edit": `previewOptimize_edit__0kDci`,
	"primary": `previewOptimize_primary__0975V`,
	"previewContainer": `previewOptimize_previewContainer__JLo4D`,
	"discardBtn": `previewOptimize_discardBtn__rjs59`
};
export default ___CSS_LOADER_EXPORT___;
