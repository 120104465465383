import React from 'react'
import Switch from '@mui/material/Switch';
import Images from '../../../../../assets/images/images'
import styles from './nav.module.scss'
import Navbar from '../../../../home/navbar/navbar';

const NavUpdate = () => {
  const [checked, setChecked] = React.useState(true);
  const [viewMode, setViewMode] = React.useState('design');

  const handleChange = (event) => {
    // alert(event.target.checked)
    setChecked(event.target.checked);
  };

  return (
    <div className={styles.pageWrapper}>
      <header>
        <h3>Nav Menu</h3>
        <ul>
          <li>
            <ul>
              <li><a onClick={() => setViewMode('code')} className={ viewMode === 'code' ? styles.active : '' }><img src={Images.CODE} alt="Icon" width="22" /></a></li>
              <li><a onClick={() => setViewMode('design')} className={ viewMode === 'design' ? styles.active : '' }><img src={Images.PAGE} alt="Icon" width="16" /></a></li>
            </ul>
          </li>
          <li>
          <div className="customeSwitch">
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className={ checked ? 'active switchlabel' : 'hide switchlabel' }>{checked ? 'Active' : 'Hide'}</span>
            </div>
          </li>
          <li><button className={styles.publishBtn}>Publish</button></li>
        </ul>
      </header>
      <section className={styles.mainWrapper}>
        {viewMode === 'design' &&
          <div className={styles.design}>
            <Navbar />
          </div>
        }
        {viewMode === 'code' &&
          <div className={styles.code}>
            code
          </div>
        }
      </section>
    </div>
  )
}

export default NavUpdate