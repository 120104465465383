import React, { useEffect, useState } from 'react'
import styles from './roundrobinribbon.module.scss'
import ImageRRR from './imageRRR';
import ContentRRR from './contentRRR';
import { APIConstant } from '../../../../../services/APIConstant';

const UpdateRoundRobinRibbon = ({templateId}) => {
    const [type, setType] = useState();
    const [mode, setMode] = useState();
    const [contentDataList, setContentDataList] = useState();
    const [imageDataList, setImageDataList] = useState();
    const onTypeChange = (e) => {
        setType(e.currentTarget.value)
    }

    useEffect(() => {
        fetch(APIConstant.getContentRRR, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            setContentDataList(data.length)
            if(data.length > 0){
                setType('content')
            }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        fetch(APIConstant.getImageRRR, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            setImageDataList(data.length)
            if(data.length > 0){
                setType('image')
            }
        })
        .catch(err => console.log(err))
    }, [])
     
    
  return (
    <div className={styles.marqueeCont} style={{marginTop: '10px'}}>
        {/* {console.log('req: ',contentDataList, imageDataList)} */}

        
        {contentDataList === 0  && imageDataList === 0 &&
            <>
                <h5>Please select the RRR type</h5>
                <div className={styles.radioGroup}>
                    <input type="radio" id="html" name="fav_language" value="image" onChange={onTypeChange} />
                    <label for="html">Image RRR</label>
                    <input type="radio" id="css" name="fav_language" value="content" onChange={onTypeChange}/>
                    <label for="css">Content RRR</label>
                </div>
            </>
        }
        
            
        
        
        {type === 'image' &&
            <div className={styles.imgContainer} style={{marginTop: '10px'}}>
                <h5>Image RRR</h5>
                <ImageRRR templateId={templateId}/>
            </div>
        }
        {type === 'content' &&
            <div className={styles.contentContainer} style={{marginTop: '10px'}}>
                <h5>Text Content RRR</h5>
                <ContentRRR templateId={templateId} />
            </div>
        }
    </div>
  )
}

export default UpdateRoundRobinRibbon