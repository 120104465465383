import React, { useEffect, useState } from 'react'
import styles from './draftCreateConfirmation.module.scss'
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import { useNavigate } from 'react-router-dom';

const DraftCreateConfirmation = ({ parentDraftData, getConfirmation }) => {
  const [hasPageName, setHasPageName] = useState(false);
  const [pageMode, setPageMode] = useState(false);
  const [draftPageList, setDraftPageList] = useState([]);
  const navigate = useNavigate();

    const confirmDraft = (conval) => {
      getConfirmation(conval)
    }
    const getPageName = (mode) => {
      setHasPageName(true)
      setPageMode(mode)
    }
    const openDraftPage = (mode) => {
      console.log('Mode :', mode)
      setHasPageName(true)
      setPageMode(mode)
    }
    const createNewDraftPage = (pageName) => {
      parentDraftData.pageName = pageName
      axios.post(APIConstant.draftPage, parentDraftData)
      .then(res => {
        console.log('Res', res.data.data)
        navigate(`/admin/preview?id=${res.data.data}`)
      })
      .catch(err => console.log(err))

      confirmDraft('created')
  }

  const openSelectedDraftPage = (selectedpageid) => {
    // console.log('Selected Draft', selectedpageid)
    navigate(`/admin/preview?id=${selectedpageid}`)
    getConfirmation('Opened')
  }
    useEffect(() => {
      // console.log('ParentDraftData', parentDraftData)

      axios.post(APIConstant.getDraftPageWithGroupId, {'groupID': parentDraftData.group_id})
            .then(res => {
              if(res.data.length > 0){
                setDraftPageList(res.data)
              }else{
                
              }
            })
            .catch(err => console.log(err))

    }, [])
  return (
    <div className={styles.draftCreateConfirmation}>
      {hasPageName
        ? <>
            {pageMode === 'create' &&
              <>
                <h4>Enter Page Name</h4>
                <Formik
                  initialValues={{
                    pageName: '',
                  }}
                  onSubmit={async (values) => {
                    createNewDraftPage(values.pageName)
                  }}
                >
                  <Form>
                    <Field id="pageName" name="pageName"  />
                    <button type="submit">Submit</button>
                  </Form>
                </Formik>
              </>
            }
          {pageMode === 'open' &&
            <>
                <h4>Select Draft Page</h4>
                <Formik
                  initialValues={{
                    selectedPage: '',
                  }}
                  onSubmit={async (values) => {
                    // console.log('values', values)
                    openSelectedDraftPage(values.selectedPage)
                  }}
                >
                  <Form>
                    <Field as="select" name="selectedPage">
                      {draftPageList.map((item, index) => {
                        return (
                          <option value={item.page_id}>{item.page_name}</option>
                        )}
                      )}
                    </Field>
                    <button type="submit">Submit</button>
                  </Form>
                </Formik>
            </>
          }
        </>
        : <>
        <h5>We have already a draft page, <br/>shall we open that page?</h5>

        <div className={styles.btnCont}>
            <button onClick={() => openDraftPage('open')}>Open Draft Page</button>
            <button onClick={() => getPageName('create')}>No, Create a New</button>
        </div>
        </>
      }
        
    </div>
  )
}

export default DraftCreateConfirmation