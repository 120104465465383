// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editLirc_lircCont__GKVr3 .editLirc_formCont__uFo51 {
  width: 100%;
}
.editLirc_lircCont__GKVr3 .editLirc_formCont__uFo51 input {
  width: 100%;
  height: 36px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.editLirc_lircCont__GKVr3 .editLirc_formCont__uFo51 label {
  font-size: 13px;
  margin-bottom: 5px;
  display: block;
}
.editLirc_lircCont__GKVr3 .editLirc_formCont__uFo51 h4 {
  margin-bottom: 10px;
}

.editLirc_formHeader__Zy-Hq {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.editLirc_formHeader__Zy-Hq h5 {
  font-size: 18px;
  font-weight: 600;
}
.editLirc_formHeader__Zy-Hq .editLirc_btnContainer__uBpxr {
  display: flex;
  justify-content: flex-end;
}
.editLirc_formHeader__Zy-Hq .editLirc_btnContainer__uBpxr button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
}
.editLirc_formHeader__Zy-Hq .editLirc_btnContainer__uBpxr button.editLirc_discard__T9yBq, .editLirc_formHeader__Zy-Hq .editLirc_btnContainer__uBpxr button.editLirc_edit__9b2xv {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.editLirc_formHeader__Zy-Hq .editLirc_btnContainer__uBpxr button.editLirc_primary__OYj1b {
  background-color: #398378;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/home/sections/lirc/editLirc.module.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AACQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;AACZ;AACQ;EACI,eAAA;EACA,kBAAA;EACA,cAAA;AACZ;AACQ;EACI,mBAAA;AACZ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAEI;EACI,eAAA;EACA,gBAAA;AAAR;AAEI;EACI,aAAA;EACA,yBAAA;AAAR;AAEQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AAAZ;AACY;EACI,yBAAA;EACA,yBAAA;AAChB;AACY;EACI,yBAAA;EACA,cAAA;AAChB","sourcesContent":[".lircCont{\n    .formCont{\n        width: 100%;\n        input{\n            width: 100%;\n            height: 36px;\n            margin-bottom: 15px;\n            border-radius: 5px;\n            border: 1px solid rgba(0,0,0,0.2);\n        }\n        label{\n            font-size: 13px;\n            margin-bottom: 5px;\n            display: block;\n        }\n        h4{\n            margin-bottom: 10px;\n        }\n    }\n}\n\n.formHeader{\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n    margin-top: 10px;\n    h5{\n        font-size: 18px;\n        font-weight: 600;\n    }\n    .btnContainer{\n        display: flex;\n        justify-content: flex-end;\n        \n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lircCont": `editLirc_lircCont__GKVr3`,
	"formCont": `editLirc_formCont__uFo51`,
	"formHeader": `editLirc_formHeader__Zy-Hq`,
	"btnContainer": `editLirc_btnContainer__uBpxr`,
	"discard": `editLirc_discard__T9yBq`,
	"edit": `editLirc_edit__9b2xv`,
	"primary": `editLirc_primary__OYj1b`
};
export default ___CSS_LOADER_EXPORT___;
