import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteToaster() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    console.log('deleted')
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  React.useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    
  }, [])
  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Deleted Succefully"
        action={action}
      />
    </div>
  );
}