import React, { useEffect } from 'react'
import Switch from '@mui/material/Switch';
import Images from '../../../../../assets/images/images'
import styles from './platforms.module.scss'
import ManageandOptimize from '../../../../home/manageandoptimize/manageand_optimize';
import axios from 'axios';

import { APIConstant } from '../../../../../services/APIConstant';
import UpdatePlatforms from './UpdatePlatforms';

const Platforms = () => {
  const [checked, setChecked] = React.useState(true);
  const [viewMode, setViewMode] = React.useState('design');
  const [bannerStatus, setBannerStatus] = React.useState('');

  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if(checked){
      visbility = 'hide'
    }
    else{
      visbility = 'active'
    }
    axios.put(APIConstant.updateVisibility, {visibility_id: 2, visible_status: visbility})
    .then(res => {
      console.log(res)
    })
    .catch(err => console.log(err))
  };
  useEffect(() => {
    axios.get(APIConstant.getSectionVisibility)
    .then(res => {
      setBannerStatus(res.data.find(obj => obj.visibility_id === 2).visible_status);
    })
    .catch(err => console.log(err))

    if(bannerStatus === 'hide'){
      setChecked(false)
    }
    if(bannerStatus === 'active'){
      setChecked(true)
    }
  }, [bannerStatus])

  useEffect(() => {
    
  }, [])
  return (
    <div className={styles.pageWrapper}>
      <header>
        <h3>Platforms</h3>
        <ul>
          <li>
            <ul>
              <li><a onClick={() => setViewMode('code')} className={ viewMode === 'code' ? styles.active : '' }><img src={Images.CODE} alt="Icon" width="22" /></a></li>
              <li><a onClick={() => setViewMode('design')} className={ viewMode === 'design' ? styles.active : '' }><img src={Images.PAGE} alt="Icon" width="16" /></a></li>
            </ul>
          </li>
          <li>
          <div className="customeSwitch">
            <Switch
              checked={checked}
              onChange={handleVisibilityChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className={ checked ? 'active switchlabel' : 'hide switchlabel' }>{checked ? 'Active' : 'Hide'}</span>
            </div>
          </li>
          {/* <li><button className={styles.publishBtn}>Publish</button></li> */}
        </ul>
      </header>
      <section className={styles.mainWrapper}>
        {viewMode === 'design' &&
          <div className={styles.design}>
            <ManageandOptimize />
          </div>
        }
        {viewMode === 'code' &&
          <div className={styles.code}>
            <UpdatePlatforms />
          </div>
        }
      </section>
    </div>
  )
}

export default Platforms