// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteConfirmationDialog_deleteDialog__HnEvB {
  padding: 40px;
  width: 450px;
  max-width: 100%;
}
.deleteConfirmationDialog_deleteDialog__HnEvB .deleteConfirmationDialog_icon__Roly3 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.deleteConfirmationDialog_deleteDialog__HnEvB h2 {
  font-size: 26px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
}
.deleteConfirmationDialog_deleteDialog__HnEvB p {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
}
.deleteConfirmationDialog_deleteDialog__HnEvB .deleteConfirmationDialog_btnContainer__AkYw\\+ {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.deleteConfirmationDialog_deleteDialog__HnEvB .deleteConfirmationDialog_btnContainer__AkYw\\+ button {
  height: 30px;
  border: none;
  border-radius: 5px;
  padding: 0px 20px;
  color: #FFFFFF;
}
.deleteConfirmationDialog_deleteDialog__HnEvB .deleteConfirmationDialog_btnContainer__AkYw\\+ button.deleteConfirmationDialog_btnCancel__AUYG5 {
  background-color: rgba(0, 0, 0, 0.6);
}
.deleteConfirmationDialog_deleteDialog__HnEvB .deleteConfirmationDialog_btnContainer__AkYw\\+ button.deleteConfirmationDialog_btnDelete__K6cTr {
  background-color: #D1454D;
}`, "",{"version":3,"sources":["webpack://./src/components/dialog/deleteConfirmationDialog/deleteConfirmationDialog.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,eAAA;AACJ;AAAI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAER;AAAI;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,yBAAA;EACA,iBAAA;AAER;AAAI;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,SAAA;AAER;AADQ;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AAGZ;AAFY;EACI,oCAAA;AAIhB;AAFY;EACI,yBAAA;AAIhB","sourcesContent":[".deleteDialog{\n    padding: 40px;\n    width: 450px;\n    max-width: 100%;\n    .icon{\n        display: flex;\n        justify-content: center;\n        margin-bottom: 20px;\n    }\n    h2{\n        font-size: 26px;\n        text-align: center;\n        font-weight: 400;\n        margin-bottom: 20px;\n    }\n    p{\n        text-align: center;\n        color: rgba(#000000, 0.6);\n        line-height: 22px;\n    }\n    .btnContainer{\n        display: flex;\n        justify-content: center;\n        margin-top: 20px;\n        gap: 20px;\n        button{\n            height: 30px;\n            border: none;\n            border-radius: 5px;\n            padding: 0px 20px;\n            color: #FFFFFF;\n            &.btnCancel{\n                background-color: rgba(#000000, 0.6);\n            }\n            &.btnDelete{\n                background-color: #D1454D;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteDialog": `deleteConfirmationDialog_deleteDialog__HnEvB`,
	"icon": `deleteConfirmationDialog_icon__Roly3`,
	"btnContainer": `deleteConfirmationDialog_btnContainer__AkYw+`,
	"btnCancel": `deleteConfirmationDialog_btnCancel__AUYG5`,
	"btnDelete": `deleteConfirmationDialog_btnDelete__K6cTr`
};
export default ___CSS_LOADER_EXPORT___;
