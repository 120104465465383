import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from './pricingupdate.module.scss';
import PricingForm from './pricingform';
import UpdatePricingTitle from './updateTitle';

const PricingHome = ({templateId, updateViewMode}) => {
    const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeViewMode = (viewMode) => {
    updateViewMode(viewMode)
  }
  return (
    <Box sx={{ width: '100%', typography: 'body1', marginTop: '10px' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Title" value="1" />
            <Tab label="Plans" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <div className={styles.titleCont}>
                <UpdatePricingTitle templateId={templateId} pageViewMode={changeViewMode}/>
            </div>
        </TabPanel>
        <TabPanel value="2">
            <div className={styles.planCont}>
                <PricingForm templateId={templateId}/>
                
            </div>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default PricingHome