import { Endpoint } from './endpoint';
import {
    PayorcApibaseUrl,
    PayorcPort,
  } from './config';

  export const APIConstant = {
    getBaseUrl : `${PayorcApibaseUrl}`,
    getHomeBanners : `${PayorcApibaseUrl}${Endpoint.getHomeBanners}`,
    getSectionVisibility : `${PayorcApibaseUrl}${Endpoint.getSectionVisibility}`,
    addHomeBanners : `${PayorcApibaseUrl}${Endpoint.addHomeBanners}`,
    updateVisibility : `${PayorcApibaseUrl}${Endpoint.updateVisibility}`,
    updatePortfolioTitle : `${PayorcApibaseUrl}${Endpoint.updatePortfolioTitle}`,
    addPortfolioTitle : `${PayorcApibaseUrl}${Endpoint.addPortfolioTitle}`,
    getPortfolioTitle : `${PayorcApibaseUrl}${Endpoint.getPortfolioTitle}`,
    getPortfolioSections : `${PayorcApibaseUrl}${Endpoint.getPortfolioSections}`,
    getPortfolioSecionDetail : `${PayorcApibaseUrl}${Endpoint.getPortfolioSecionDetail}`,
    getSelectedPortfolioTitle : `${PayorcApibaseUrl}${Endpoint.getSelectedPortfolioTitle}`,
    getLegalList : `${PayorcApibaseUrl}${Endpoint.getLegalList}`,
    getSelectedLegalData : `${PayorcApibaseUrl}${Endpoint.getSelectedLegalData}`,
    updateLegalData : `${PayorcApibaseUrl}${Endpoint.updateLegalData}`,
    createLegalData : `${PayorcApibaseUrl}${Endpoint.createLegalData}`,
    getLandingBanners : `${PayorcApibaseUrl}${Endpoint.getLandingBanners}`,
    updateHomeBanner : `${PayorcApibaseUrl}${Endpoint.updateHomeBanner}`,
    deleteHomeBanner : `${PayorcApibaseUrl}${Endpoint.deleteHomeBanner}`,
    getSelectedHomeBanner : `${PayorcApibaseUrl}${Endpoint.getSelectedHomeBanner}`,
    updateOrganization : `${PayorcApibaseUrl}${Endpoint.updateOrganization}`,
    getOrganization : `${PayorcApibaseUrl}${Endpoint.getOrganization}`,
    createPortfolioSections : `${PayorcApibaseUrl}${Endpoint.createPortfolioSections}`,
    updatePortfolioSections : `${PayorcApibaseUrl}${Endpoint.updatePortfolioSections}`,
    createPortfolioList : `${PayorcApibaseUrl}${Endpoint.createPortfolioList}`,
    updatePortfolioList : `${PayorcApibaseUrl}${Endpoint.updatePortfolioList}`,
    getPortfolioSelectedItem : `${PayorcApibaseUrl}${Endpoint.getPortfolioSelectedItem}`,
    deletePortfolioItem : `${PayorcApibaseUrl}${Endpoint.deletePortfolioItem}`,
    getPricingTitle : `${PayorcApibaseUrl}${Endpoint.getPricingTitle}`,
    updatePricingTitle : `${PayorcApibaseUrl}${Endpoint.updatePricingTitle}`,
    addPricingPlan : `${PayorcApibaseUrl}${Endpoint.addPricingPlan}`,
    getPricingPlan : `${PayorcApibaseUrl}${Endpoint.getPricingPlan}`,
    deletePricingPlan : `${PayorcApibaseUrl}${Endpoint.deletePricingPlan}`,
    getPricingFeatures : `${PayorcApibaseUrl}${Endpoint.getPricingFeatures}`,
    addPricingFeature : `${PayorcApibaseUrl}${Endpoint.addPricingFeature}`,
    addPricingPlanData : `${PayorcApibaseUrl}${Endpoint.addPricingPlanData}`,
    getPricingPlanData : `${PayorcApibaseUrl}${Endpoint.getPricingPlanData}`,
    updateFeatureListData : `${PayorcApibaseUrl}${Endpoint.updateFeatureListData}`,
    updatePricingPlanData : `${PayorcApibaseUrl}${Endpoint.updatePricingPlanData}`,
    updateNoCodeTitle : `${PayorcApibaseUrl}${Endpoint.updateNoCodeTitle}`,
    getNoCodeTitle : `${PayorcApibaseUrl}${Endpoint.getNoCodeTitle}`,
    getIntegrationMadeEasyTitle : `${PayorcApibaseUrl}${Endpoint.getIntegrationMadeEasyTitle}`,
    updateIntegrationMadeEasyTitle : `${PayorcApibaseUrl}${Endpoint.updateIntegrationMadeEasyTitle}`,
    deleteFeature : `${PayorcApibaseUrl}${Endpoint.deleteFeature}`,
    addPricingPlanValues : `${PayorcApibaseUrl}${Endpoint.addPricingPlanValues}`,
    getPlatformsTitle : `${PayorcApibaseUrl}${Endpoint.getPlatformsTitle}`,
    updatePlatformsTitle : `${PayorcApibaseUrl}${Endpoint.updatePlatformsTitle}`,
    updateJourneyTitle : `${PayorcApibaseUrl}${Endpoint.updateJourneyTitle}`,
    getJourneyTitle : `${PayorcApibaseUrl}${Endpoint.getJourneyTitle}`,
    getPricingPlanDetails : `${PayorcApibaseUrl}${Endpoint.getPricingPlanDetails}`,
    imageUpload : `${PayorcApibaseUrl}${Endpoint.imageUpload}`,
    createPage : `${PayorcApibaseUrl}${Endpoint.createPage}`,
    getPageList : `${PayorcApibaseUrl}${Endpoint.getPageList}`,
    getSelectedPage : `${PayorcApibaseUrl}${Endpoint.getSelectedPage}`,
    publishPage : `${PayorcApibaseUrl}${Endpoint.publishPage}`,
    getTemplates : `${PayorcApibaseUrl}${Endpoint.getTemplates}`,
    createPageTemplate : `${PayorcApibaseUrl}${Endpoint.createPageTemplate}`,
    reorderPageTemplates : `${PayorcApibaseUrl}${Endpoint.reorderPageTemplates}`,
    getPageTemplatesWithID : `${PayorcApibaseUrl}${Endpoint.getPageTemplatesWithID}`,
    getLandingPage : `${PayorcApibaseUrl}${Endpoint.getLandingPage}`,
    draftPage : `${PayorcApibaseUrl}${Endpoint.draftPage}`,
    getDraftPageWithGroupId : `${PayorcApibaseUrl}${Endpoint.getDraftPageWithGroupId}`,
    deletePage : `${PayorcApibaseUrl}${Endpoint.deletePage}`,
    getHomeBannersWithId : `${PayorcApibaseUrl}${Endpoint.getHomeBannersWithId}`,
    getLandingPageContent : `${PayorcApibaseUrl}${Endpoint.getLandingPageContent}`,
    getNoCodeIntegrationTitle : `${PayorcApibaseUrl}${Endpoint.getNoCodeIntegrationTitle}`,
    addNoCodeIntegrationTitle : `${PayorcApibaseUrl}${Endpoint.addNoCodeIntegrationTitle}`,
    getNoCodeIntegrationList : `${PayorcApibaseUrl}${Endpoint.getNoCodeIntegrationList}`,
    addNoCodeIntegrationList : `${PayorcApibaseUrl}${Endpoint.addNoCodeIntegrationList}`,
    getNoCodeIntegrationListWithId : `${PayorcApibaseUrl}${Endpoint.getNoCodeIntegrationListWithId}`,
    updateNoCodeIntegrationListWithId : `${PayorcApibaseUrl}${Endpoint.updateNoCodeIntegrationListWithId}`,
    deleteNoCodeIntegrationWithId : `${PayorcApibaseUrl}${Endpoint.deleteNoCodeIntegrationWithId}`,
    addPlatformsTitle : `${PayorcApibaseUrl}${Endpoint.addPlatformsTitle}`,
    addPlatformsList : `${PayorcApibaseUrl}${Endpoint.addPlatformsList}`,
    updatePlatformsListWithId : `${PayorcApibaseUrl}${Endpoint.updatePlatformsListWithId}`,
    getPlatformsListWithId : `${PayorcApibaseUrl}${Endpoint.getPlatformsListWithId}`,
    getPlatformsList : `${PayorcApibaseUrl}${Endpoint.getPlatformsList}`,
    deletePlatformsWithId : `${PayorcApibaseUrl}${Endpoint.deletePlatformsWithId}`,
    addJourneyTitle : `${PayorcApibaseUrl}${Endpoint.addJourneyTitle}`,
    addJourneySection : `${PayorcApibaseUrl}${Endpoint.addJourneySection}`,
    updateJourneySection : `${PayorcApibaseUrl}${Endpoint.updateJourneySection}`,
    getJourneySection : `${PayorcApibaseUrl}${Endpoint.getJourneySection}`,
    deleteTemplate : `${PayorcApibaseUrl}${Endpoint.deleteTemplate}`,
    addLcri : `${PayorcApibaseUrl}${Endpoint.addLcri}`,
    getLcri : `${PayorcApibaseUrl}${Endpoint.getLcri}`,
    updateLcri : `${PayorcApibaseUrl}${Endpoint.updateLcri}`,
    addLirc : `${PayorcApibaseUrl}${Endpoint.addLirc}`,
    getLirc : `${PayorcApibaseUrl}${Endpoint.getLirc}`,
    updateLirc : `${PayorcApibaseUrl}${Endpoint.updateLirc}`,
    getLircTwoColTitle : `${PayorcApibaseUrl}${Endpoint.getLircTwoColTitle}`,
    updateLircTwoColtitle : `${PayorcApibaseUrl}${Endpoint.updateLircTwoColtitle}`,
    addLircTwoColTitle : `${PayorcApibaseUrl}${Endpoint.addLircTwoColTitle}`,
    addLircTwoColList : `${PayorcApibaseUrl}${Endpoint.addLircTwoColList}`,
    updateLircTwoColListWithId : `${PayorcApibaseUrl}${Endpoint.updateLircTwoColListWithId}`,
    getLircTwoColListWithId : `${PayorcApibaseUrl}${Endpoint.getLircTwoColListWithId}`,
    getLircTwoColList : `${PayorcApibaseUrl}${Endpoint.getLircTwoColList}`,
    addImageRRR : `${PayorcApibaseUrl}${Endpoint.addImageRRR}`,
    getImageRRR : `${PayorcApibaseUrl}${Endpoint.getImageRRR}`,
    deleteImageRRRWithId : `${PayorcApibaseUrl}${Endpoint.deleteImageRRRWithId}`,
    addContentRRR : `${PayorcApibaseUrl}${Endpoint.addContentRRR}`,
    getContentRRR : `${PayorcApibaseUrl}${Endpoint.getContentRRR}`,
    updateContentRRR : `${PayorcApibaseUrl}${Endpoint.updateContentRRR}`,
    getContentRRRWithId : `${PayorcApibaseUrl}${Endpoint.getContentRRRWithId}`,
    deleteContentRRRWithId : `${PayorcApibaseUrl}${Endpoint.deleteContentRRRWithId}`,
    addProcessorInputData : `${PayorcApibaseUrl}${Endpoint.addProcessorInputData}`,
    addProcessorOutputData : `${PayorcApibaseUrl}${Endpoint.addProcessorOutputData}`,
    getProcessorInput : `${PayorcApibaseUrl}${Endpoint.getProcessorInput}`,
    getProcessorOutput : `${PayorcApibaseUrl}${Endpoint.getProcessorOutput}`,
    deleteProcessorInputWithId : `${PayorcApibaseUrl}${Endpoint.deleteProcessorInputWithId}`,
    deleteProcessorOutputWithId : `${PayorcApibaseUrl}${Endpoint.deleteProcessorOutputWithId}`,
    addPricingTitle : `${PayorcApibaseUrl}${Endpoint.addPricingTitle}`,
    getpricingfeaturesList : `${PayorcApibaseUrl}${Endpoint.getpricingfeaturesList}`,
    getPricingPlanDetailsWithId : `${PayorcApibaseUrl}${Endpoint.getPricingPlanDetailsWithId}`,
    getCommonSubSectionIntegrationEasy : `${PayorcApibaseUrl}${Endpoint.getCommonSubSectionIntegrationEasy}`,
    addCommonSubSectionIntegrationEasy : `${PayorcApibaseUrl}${Endpoint.addCommonSubSectionIntegrationEasy}`,
    updateCommonSubSectionIntegrationEasy : `${PayorcApibaseUrl}${Endpoint.updateCommonSubSectionIntegrationEasy}`,
    addIntegrationMadeEasyTitle : `${PayorcApibaseUrl}${Endpoint.addIntegrationMadeEasyTitle}`,
    deleteIntegrationEasySectionWithId : `${PayorcApibaseUrl}${Endpoint.deleteIntegrationEasySectionWithId}`,
    createTextEditorData : `${PayorcApibaseUrl}${Endpoint.createTextEditorData}`,
    getTexteditorData : `${PayorcApibaseUrl}${Endpoint.getTexteditorData}`,
    getSelectedTemplate : `${PayorcApibaseUrl}${Endpoint.getSelectedTemplate}`,
    updateTextEditorData : `${PayorcApibaseUrl}${Endpoint.updateTextEditorData}`,
    getPortfolioSectionsAndData : `${PayorcApibaseUrl}${Endpoint.getPortfolioSectionsAndData}`,
    sequenceHomeBanner : `${PayorcApibaseUrl}${Endpoint.sequenceHomeBanner}`,
    loginCms : `${PayorcApibaseUrl}${Endpoint.loginCms}`,
    getPublishedPageList : `${PayorcApibaseUrl}${Endpoint.getPublishedPageList}`,
    getSelectedSEO : `${PayorcApibaseUrl}${Endpoint.getSelectedSEO}`,
    sendContactusFormData : `${PayorcApibaseUrl}${Endpoint.sendContactusFormData}`,
  }