import React, { useState } from 'react'
import styles from './textEditor.module.scss';
import { Switch } from '@mui/material';
import images from '../../../assets/images/images';
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';
import Processor from '../../home/processor/processor';
import UpdateProcessor from '../../admin/home/sections/processor/processor';
import TextEditorHome from '../../home/textEditor/textEditor';
import UpdateTextEditor from '../../admin/home/sections/testEditor/updateTextEditor';
import { useSelector } from 'react-redux'

const PreviewTextEditor = ({templateId, pageId}) => {
  const [checked, setChecked] = useState();
  const [viewMode, setViewMode] = useState('preview');

  const activePageId = useSelector((state) => state.activePageId)

    console.log('activePageId', activePageId.id)

  const handleVisibilityChange = (event) => {
    let visbility;
    setChecked(event.target.checked);
    if (checked) {
      visbility = 'hide'
    }
    else {
      visbility = 'active'
    }
  };
  const deleteTemplate = () => {
    axios.post(APIConstant.deleteTemplate, {templateId : templateId})
    .then(res => {})
    .catch(err => console.log(err))
  }
  return (
    <div className={`${styles.platformPreviewCont} ${styles.previewContainer}`}>
        <ul className={styles.sectionBtns}>
            {/* <li>
            <div className="customeSwitch">
                <Switch
                checked={checked}
                onChange={handleVisibilityChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />
                <span className={checked ? 'active switchlabel' : 'hide switchlabel'}>{checked ? 'Active' : 'Hide'}</span>
            </div>
            </li> */}
            {viewMode === 'preview' &&
                <li>
                    <button className={styles.edit} onClick={() => setViewMode('edit')}>Edit</button>
                </li>
            }
            {viewMode === 'edit' &&
                <>
                    {/* <li>
                    <button className={styles.discard} onClick={() => setViewMode('preview')}>Discard</button>
                    </li> */}
                    <li>
                    <button className={styles.discard} onClick={() => setViewMode('preview')}>Discard</button>
                    </li>
                    {/* <li>
                      <button className={styles.delete} onClick={deleteTemplate}>
                        <img src={images.DELETE} width="20" title="Delete" alt="delete" />
                      </button>
                    </li> */}
                </>
            }
        </ul>
        {viewMode === 'preview' &&
            <>
            
            <TextEditorHome templateId={templateId}/>
            </>
            
        }
        {viewMode === 'edit' &&
            <UpdateTextEditor templateId={templateId} />
        }
        
    </div>
  )
}

export default PreviewTextEditor