import React, { useEffect } from 'react'
import axios from 'axios';
import Switch from '@mui/material/Switch';
import TextEditor from '../../components/texteditor/texteditor'
import Images from '../../assets/images/images'
import styles from './legal.module.scss'
import Template from './temp';
import { useSearchParams } from 'react-router-dom';
import { APIConstant } from '../../services/APIConstant';


const Legal = () => {
  const [checked, setChecked] = React.useState(true);
  const [viewMode, setViewMode] = React.useState('design');
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedLegalData, setSelectedLegalData] = React.useState({});

  // const updateContent = (content) => {
  //   console.log('Content', content)
  // }
  const handleChange = (event) => {
    // alert(event.target.checked)
    setChecked(event.target.checked);
  };
  // let id = searchParams.get("id")
  
  useEffect(() => {
    axios.post(APIConstant.getSelectedLegalData, { legal_id: searchParams.get("id")  })
    .then(res => {
      setSelectedLegalData(res.data[0])
    })
    .catch(err => console.log(err))
  }, [searchParams.get("id")])

  return (
    <div className={styles.privacyPolicyCont}>
      <div className={styles.pageWrapper}>
      <header>
        
        <h3>{selectedLegalData.legal_name}</h3>
        <ul>
          <li>
            <ul>
              <li><a onClick={() => setViewMode('code')} className={ viewMode === 'code' ? styles.active : '' }><img src={Images.CODE} alt="Icon" width="22" /></a></li>
              <li><a onClick={() => setViewMode('design')} className={ viewMode === 'design' ? styles.active : '' }><img src={Images.PAGE} alt="Icon" width="16" /></a></li>
            </ul>
          </li>
          <li>
          <div className="customeSwitch">
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
           <span className={ checked ? 'active switchlabel' : 'hide switchlabel' }>{checked ? 'Active' : 'Hide'}</span>
            </div>
          </li>
          {/* <li><button className={styles.publishBtn}>Publish</button></li> */}
        </ul>
      </header>
      <section className={styles.mainWrapper}>
          
        {viewMode === 'design' &&
          <div className={styles.design}>
            <Template data={selectedLegalData}/>
          </div>
        }
        {viewMode === 'code' &&
          <div className={styles.code}>
            
            <TextEditor data={selectedLegalData} />
            
          </div>
        }
      </section>
    </div>

      
    </div>
  )
}

export default Legal