import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import styles from './bannerupdate.module.scss'
import AddBanner from './addBanner';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import DeleteConfirmation from '../../../dialog/delete/delete';
import BannerDND from './bannerDnd';

const BannerList = ({template}) => {
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState();
    const [modeType, setModeType] = useState();

    const addBannerOpenDialog = (mode,id) => {
      setOpen(true);
      setModeType(mode);
      setSelectedBanner(id)
    };
    const handleClose = () => {
      setOpen(false);
    };
    const deleteBanner = (id) => {

        setOpenDeleteDialog(true)
        setSelectedBanner(id)
    }
    const confirmDeleteBanner = (id) => {
        axios.post(APIConstant.deleteHomeBanner, { bannerid:id })
        .then(res => {
            
        })
        .catch(err => console.log(err))
        setOpenDeleteDialog(false)
    }
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }
    const handleCloseAndRefresh = () => {
        handleClose()
    }
    return (
        <div className={styles.bannerList}>
            <div style={{ height: 'auto', width: '100%' }} className="customDataGrid">
                <BannerDND template={template} editBannerWithId={addBannerOpenDialog} deleteBannerWithId={deleteBanner}/>
            </div>

            <div className={styles.btnCont}>
                <button onClick={() => addBannerOpenDialog('Add')}>ADD BANNER</button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <AddBanner mode={modeType} selected={selectedBanner} templateId={template} closeDialog={handleCloseAndRefresh}/>
            </Dialog>

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DeleteConfirmation selected={selectedBanner} closeDialog={handleCloseDeleteDialog} confirmDeleteDialog={confirmDeleteBanner}/>
            </Dialog>
        </div>
    )
}

export default BannerList