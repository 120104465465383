// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewProcessor_sectionBtns__afQz3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.previewProcessor_sectionBtns__afQz3 li button {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
}
.previewProcessor_sectionBtns__afQz3 li button.previewProcessor_discard__A6Sgu, .previewProcessor_sectionBtns__afQz3 li button.previewProcessor_edit__HYMyo {
  background-color: #FFFFFF;
  border: 1px solid #398378;
}
.previewProcessor_sectionBtns__afQz3 li button.previewProcessor_primary__jFVb\\+ {
  background-color: #398378;
  color: #FFFFFF;
}

.previewProcessor_previewContainer__y5E7b {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/preview/processor/previewProcessor.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACQ;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AAAY;EACI,yBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,yBAAA;EACA,cAAA;AAEhB;;AAGA;EACI,oCAAA;EACA,aAAA;EACA,YAAA;AAAJ","sourcesContent":[".sectionBtns{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    li{\n        button{\n            margin-left: 10px;\n            border: none;\n            border-radius: 5px;\n            padding: 5px 20px;\n            height: 30px;\n            &.discard, &.edit{\n                background-color: #FFFFFF;\n                border: 1px solid  #398378;\n            }\n            &.primary{\n                background-color: #398378;\n                color: #FFFFFF;\n            }\n        }\n    }\n}\n.previewContainer{\n    border: 1px solid rgba(#000000, 0.1);\n    padding: 10px;\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBtns": `previewProcessor_sectionBtns__afQz3`,
	"discard": `previewProcessor_discard__A6Sgu`,
	"edit": `previewProcessor_edit__HYMyo`,
	"primary": `previewProcessor_primary__jFVb+`,
	"previewContainer": `previewProcessor_previewContainer__y5E7b`
};
export default ___CSS_LOADER_EXPORT___;
