import React, { useEffect, useState } from 'react';
import styles from './lcri.module.scss';
import Images from '../../../assets/images/images';
import { APIConstant } from '../../../services/APIConstant';

const LCRI = ({templateId}) => {
  const [templateData, setTemplateData] = useState({});
  useEffect(() => {
    fetch(APIConstant.getLcri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    })
    .then(res => res.json())
    .then((data) => {
        // console.log('Data :', data)
        setTemplateData(data[0])
    })
    .catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.lcriContainer}>
      <div className='container'>
        <div className={styles.secWrapper}>
          <section className={`${styles.secCont} ${styles.txtCont}`}>
            <h5>{templateData?.title}</h5>
            <h3>{templateData?.heading}</h3>
            
            <div className={`${styles.pageContent} editorData`} dangerouslySetInnerHTML={ {__html: templateData?.description} } />
           
            {/* <ul>
              <li><span><img src={Images.CHECK} alt="Check" width="14"/></span>Track payments data in one view</li>
              <li><span><img src={Images.CHECK} alt="Check" width="14"/></span>Manage payment orchestration</li>
              <li><span><img src={Images.CHECK} alt="Check" width="14"/></span>Prevent fraud & defend chargebacks</li>
            </ul> */}
            {templateData?.link_text !== '' && 
              <button>{templateData?.link_text} <span><img src={Images.RIGHT_ARROW} width="16" alt="arrow"/></span></button>
            }
          </section>
          <section className={`${styles.secCont} ${styles.imgCont}`}>
            <img src={templateData?.img_path} alt="Laptop Banner" />
          </section>
        </div>
      </div>
    </div>
  )
}

export default LCRI